import { React, useEffect, useRef, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import $ from 'jquery';

import './Product.css';
import ProductDetail from './productPages/ProductDetail.js';
import ProcessDetail from './productPages/ProcessDetail.js';

import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs, doc, query, orderBy, limit , where} from "firebase/firestore"; // 데이터 읽어오기

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay, Grid } from "swiper";	// 추가

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay, Grid]);

function Product(props) {  // props.product 서비스 페이지의 필터링 키워드 2
  const [BannerDb, SetBannerDb] = useState(null);
  const [BannerLoading, SetBannerLoading] = useState(false);
  
  useEffect(()=>{
    LoadingBannerData(props.product);
  },[])

  useEffect(()=>{
    LoadingBannerData(props.product);
  },[props.product])

  async function LoadingBannerData(productType){
    SetBannerLoading(false);
    const UserListRef = collection(props.db, "Banner"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, where("Page", "==", "Product"), where("Type", "==", productType)));
    let Banner = [];
    await querySnapshot.forEach((doc) => {
      Banner.push(doc.data());
    });
    SetBannerDb(Banner);
    SetBannerLoading(true);
  }

  return (
    <div className="Product">
      <div className="Product_PC flex w-full flex-1 items-center flex-col">
        <div className="Product_PC-Banner flex flex-col w-full justify-center items-center h-40">
          <p className="flex">제 품 소 개</p>
          <p className="Title flex">{props.product}</p>
          <div className="BannerBackColor flex w-full h-full"></div>
          {BannerLoading ? 
          <div className="BannerBackIMG flex w-full h-full">
            {BannerDb.map(banner => (
              <img className="flex w-full h-full" src={banner.IMG}></img>
            ))}
          </div> : null}
        </div>
        <div className="Product_PC-products w-full items-center flex flex-col">
          <div className="MenuAreaList flex w-full con">
            <ul className="flex w-full">
              <li className="flex flex-1">
                <Link to="/CHQWire" className="flex flex-1 w-full h-full items-center justify-center">CHQ WIRE</Link>
              </li>
              <li className="flex flex-1">
                <Link to="/CDBar" className="flex flex-1 w-full h-full items-center justify-center">CD BAR</Link>
              </li>
              <li className="flex flex-1">
                <Link to="/Cutting" className="flex flex-1 w-full h-full items-center justify-center">CUTTING</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="ContentList flex w-full con">
          <ProductDetail db={props.db} productType={props.product} />
        </div>
        <div className="ContentList flex w-full con">
          <ProcessDetail db={props.db} productType={props.product} />
        </div>
      </div>

      <div className="Product_MB flex w-full flex-1 items-center flex-col">
        <div className="Product_MB-Banner flex flex-col w-full justify-center items-center h-40">
          <p className="flex text-lg mb-2">제 품 소 개</p>
          <p className="Title flex">{props.product}</p>
          <div className="BannerBackColor flex w-full h-full"></div>
          {BannerLoading ? 
          <div className="BannerBackIMG flex w-full h-full">
            {BannerDb.map(banner => (
              <img className="flex w-full h-full" src={banner.IMG}></img>
            ))}
          </div>
          : null}
           </div>
        <div className="Product_MB-products w-full items-center flex flex-col">
          <div className="MenuAreaList flex w-full con">
            <ul className="flex w-full">
              <li className="flex flex-1">
                <Link to="/CHQWire" className="flex flex-1 justify-center items-center">CHQ WIRE</Link>
              </li>
              <li className="flex flex-1">
                <Link to="/CDBar" className="flex flex-1 justify-center items-center">CD BAR</Link>
              </li>
              <li className="flex flex-1">
                <Link to="/Cutting" className="flex flex-1 justify-center items-center">CUTTING</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="ContentList flex w-full">
          <ProductDetail db={props.db} productType={props.product} />
        </div>
        <div className="ContentList flex w-full con">
          <ProcessDetail db={props.db} productType={props.product} />
        </div>
      </div>
    </div>
  );
}

export default Product;
