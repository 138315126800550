import { React, useEffect, useState, useRef } from "react";
import './Home.css';
import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs, doc, query, orderBy, limit , where} from "firebase/firestore"; // 데이터 읽어오기
import ReactPlayer from 'react-player'

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";	// 추가

import $ from 'jquery';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay])

function Home(props) {

  const [MainBanner, SetMainBanner]=useState(null);
  const [MainBannerLoading, SetMainBannerLoading]=useState(false);

  const [MainBannerMB, SetMainBannerMB]=useState(null);
  const [MainBannerMBLoading, SetMainBannerMBLoading]=useState(false);

  const [CIDb, SetCIDb]=useState(null);
  const [CILoading, SetCILoading]=useState(false);

  const [NewsDb, SetNewsDb]=useState(null);
  const [NewsLoading, SetNewsLoading]=useState(false);

  //icons
  const [Iconup1 ,SetIconup1]=useState(true);

  const [CustomerDb, SetCustomerDb]=useState(null);
  const [CustomerLoading, SetCustomerLoading]=useState(false);

  useEffect(()=>{
    LoadingMainVideoData();
    LoadingMainVideoMBData();
    LoadingCIData();
    LoadingCustomerData();
    LoadingNewsData();
  },[])

  async function LoadingMainVideoData(){
    SetMainBannerLoading(false);
    const UserListRef = collection(props.db, "MainBanner"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(UserListRef);
    let MainBanner=[];
    MainBanner.length=0;
    await querySnapshot.forEach((doc) => {
      MainBanner.push(doc.data());
    });

    let seed = Math.floor(new Date().getTime() / 1000);
    let x = Math.sin(seed++) * 10000;
    const randomValue = Math.floor((x - Math.floor(x)) * MainBanner.length);
    const Cage = [MainBanner[randomValue]];
    
    if(Cage!==null){
      SetMainBanner(Cage);
      SetMainBannerLoading(true);
    }
  }

  async function LoadingMainVideoMBData(){
    SetMainBannerLoading(false);
    const UserListRef = collection(props.db, "MainBannerMB"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(UserListRef);
    let MainBannerMB=[];
    MainBannerMB.length=0;
    await querySnapshot.forEach((doc) => {
      MainBannerMB.push(doc.data());
    });

    let seed = Math.floor(new Date().getTime() / 1000);
    let x = Math.sin(seed++) * 10000;
    const randomValue = Math.floor((x - Math.floor(x)) * MainBannerMB.length);
    const Cage = [MainBannerMB[randomValue]];
    if(Cage!==null){
      SetMainBannerMB(Cage);
      SetMainBannerMBLoading(true);
    }
    
  }

  async function LoadingCIData(){
    SetCILoading(false);
    const UserListRef = collection(props.db, "CI"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot1 = await getDocs(query(UserListRef, orderBy("No","asc")));
    let CIData=[];
    CIData.length=0;
    await querySnapshot1.forEach((doc) => {
      CIData.push(doc.data());
    });
    SetCIDb(CIData);
    SetCILoading(true);
  }

  async function LoadingCustomerData(){
    SetCustomerLoading(false);
    const UserListRef = collection(props.db, "Customer"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("No", "asc")));
    let CustomerData=[];
    CustomerData.length=0;
    await querySnapshot.forEach((doc) => {
      CustomerData.push(doc.data());
    });
    SetCustomerDb(CustomerData);
    SetCustomerLoading(true);
  }

  async function LoadingNewsData(){
    SetNewsLoading(false);
    const UserListRef = collection(props.db, "News"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot1 = await getDocs(query(UserListRef, orderBy("Upload","desc"), limit(3)));
    let NewsData=[];
    NewsData.length=0;
    await querySnapshot1.forEach((doc) => {
      NewsData.push(doc.data());
    });
    SetNewsDb(NewsData);
    SetNewsLoading(true);
  }

  function ContentsClick(e) {
    if(e.currentTarget.nextElementSibling.classList.contains('active')){
      e.currentTarget.nextElementSibling.classList.remove('active');
      SetIconup1(true);
    }
    else{
      e.currentTarget.nextElementSibling.classList.add('active');
      SetIconup1(false);
    }
  }

  return (
    <div className="Home">
      <div className="Home_PC flex flex-col w-full items-center">
        <div className="Home_PC-top flex flex-col w-full items-center justify-center">
        {MainBannerLoading ?
          <div className="BackIMG flex h-full w-full">
            {MainBanner.map((mainbanner, index)=>(
            <ReactPlayer url={mainbanner.Video} key={index}
            width='100%' height='100%' muted={true} controls={false} playsinline={true} playing={true} loop={true}  /> 
            ))}     
          </div>: null}     
        </div>
        <div className="Home_PC-intro con flex flex-col w-full items-center justify-center">
          <div className="Text flex w-full">
            <div className="Text-Left flex flex-1">
              Clean & Creative <br></br>
              Innovation Enterprise <br></br>
              PartnerShip <br></br>
            </div>
            <div className="Text-Right flex flex-1 flex-col justify-end">
              <div className="flex flex-1 items-center">우리 삶에 이로운 철, 다양한 시도와 <br></br>
                도전으로 그 이상의 가치를 향해 앞장서는 <br></br>
                영우특수강이 되겠습니다. <br></br>
              </div>
              <div className="flex gap-6 pl-4">
                <Link to="/AboutUs" target="_blank" className="flex PinUnderline text-xl">회사소개<i className="fa-solid fa-arrow-up-right-from-square text-sm ml-1"></i></Link>
                <Link to="/Patent" target="_blank" className="flex PinUnderline text-xl">특허·인증<i className="fa-solid fa-arrow-up-right-from-square text-sm ml-1"></i></Link>
                <Link to="/CHQWire" target="_blank" className="flex PinUnderline text-xl">제품·공정<i className="fa-solid fa-arrow-up-right-from-square text-sm ml-1"></i></Link>
              </div>
            </div>
          </div>
          {CILoading ? 
          <div className="IntroBox flex w-full gap-4 items-center">
            {CIDb.map(ci=>(
              <div className="IntroBoxList flex flex-col items-center" key={ci.index}>
                <img className="flex flex-1" src={ci.IMG} alt="ci"></img>
                <div className="IntroBoxListText">
                  <p>{ci.Name}</p>
                  <p>{ci.NameSub}</p>
                </div>
              </div>
            ))}
          </div> : null}
        </div>
        <div className="Home_PC-Trade flex w-full justify-center">
          <div className="flex flex-col w-full con">
            <div className="BigName flex w-full justify-center mb-6">주요거래처</div>
            <div className="Contents flex flex-col">
              {CustomerLoading ?
              <Swiper
              className='companyList flex w-full flex-1 justify-center' 
              spaceBetween={20}
              slidesPerView={4}
              slidesPerGroup={4}
              autoplay
              ={{ delay: 3000, disableOnInteraction: false }}
              loop={true}
              loopPreventsSliding={false}
              // onSlideChange={() => { console.log("slide change"); }}
              // onSwiper={(swiper) => { console.log(swiper); }}
              >
                {CustomerDb.map(customer=>(
                  <SwiperSlide className="CompanyLists flex flex-col items-center mb-2" key={customer.index}>
                    <div className="flex flex-1 justify-center items-center">
                      <img src={customer.IMG} alt={customer.Name}></img>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              : null}
            </div>
          </div>
        </div>
        <div className="Home_PC-News flex w-full justify-center">
          <div className="flex flex-col w-full con">
            <div className="BigName flex w-full items-center">공지사항</div>
            <div className="Contents flex flex-col">
            {NewsLoading && NewsDb.length ?
              <div className="flex flex-1 flex-col">
              {NewsDb.map((archaive, index)=>(
                <div className="NewsList flex flex-1 flex-col items-center" key={archaive}>
                  <div className="HeadLines flex w-full" onClick={ContentsClick}>
                    <div className="flex w-10 justify-center items-center">{index+1}</div>
                    <div className="flex justify-center items-center">{archaive.Name}</div>
                    <div className="flex w-20"></div>
                    <div className="flex items-center flex-1">{archaive.Upload}</div>
                    <div className="flex items-center ml-2">
                      { Iconup1 ?
                        <i className="fa-solid fa-angle-left"></i>
                        : <i className="fa-solid fa-angle-down"></i>
                      }
                    </div>
                  </div>
                  <div className="NContext flex w-full flex-col gap-4">
                    <div className='flex w-full font-bold text-xl'> {archaive.Name}</div>
                    <div className='flex w-full'> 
                      <div className='flex'>상세내용 : </div>
                      <div className='flex flex-1 ml-2'>{archaive.Detail}</div> 
                    </div>
                    <div className='flex w-full gap-3 mt-2 '>
                      {archaive.FILE1==="" || archaive.FILE1===undefined || archaive.FILE1===null ? null : <button className="PinBasic"><a href={archaive.FILE1} target="_blank">{archaive.FILE1Name} 확인하기</a></button> }
                      {archaive.FILE2==="" || archaive.FILE2===undefined || archaive.FILE2===null ? null : <button className="PinBasic"><a href={archaive.FILE2} target="_blank">{archaive.FILE2Name} 확인하기</a></button> }
                      {archaive.FILE3==="" || archaive.FILE3===undefined || archaive.FILE3===null ? null : <button className="PinBasic"><a href={archaive.FILE3} target="_blank">{archaive.FILE2Name} 확인하기</a></button> }
                    </div>
                  </div>
                </div>
                ))} 
              </div>
            : null }
              <div className="flex flex-1 justify-center mt-10"><Link to="/News">See More...</Link></div>
            </div>
            <div className="flex h-40"></div>
          </div>      
        </div>
      </div>
      <div className="Home_MB flex flex-col w-full items-center">
        <div className="Home_MB-top flex flex-col w-full items-center justify-center">
          <div className="BackIMG flex h-full w-full">
          {MainBannerMBLoading ?
            <div className="flex flex-1 flex-col">
              {MainBannerMB.map((mainbannermb, index)=>(
              <ReactPlayer url={mainbannermb.Video} key={index}
              width='100%' height='100%' muted={true} playsinline={true} playing={true} loop={true}  /> 
              ))} 
            </div>
            : null}                 
          </div>
        </div>
        <div className="Home_MB-intro con flex flex-col w-full items-center justify-center">
          <div className="Text flex w-full flex-col">
            <div className="flex h-14"></div>
            <div className="Text-Left flex flex-1">
              Clean & Creative <br></br>
              Innovation Enterprise <br></br>
              PartnerShip <br></br>
            </div>
            <div className="Text-Right flex flex-1">
            우리 삶에 이로운 철, 다양한 시도와 <br></br>
            도전으로 그 이상의 가치를 향해 앞장서는 <br></br>
            영우특수강이 되겠습니다. <br></br>
            </div>
            <div className="flex gap-4 mt-6 ml-2">
                <Link to="/AboutUs" target="_blank" className="PinUnderline text-sm">회사소개<i className="fa-solid fa-arrow-up-right-from-square text-sm ml-1"></i></Link>
                <Link to="/Patent" target="_blank" className="PinUnderline text-sm">특허·인증<i className="fa-solid fa-arrow-up-right-from-square text-sm ml-1"></i></Link>
                <Link to="/CHQWire" target="_blank" className="PinUnderline text-sm">제품·공정<i className="fa-solid fa-arrow-up-right-from-square text-sm ml-1"></i></Link>
              </div>
          </div>
          {CILoading ? 
          <div className="IntroBox flex w-full gap-4 flex-col items-center">
            {CIDb.map(ci=>(
              <div className="IntroBoxList flex flex-col items-center" key={ci.index}>
                <img className="flex flex-1" src={ci.IMG} alt="ci"></img>
                <div className="IntroBoxListText">
                  <p>{ci.Name}</p>
                  <p>{ci.NameSub}</p>
                </div>
              </div>
            ))}
          </div> : null}
        </div>
        <div className="Home_MB-Trade flex w-full justify-center">
          <div className="flex flex-col w-full">
            <div className="BigName flex w-full justify-center mb-6">주요거래처</div>
            <div className="Contents flex flex-col">
              {CustomerLoading ?
              <Swiper
              className='companyList flex w-full flex-1 justify-center' 
              spaceBetween={20}
              slidesPerView={2}
              slidesPerGroup={2}
              autoplay
              ={{ delay: 3000, disableOnInteraction: false }}
              loop={true}
              loopPreventsSliding={false}
              // onSlideChange={() => { console.log("slide change"); }}
              // onSwiper={(swiper) => { console.log(swiper); }}
              >
                {CustomerDb.map(customer=>(
                  <SwiperSlide className="CompanyLists flex flex-col items-center mb-2" key={customer.index}>
                    <div className="flex flex-1 justify-center items-center">
                      <img src={customer.IMG} alt={customer.Name}></img>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              : null}
            </div>
          </div>
        </div>
        <div className="Home_MB-News flex w-full justify-center">
          <div className="flex flex-col w-full">
            <div className="BigName flex w-full items-center">공지사항</div>
            <div className="Contents flex flex-col">
            {NewsLoading && NewsDb.length ?
              <div className="flex flex-1 flex-col">
              {NewsDb.map((archaive, index)=>(
                <div className="NewsList flex flex-1 flex-col items-center" key={archaive}>
                  <div className="HeadLines flex w-full" onClick={ContentsClick}>
                    <div className="flex w-10 justify-center items-center">{index+1}</div>
                    <div className="flex justify-center items-center">{archaive.Name}</div>
                    <div className="flex w-20"></div>
                    <div className="flex items-center flex-1">{archaive.Upload}</div>
                    <div className="flex items-center ml-2">
                      { Iconup1 ?
                        <i className="fa-solid fa-angle-left"></i>
                        : <i className="fa-solid fa-angle-down"></i>
                      }
                    </div>
                  </div>
                  <div className="NContext flex w-full flex-col gap-4">
                    <div className='flex w-full font-bold text-xl'> {archaive.Name}</div>
                    <div className='flex w-full'> 
                      <div className='flex'>상세내용 : </div>
                      <div className='flex flex-1 ml-2'>{archaive.Detail}</div> 
                    </div>
                    <div className='flex w-full gap-3 mt-2 '>
                      {archaive.FILE1==="" || archaive.FILE1===undefined || archaive.FILE1===null ? null : <button className="PinBasic"><a href={archaive.FILE1} target="_blank">{archaive.FILE1Name} 확인하기</a></button> }
                      {archaive.FILE2==="" || archaive.FILE2===undefined || archaive.FILE2===null ? null : <button className="PinBasic"><a href={archaive.FILE2} target="_blank">{archaive.FILE2Name} 확인하기</a></button> }
                      {archaive.FILE3==="" || archaive.FILE3===undefined || archaive.FILE3===null ? null : <button className="PinBasic"><a href={archaive.FILE3} target="_blank">{archaive.FILE2Name} 확인하기</a></button> }
                    </div>
                  </div>
                </div>
                ))} 
              </div>
            : null }
              <div className="flex flex-1 justify-center mt-10"><Link to="/News">See More...</Link></div>
            </div>
            <div className="flex h-20"></div>
            <div className="flex h-10"></div>
          </div>      
        </div>
      </div>
    </div>
  );
}

export default Home;
