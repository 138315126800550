import React, { useEffect, useState, useRef } from "react";
import './Patent.css';
import $ from 'jquery';

import { collection, getDocs, addDoc, query, where, doc, deleteDoc, orderBy, updateDoc } from "firebase/firestore"; // 데이터 읽어오기
import { getStorage, ref, deleteObject, uploadBytes, getDownloadURL } from 'firebase/storage';

function Patent(props) {

  const [PatentLoaded, SetPatentLoaded]=useState(false);
  const [MyPatentLoaded, SetMyPatentLoaded]=useState(false);

  const [UserPatentData, SetUserPatentData] = useState(null); 
  let UserPatentDt = [];

  const Name = useRef(null);	
  const Agency = useRef(null);		
  const imageFileInputRef1 = useRef(null);
  const [IMGupload, SetIMGupload] = useState(false);
  const [ShowIMAGE, SetShowIMAGE] = useState("");
  const [Uploading, SetUploading] = useState(false);  // 업로딩 관련

  useEffect(() => {
    reLoadUserPosts();
  }, []);
  
  useEffect(()=>{
    if(props.UserData===null || props.UserData===undefined || props.UserData.length===0){
      SetPatentLoaded(false);
    }
    else{
      SetPatentLoaded(true);
    }
  },[props.UserData]);

  useEffect(()=>{
    if(UserPatentData===null || UserPatentData===undefined || UserPatentData.length===0){
      SetMyPatentLoaded(false);
    }
    else{
      SetMyPatentLoaded(true); 
    }
  },[UserPatentData])

  async function LoadUserPosts(){
    SetUserPatentData(null);
    const UserListRef = collection(props.db, "Patent"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("No", "asc")));
  
    UserPatentDt.length=0; // 초기화 query 다음에 해야 2번씩이나 저장안됨
    querySnapshot.forEach((doc) => {
      UserPatentDt.push(doc.data());
    });

    SetUserPatentData(UserPatentDt);  // 최종입력
  }

  function reLoadUserPosts(){
    SetMyPatentLoaded(false);
    LoadUserPosts();
  }

  function OpenPatentUpload() {
    $('.Patent_PC .PatentUpload').css('display','flex');
  }

  function ClosePatentUpload(){
    $('.Patent_PC .PatentUpload').css('display','none');
  }

  function ShowIMG(e){
    SetShowIMAGE(e.target.src);
    $('.Patent_PC .ShowIMG').css('display','flex');
  }

  function CloseIMG(){
    $('.Patent_PC .ShowIMG').css('display','none');
  }

  function ShowIMG_MB(e){
    SetShowIMAGE(e.target.src);
    $('.Patent_MB .ShowIMG').css('display','flex');
  }

  function CloseIMG_MB(){
    $('.Patent_MB .ShowIMG').css('display','none');
  }

  // 데이터 업로드
  async function PatentUpload(e){
    e.preventDefault();
    if(window.confirm("업로드 하시겠습니까?")){   
      try {
        SetUploading(true);
        await DataUpload();
        alert("업로드 완료");
        window.location.reload(); 
      } catch (error) { console.log(error); } 
    }
    else{ alert("업로드 취소"); }
  }

   // Data 업로드 
   async function DataUpload(){
    try {
      const UserListRef = collection(props.db, "Patent"); // 컬렉션 선택
      const userData = {
        Name : Name.current.value,
        Agency : Agency.current.value,
        IMG : "",
        No : "999" ,
      };   
      const docRef = await addDoc(UserListRef, userData);
      const codeData ={ Code : docRef.id }
      updateDoc(docRef, codeData);
      await props.handleFileSelect(docRef, "Patent", imageFileInputRef1.current.files[0], IMGupload);
    } catch (error) {
      console.log(error);
    }
  }

  // 이미지 업로드 링크 설정
  async function CheckMemoryOver(e) {
    const file = e.target.files[0];   // 업로드 할 파일
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB

    if (file && file.size > maxSizeInBytes && file.size===0) {
      alert('파일 크기가 5MB를 초과합니다. 다른 파일을 선택해주세요.');
      e.target.value = ''; // 파일 선택 취소
      SetIMGupload(false);
    }
    else{
      SetIMGupload(true);
    }
  }

  return (
    <div className="Patent flex w-full justify-center">
      {Uploading ? 
      <div className='UploadingBlock flex flex-1 w-full h-full justify-center items-center'>
        <i className="fa-solid fa-circle-notch fa-spin"></i> 
      </div> : null}
      <div className='Patent_PC flex con w-full flex-col'>
        <div className='Sections flex w-full flex-col mb-40'>
          <div className='flex w-full pl-5 mb-20 flex-col flex-wrap gap-y-3'>
            <div className='flex w-full pr-20 items-center'>
              <div className='Title flex mr-4'>특허·인증</div>
              {PatentLoaded && ( props.UserData[0].Grade==="Manager" || props.UserData[0].Grade==="Master") && props.auth.currentUser.emailVerified ?   
              <div className='flex AddrSearchButton2 font-bold' onClick={OpenPatentUpload}>특허·인증 등록</div> : null}
            </div>
          </div>
          {MyPatentLoaded ? 
            <div>
              <ul className='PatentList grid w-full grid-cols-4 ml-5'>
                {UserPatentData.map((item) => (
                  <li key={item.index} className='flex flex-col flex-1'>
                    <img src={item.IMG} alt='이미지1' onClick={ShowIMG}></img>
                    <div className='PatentName flex flex-1'>{item.Name}</div>
                    <div className='PatentAgency flex'>
                      <p className="flex flex-1">인증기관 : {item.Agency}</p>
                    </div>      
                    {PatentLoaded && ( props.UserData[0].Grade==="Manager" || props.UserData[0].Grade==="Master") && props.auth.currentUser.emailVerified  ? 
                      <div className="flex gap-x-5">
                        <select value={item.No} onChange={(e) => props.handleChange(e, "Patent")} id={item.id}> 
                          <option value="1" id={item.Code}>1</option>
                          <option value="2" id={item.Code}>2</option>
                          <option value="3" id={item.Code}>3</option>
                          <option value="4" id={item.Code}>4</option>
                          <option value="5" id={item.Code}>5</option>
                          <option value="6" id={item.Code}>6</option>
                          <option value="7" id={item.Code}>7</option>
                          <option value="8" id={item.Code}>8</option>
                          <option value="9" id={item.Code}>9</option>
                          <option value="10" id={item.Code}>10</option>
                          <option value="999" id={item.Code}>999</option>
                        </select>
                        <button className="flex" onClick={(e) => props.DeleteDBwithIMG(e, item.Code, "Patent", item.IMG)}>
                          <i className="fa-solid fa-trash-can"></i>
                        </button> 
                      </div> : null}    
                  </li> 
                ))}
              </ul>
            </div>
          :  null}
        </div>
        <div className='PatentUpload flex w-full h-full justify-center items-center'>
          <div class='PatentUploadContext flex flex-col'>
            <div className="flex w-full h-10 justify-end">
              <i className="fa-solid fa-xmark text-2xl" onClick={ClosePatentUpload}></i>
            </div>
            <div className="flex w-full h-10 justify-center text-3xl mt-5 mb-10 font-bold">특허·인증 등록</div>
            <form onSubmit={PatentUpload} className='flex flex-col w-full'>
              <div className='flex w-full mb-5'>
                <input className="flex w-full" ref={Name} maxLength={100} type="text" placeholder='제목*' required/>	
              </div>
              <div className='flex w-full mb-5'>
                <input ref={Agency} maxLength={400} className='flex w-full h-20' type="text" placeholder='인증기관*' required/>	
              </div>
              <div className='flex mb-3'>
                <input type="file" ref={imageFileInputRef1} onChange={CheckMemoryOver} accept="image/*"/>
              </div>
              <div className='flex mt-5 w-full justify-center'>
                <button type="submit" className='Login_Submit flex flex-1 justify-center AddrSearchButton2'>특허·인증 등록하기</button>
              </div>
            </form> 
          </div>
        </div>
        <div className='ShowIMG flex w-full h-full justify-center items-center'>
          <div class='ShowIMGContext flex flex-col items-center justify-center' onClick={CloseIMG}>
            <i className="fa-solid fa-circle-xmark flex"></i>
            <img src={ShowIMAGE} alt='이미지' className='flex flex-1'></img>
          </div>
        </div>
      </div>

      <div className='Patent_MB flex w-full flex-col'>
        <div className='Sections flex w-full flex-col mb-40'>
          <div className='flex w-full mb-20 flex-col flex-wrap gap-y-3'>
            <div className='flex w-full pr-20 items-center'>
              <div className='Title flex'>특허·인증</div>
            </div>
          </div>
          {MyPatentLoaded ? 
            <div>
              <ul className='PatentList flex grid w-full grid-cols-2 gap-5'>
                {UserPatentData.map((item) => (
                  <li key={item.index} className='flex flex-col'>
                    <img src={item.IMG} className="flex" alt='이미지1' onClick={ShowIMG_MB}></img>
                    <div className='PatentName flex flex-1'>{item.Name}</div>
                    <div className='PatentAgency flex'>
                      <p className="flex flex-1">인증기관 : {item.Agency}</p>
                    </div>      
                  </li> 
                ))}
              </ul>
            </div>
          :  null}
        </div>
        <div className='ShowIMG flex w-full h-full justify-center items-center'>
          <div class='ShowIMGContext flex flex-col items-center justify-center' onClick={CloseIMG_MB}>
            <i className="fa-solid fa-circle-xmark flex"></i>
            <img src={ShowIMAGE} alt='이미지' className='flex flex-1'></img>
          </div>
        </div>
      </div>
    </div>

  );
}


export default Patent;
