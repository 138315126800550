import React, { useEffect, useState, useRef } from "react";
import './News.css';
import $ from 'jquery';

import { collection, getDocs, addDoc, query, where, doc, deleteDoc, orderBy, updateDoc } from "firebase/firestore"; // 데이터 읽어오기
import { getStorage, ref, deleteObject, uploadBytes, getDownloadURL } from 'firebase/storage';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";	// 추가

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

SwiperCore.use([Navigation, Pagination, Autoplay])

function News(props) {

  const [NewsLoaded, SetNewsLoaded]=useState(false);
  const [MyPostsLoaded, SetMyPostsLoaded]=useState(false);

  const [UserPostsData, SetUserPostsData] = useState(null); 
  let UserPostsDt = [];
  const [UserPostsPagingData, SetUserPostsPagingData] = useState(null); 
  let UserPostPaging = [];
  const [UserPostPageNum, SetUserPostPageNum] = useState(5);
  const [UserPostsArr, SetUserPostsArr] = useState(1);  // 1:최근 업로드 2:업로드 순

  const MetalFNaviPrev=useRef(null);
  const MetalFNaviNext=useRef(null);
  const MetalFPagination=useRef(null);
  const paginationMetal = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
    el : MetalFPagination.current,
  };

  const MetalFNaviPrev_MB=useRef(null);
  const MetalFNaviNext_MB=useRef(null);
  const MetalFPagination_MB=useRef(null);
  const paginationMetal_MB = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + '</span>';
    },
    el : MetalFPagination_MB.current,
  };

  const Name = useRef(null);	
  const Detail = useRef(null);		
  const FileInputRef1 = useRef(null);
  const FileInputRef2 = useRef(null);
  const FileInputRef3 = useRef(null);
  const [IMGupload1, SetIMGupload1] = useState(false);
  const [IMGupload2, SetIMGupload2] = useState(false);
  const [IMGupload3, SetIMGupload3] = useState(false);
  const [time, setTime] = useState(new Date());
  const [Uploading, SetUploading] = useState(false);
  const [ShowIMAGE, SetShowIMAGE] = useState("");

  useEffect(() => {
    reLoadUserPosts(UserPostsArr, UserPostPageNum);
    const interval = setInterval(() => {setTime(new Date()); }, 10000); // 1분마다 업데이트
    return () => clearInterval(interval);
  }, []);
  
  useEffect(()=>{
    if(props.UserData===null || props.UserData===undefined || props.UserData.length===0){
      SetNewsLoaded(false);
    }
    else{
      SetNewsLoaded(true);
    }
  },[props.UserData]);

  useEffect(()=>{
    if(UserPostsData===null || UserPostsData===undefined || UserPostsData.length===0){
      SetMyPostsLoaded(false);
    }
    else{
      if(UserPostsPagingData===null || UserPostsPagingData===undefined || UserPostsPagingData.length===0){
        SetMyPostsLoaded(false);
      }
      else{
        SetMyPostsLoaded(true); }
    }
  },[UserPostsData, UserPostsPagingData])

  async function LoadUserPosts(UserPostsArr, UserPostPageNum){
    SetUserPostsData(null);
    const UserListRef = collection(props.db, "News"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    let querySnapshot;
    switch (UserPostsArr) {   // 업로드순 정렬 설정
      case 1:
        querySnapshot = await getDocs(query(UserListRef, orderBy("Upload", "desc")));
        break;
      case 2:
        querySnapshot = await getDocs(query(UserListRef, orderBy("Upload", "asc")));
        break;
      default:
        break;
    }
    UserPostsDt.length=0; // 초기화 query 다음에 해야 2번씩이나 저장안됨
    querySnapshot.forEach((doc) => {
      UserPostsDt.push(doc.data());
    });
    UserPostPaging=[];
    for (let i = 0; i < UserPostsDt.length / UserPostPageNum; i++) {
      if(UserPostsDt.lenght%UserPostPageNum!==0) UserPostPaging.push({id : i+1});
      else UserPostPaging.push({id : i});
    }
    SetUserPostsData(UserPostsDt);  // 최종입력
    SetUserPostsPagingData(UserPostPaging);
  }

  function reLoadUserPosts(UserPostsArr, UserPostPageNum){
    SetMyPostsLoaded(false);
    LoadUserPosts(UserPostsArr, UserPostPageNum);
  }

  function OpenPostDetail(e){
    if($(e.target).find('i').hasClass('fa-rotate-270')){
      $(e.target).next().css('display','none'); 
      $(e.target).find('i').removeClass('fa-rotate-270');  
    }
    else{
      ClosePostDetail();
      $(e.target).next().css('display','flex');  
      $(e.target).find('i').addClass('fa-rotate-270');  
    }
  }

  function ClosePostDetail(){
    $(".News .Sections .NewsListContext").css('display','none');  // 클릭된 거 형제 노드들 안보이게 함.
    $(".News .Sections .NewsListBar i").removeClass('fa-rotate-270');  
  }

  function OpenNewsUpload() {
    $('.News .NewsUpload').css('display','flex');
  }

  function CloseNewsUpload(){
    $('.News .NewsUpload').css('display','none');
  }

  function PostUploadArr(e){ 
    if($(e.target).find('i').hasClass('fa-rotate-180')){
      $(e.target).find('i').removeClass('fa-rotate-180');
      SetUserPostsArr(1); // 1:최근 업로드 2:업로드 순
      reLoadUserPosts(1, UserPostPageNum);
    }
    else{
      $(e.target).find('i').addClass('fa-rotate-180');
      SetUserPostsArr(2);
      reLoadUserPosts(2, UserPostPageNum);
    }
  }

  function PostPageChange(e){
    SetUserPostPageNum(e.target.value);
    reLoadUserPosts(UserPostsArr, e.target.value);
  }

  function ShowIMG(e){
    SetShowIMAGE(e.target.src);
    $('.News .ShowIMG').css('display','flex');
  }

  function CloseIMG(){
    $('.News .ShowIMG').css('display','none');
  }

  // 데이터 업로드
  async function NewsUpload(e){
    e.preventDefault();
    if(window.confirm("업로드 하시겠습니까? 등록된 공지사항은 수정할 수 없습니다.")){   
      try {
        SetUploading(true);
        await DataUpload();
        alert("업로드 완료");
        window.location.reload(); 
      } catch (error) { console.log(error); } 
    }
    else{ alert("업로드 취소"); }
  }

   // Data 업로드 
   async function DataUpload(){
    try {
      let optionsDate = { year: 'numeric', month: '2-digit', day: '2-digit' };
      let optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
      let dateFormatted = time.toLocaleString(undefined, optionsDate); // "YYYY-MM-DD"
      let timeFormatted = time.toLocaleString(undefined, optionsTime); // "HH:MM:SS"
      let CurrentTime = dateFormatted + ' ' + timeFormatted; // "YYYY-MM-DD HH:MM:SS"
      const UserListRef = collection(props.db, "News"); // 컬렉션 선택
      const userData = {
        Upload : CurrentTime, 
        Name : Name.current.value,
        Detail : Detail.current.value,
        FILE1 : "",
        FILE2 : "",
        FILE3 : "",
      };   
      const docRef = await addDoc(UserListRef, userData);
      await updateDoc(docRef, { Code : docRef.id });
      await handleFileSelect(docRef);
    } catch (error) {
      console.log(error);
    }
  }

  // 이미지 업로드
  async function handleFileSelect(docRef) {
    const file1 = FileInputRef1.current.files[0];   // 업로드 할 파일
    const file2 = FileInputRef2.current.files[0];   // 업로드 할 파일
    const file3 = FileInputRef3.current.files[0];   // 업로드 할 파일
    const storage = getStorage(); // Firebase Storage 설정
    let storageRef;
    
    if(IMGupload1){
      if (file1!=="" || file1!== null || file1!==undefined) {
        let img1 = "News/" + docRef.id + "1";
        storageRef = ref(storage, img1); // 이미지를 저장할 경로 지정
        await uploadBytes(storageRef, file1)
        .then(async (snapshot) => {
          await getDownloadURL(storageRef)
          .then(async (url) => { await updateDoc(docRef, { FILE1 : url, FILE1Name : FileInputRef1.current.files[0].name }); })
          .catch((error) => {
            console.error('URL 가져오기 오류:', error);
          });
        })
        .catch((error) => {
          console.error("이미지 업로드 오류: ", error);
        });
      }  
    }

    if(IMGupload2){
      if (file2!=="" || file2!== null || file2!==undefined) {
        let img2 =  "News/" + docRef.id + "2";
        storageRef = ref(storage, img2); // 이미지를 저장할 경로 지정
        await uploadBytes(storageRef, file2)
        .then(async (snapshot) => {
          await getDownloadURL(storageRef)
          .then(async (url) => { await updateDoc(docRef, { FILE2 : url, FILE2Name : FileInputRef2.current.files[0].name }); })
          .catch((error) => {
            console.error('URL 가져오기 오류:', error);
          });
        })
        .catch((error) => {
          console.error("이미지 업로드 오류: ", error);
        });
      }  
    }

    if(IMGupload3){
      if (file3!=="" || file3!== null || file3!==undefined) {
        let img3 =  "News/" + docRef.id + "3";
        storageRef = ref(storage, img3); // 이미지를 저장할 경로 지정
        await uploadBytes(storageRef, file3)
        .then(async (snapshot) => {
          await getDownloadURL(storageRef)
          .then(async (url) => { await updateDoc(docRef, { FILE3 : url, FILE3Name : FileInputRef3.current.files[0].name }); })
          .catch((error) => {
            console.error('URL 가져오기 오류:', error);
          });
        })
        .catch((error) => {
          console.error("이미지 업로드 오류: ", error);
        });
      }   
    }   
  }

  // 이미지 업로드 링크 설정
  async function CheckMemoryOver(e) {
    const option = e.target.id;
    const file = e.target.files[0];   // 업로드 할 파일
    const maxSizeInBytes = 1 * 1024 * 1024; // 1MB

    if (file && file.size > maxSizeInBytes && file.size===0) {
      alert('파일 크기가 1MB를 초과합니다. 다른 파일을 선택해주세요.');
      e.target.value = ''; // 파일 선택 취소
      switch (option) {
        case "IMGUpload1":
          SetIMGupload1(false);
          break;
        case "IMGUpload2":
          SetIMGupload2(false);
          break;
        case "IMGUpload3":
          SetIMGupload3(false);
          break;
        default:
          break;
      }
    }
    else{
      switch (option) {
        case "IMGUpload1":
          SetIMGupload1(true);
          break;
        case "IMGUpload2":
          SetIMGupload2(true);
          break;
        case "IMGUpload3":
          SetIMGupload3(true);
          break;
        default:
          break;
      }
    }
  }

  return (
    <div className="News flex w-full justify-center">
      <div className='News_PC flex w-full con flex-col'>
        {Uploading ? 
        <div className='UploadingBlock flex flex-1 justify-center items-center'>
          <i className="fa-solid fa-circle-notch fa-spin"></i> 
        </div> : null}
        <div className='Sections flex w-full flex-col mb-40 items-center'>
          <div className='flex w-full pl-5 mb-10 flex-col flex-wrap gap-y-3'>
            <div className='flex w-full pr-20 items-center'>
              <div className='Title flex mr-4'>공지사항</div>
              {NewsLoaded && ( props.UserData[0].Grade==="Manager" || props.UserData[0].Grade==="Master") && props.auth.currentUser.emailVerified ?   
              <button className='AddrSearchButton2 flex h-14 ml-5 items-center' onClick={OpenNewsUpload}>공지사항 등록</button> : null}
            </div>
            <div className='flex flex-wrap gap-3'>
              <div className='AddrSearchButton3' onClick={PostUploadArr}>
                업로드 순 <i className="fa-solid fa-arrow-down ml-2"></i></div>
              <select onChange={PostPageChange} value={UserPostPageNum} className='AddrSearchButton4'>
                <option value={5}>5개씩</option>
                <option value={10}>10개씩</option>
                <option value={20}>20개씩</option>
                <option value={30}>30개씩</option>
              </select>
              </div>
          </div>
          {MyPostsLoaded ? 
            <Swiper
            className='List flex justify-center w-full flex-col' 
            spaceBetween={10}
            slidesPerView={1}
            initialSlide={0}

            navigation= {{ // 좌우 버튼 커스텀 
              prevEl: MetalFNaviPrev.current,
              nextEl: MetalFNaviNext.current
            }}
            touchRatio={0} // 드래기 넘기기 방지
            speed={0}
            pagination={paginationMetal}
            // autoplay
            // ={{ delay: 100, disableOnInteraction: false }}
            loop={false}
            loopPreventsSliding={false}
            >
              {UserPostsPagingData.map((arr)=>(
              <SwiperSlide className='w-full'>
                {UserPostsData.map((item, index) => (
                  <ul>
                  { (index+1) > UserPostPageNum*(arr.id-1) && (index+1) <= UserPostPageNum*arr.id ? 
                  <li key={item.index} id={item.Code} className='flex w-full flex-col'>
                    <div className='NewsListBar flex w-full items-center gap-3 flex-wrap' onClick={OpenPostDetail}>
                      <div className='flex w-3 justify-center'>{index+1}</div>
                      <div className='flex flex-1'>{item.Name}</div>
                      <div className='flex flex-1'>{item.Upload} Uploaded</div>
                      {NewsLoaded && ( props.UserData[0].Grade==="Manager" || props.UserData[0].Grade==="Master") && props.auth.currentUser.emailVerified  ? 
                      <button className='AddrSearchButton2' onClick={(e)=>props.DeleteDBwithIMG3(e, item.Code, "News", item.FILE1, item.FILE2, item.FILE3)}>삭제하기</button> : null}
                      <i class="fa-solid fa-chevron-left ml-3"></i>
                    </div>
                    <div className='NewsListContext flex w-full flex-col gap-3'>
                      <div className='flex w-full font-bold text-xl'> {item.Name}</div>
                      <div className='flex w-full'> 
                        <div className='flex'>상세내용 : </div>
                        <div className='flex flex-1 ml-2'>{item.Detail}</div> 
                      </div>
                      <div className='flex w-full gap-3'>
                        {item.FILE1==="" || item.FILE1===undefined || item.FILE1===null ? null : <button className="PinBasic"><a href={item.FILE1} target="_blank">{item.FILE1Name} 확인하기</a></button> }
                        {item.FILE2==="" || item.FILE2===undefined || item.FILE2===null ? null : <button className="PinBasic"><a href={item.FILE2} target="_blank">{item.FILE2Name} 확인하기</a></button> }
                        {item.FILE3==="" || item.FILE3===undefined || item.FILE3===null ? null : <button className="PinBasic"><a href={item.FILE3} target="_blank">{item.FILE2Name} 확인하기</a></button> }
                      </div>
                    </div>                                       
                  </li> : null}
                  </ul>
                ))}
              </SwiperSlide>))} 
            </Swiper>
          :  null}
          <div className="PagingTool flex w-full h-10 mt-5">
              <div className="flex flex-1"></div>
              <div className="swiper-buttonPrev flex justify-end items-center" onClick={ClosePostDetail}>
                <i className="fa-solid fa-chevron-left" ref={MetalFNaviPrev}></i>
              </div>
              <div className="flex flex-1 justify-center items-center" ref={MetalFPagination} onClick={ClosePostDetail}></div>
              <div className="swiper-buttonNext flex justify-start items-center" onClick={ClosePostDetail}>
                <i className="fa-solid fa-chevron-right" ref={MetalFNaviNext}></i>
              </div>
              <div className="flex flex-1"></div>
          </div>
        </div>
        <div className='NewsUpload flex w-full h-full justify-center items-center'>
          <div class='NewsUploadContext flex flex-col'>
            <div className="flex w-full h-10 justify-end">
              <i className="fa-solid fa-xmark text-2xl" onClick={CloseNewsUpload}></i>
            </div>
            <div className="flex w-full h-10 justify-center text-3xl mt-5 mb-10 font-bold">공지사항 등록</div>
            <form onSubmit={NewsUpload} className='flex flex-col w-full'>
              <div className='flex w-full mb-5'>
                <input className="flex w-full" ref={Name} maxLength={25} type="text" placeholder='제목*' required/>	
              </div>
              <div className='flex w-full mb-5'>
                <textarea ref={Detail} rows={8} cols={50} maxLength={400} className='flex w-full h-20' type="text" placeholder='상세*' required/>	
              </div>
              <div className='flex mb-3'>
                <input type="file" ref={FileInputRef1} onChange={CheckMemoryOver} id="IMGUpload1"/>
              </div>
              <div className='flex mb-3'>
                <input type="file" ref={FileInputRef2} onChange={CheckMemoryOver} id="IMGUpload2"/>
              </div>
              <div className='flex mb-3'>
                <input type="file" ref={FileInputRef3} onChange={CheckMemoryOver} id="IMGUpload3"/>
              </div>
              <div className='flex mt-5 w-full justify-center'>
                <button type="submit" className='Login_Submit flex flex-1 justify-center AddrSearchButton2'>등록하기</button>
              </div>
            </form> 
          </div>
        </div>
        <div className='ShowIMG flex w-full h-full justify-center items-center'>
          <div class='ShowIMGContext flex flex-col items-center justify-center' onClick={CloseIMG}>
            <i className="fa-solid fa-circle-xmark flex"></i>
            <img src={ShowIMAGE} alt='이미지' className='flex flex-1'></img>
          </div>
        </div>
      </div>

      <div className='News_MB flex w-full flex-col'>
        {Uploading ? 
        <div className='UploadingBlock flex flex-1 justify-center items-center'>
          <i className="fa-solid fa-circle-notch fa-spin"></i> 
        </div> : null}
        <div className='Sections flex w-full flex-col mb-40 items-center'>
          <div className='flex w-full mb-10 flex-col flex-wrap gap-y-3'>
            <div className='flex w-full items-center'>
              <div className='Title flex mr-4'>공지사항</div>
            </div>
            <div className='flex flex-wrap gap-3'>
              <div className='AddrSearchButton3' onClick={PostUploadArr}>
                업로드 순 <i className="fa-solid fa-arrow-down ml-2"></i></div>
              <select onChange={PostPageChange} value={UserPostPageNum} className='AddrSearchButton4'>
                <option value={5}>5개씩</option>
                <option value={10}>10개씩</option>
                <option value={20}>20개씩</option>
                <option value={30}>30개씩</option>
              </select>
              </div>
          </div>
          {MyPostsLoaded ? 
            <Swiper
            className='List flex justify-center w-full flex-col' 
            spaceBetween={10}
            slidesPerView={1}
            initialSlide={0}

            navigation= {{ // 좌우 버튼 커스텀 
              prevEl: MetalFNaviPrev_MB.current,
              nextEl: MetalFNaviNext_MB.current
            }}
            touchRatio={0} // 드래기 넘기기 방지
            speed={0}
            pagination={paginationMetal_MB}
            // autoplay
            // ={{ delay: 100, disableOnInteraction: false }}
            loop={false}
            loopPreventsSliding={false}
            >
              {UserPostsPagingData.map((arr)=>(
              <SwiperSlide className='w-full'>
                {UserPostsData.map((item, index) => (
                  <ul>
                  { (index+1) > UserPostPageNum*(arr.id-1) && (index+1) <= UserPostPageNum*arr.id ? 
                  <li key={item.index} id={item.Code} className='flex w-full flex-col'>
                    <div className='NewsListBar flex w-full items-center gap-3 flex-wrap' onClick={OpenPostDetail}>
                      <div className='flex w-3 justify-center'>{index+1}</div>
                      <div className="flex flex-col flex-1">
                        <div className='flex flex-1'>{item.Name}</div>
                        <div className='flex flex-1'>{item.Upload} Uploaded</div>
                      </div>
                      <i class="fa-solid fa-chevron-left ml-3"></i>
                    </div>
                    <div className='NewsListContext flex w-full flex-col gap-3'>
                      <div className='flex w-full font-bold text-xl'> {item.Name}</div>
                      <div className='flex w-full'> 
                        <div className='flex'>상세내용 : </div>
                        <div className='flex flex-1 ml-2'>{item.Detail}</div> 
                      </div>
                      <div className='flex w-full grid gap-3'>
                        {item.FILE1==="" || item.FILE1===undefined || item.FILE1===null ? null : <button className="PinBasic"><a href={item.FILE1} target="_blank">{item.FILE1Name} 확인하기</a></button> }
                        {item.FILE2==="" || item.FILE2===undefined || item.FILE2===null ? null : <button className="PinBasic"><a href={item.FILE2} target="_blank">{item.FILE2Name} 확인하기</a></button> }
                        {item.FILE3==="" || item.FILE3===undefined || item.FILE3===null ? null : <button className="PinBasic"><a href={item.FILE3} target="_blank">{item.FILE2Name} 확인하기</a></button> }
                      </div>
                    </div>                                       
                  </li> : null}
                  </ul>
                ))}
              </SwiperSlide>))} 
            </Swiper>
          :  null}
          <div className="PagingTool flex w-full h-10 mt-5">
              <div className="flex flex-1"></div>
              <div className="swiper-buttonPrev flex justify-end items-center" onClick={ClosePostDetail}>
                <i className="fa-solid fa-chevron-left" ref={MetalFNaviPrev_MB}></i>
              </div>
              <div className="flex flex-1 justify-center items-center" ref={MetalFPagination_MB} onClick={ClosePostDetail}></div>
              <div className="swiper-buttonNext flex justify-start items-center" onClick={ClosePostDetail}>
                <i className="fa-solid fa-chevron-right" ref={MetalFNaviNext_MB}></i>
              </div>
              <div className="flex flex-1"></div>
          </div>
        </div>
        <div className='ShowIMG flex w-full h-full justify-center items-center'>
          <div class='ShowIMGContext flex flex-col items-center justify-center' onClick={CloseIMG}>
            <i className="fa-solid fa-circle-xmark flex"></i>
            <img src={ShowIMAGE} alt='이미지' className='flex flex-1'></img>
          </div>
        </div>
      </div>
    </div>
  );
}


export default News;
