import { React, useEffect, useState, useRef } from "react";
import './productDetail.css';
import { Link, useNavigate  } from 'react-router-dom';

import ReactPlayer from 'react-player'

import { getFirestore, collection, getDocs, doc, query, orderBy, limit , where } from "firebase/firestore"; // 데이터 읽어오기

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay, EffectCreative } from "swiper";	// 추가

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-creative';

SwiperCore.use([Navigation, Pagination, Autoplay])

function ProductDetail(props_sub) {

  const [ProductInfoMB, SetProductInfoMB]=useState(null);
  const [ProductInfoMBLoading, SetProductInfoMBLoading]=useState(false);

  const [Product, SetProduct]=useState(null);
  const [ProductLoading, SetProductLoading]=useState(false);

  const [ProductBanner, SetProductBanner]=useState(null);
  const [ProductBannerLoading, SetProductBannerLoading]=useState(false);

  const swiperRef = useRef(null);
  const MetalFPagination=useRef(null);
  const swiperRef_MB = useRef(null);
  const MetalFPagination_MB=useRef(null);

  useEffect(()=>{
    LoadingProductData(props_sub.productType);
    LoadingProductBannerlData(props_sub.productType);
  },[])

  useEffect(()=>{
    LoadingProductData(props_sub.productType);
    LoadingProductBannerlData(props_sub.productType);
  },[props_sub.productType])

  async function LoadingProductData(productType){
    SetProductLoading(false);
    const UserListRef = collection(props_sub.db, "Product"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    let querySnapshot = await getDocs(query(UserListRef, orderBy('Type', 'asc'), orderBy('No', 'asc'),  where("Type", ">=", productType), where("Type", "<", productType + '\uf8ff')));
    let ProductDetailData=[];
    ProductDetailData.length=0;
    await querySnapshot.forEach((doc) => {
      ProductDetailData.push(doc.data());
    });
    SetProduct(ProductDetailData);
    console.log(ProductDetailData);
    SetProductLoading(true);
  }

  async function LoadingProductBannerlData(productType){
    SetProductBannerLoading(false);
    const UserListRef = collection(props_sub.db, "ProductBanner"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    let querySnapshot = await getDocs(query(UserListRef, orderBy('No', 'asc'), where("Type", "==", productType)));
    let ProductBannerData=[];
    ProductBannerData.length=0;
    await querySnapshot.forEach((doc) => {
      ProductBannerData.push(doc.data());
    });
    SetProductBanner(ProductBannerData);
    SetProductBannerLoading(true);
  }

  return (
    <div className="ProductDetail flex flex-1">
      <div className="ProductDetail_PC flex w-full flex-col">
        <div className="flex h-20"></div>
        {props_sub.productType!==null ? 
        <div className="flex flex-col mb-10">
          <div className="Middles mb-4 flex items-start gap-6">
            <p>{props_sub.productType}</p>
          </div>
          {props_sub.productType === "CHQ WIRE" ? 
            <p className="Description">냉간 압조용 선재를 말하며, 냉간 드로잉, 산세/피막, 열처리 공정으로 작업됩니다.</p>: null}
          {props_sub.productType === "CD BAR" ? 
            <p className="Description">연마, 절삭 등의 기계 가공용 소재로, 형태는 원형 및 육각, 사각, 팔각 등이 있습니다.</p>: null}
          {props_sub.productType === "CUTTING" ? 
            <p className="Description">원형 봉강 및 사각, 육각 등 이형제품까지 오차범위 0.1mm 이내 정교하며 깨끗한 마감으로 
                고객의 주문 사양에 맞추어 절단이 가능합니다.</p>: null}
        </div> : null }
        <div className="flex w-full justify-center ml-4">
        {ProductBannerLoading ?
          <Swiper
            className="mySwiper w-1/2 flex"
            ref={swiperRef}
            grabCursor={true}
            effect={'creative'}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [0, 0, -400],
              },
              next: {
                translate: ['100%', 0, 0],
              },
            }}
            modules={[EffectCreative]}
            pagination={{
              clickable: true,
              el : MetalFPagination.current}}
            // autoplay
            // ={{ delay: 5000, disableOnInteraction: true }}
            loop={true}
            loopPreventsSliding={false}
            // onSlideChange={(swiper) => handleSlideChange(swiper)}
            // onSwiper={handleVideoEnded}
          >
            {ProductBanner.map(pb=>(
            <SwiperSlide className="flex flex-col bg-white" key={pb.index}>
              {pb.Video==="" ? 
              <div className="ProductLists flex flex-1 items-center h-80 justify-center">No Video</div>
              :
              <div class="ProductLists flex flex-1">
                <ReactPlayer url={pb.Video} 
                width='600px' height='337px' muted={true} controls={false} playsinline={true} playing={true} loop={true} 
                config = { { 
                  youtube : { 
                    playerVars : {  showinfo : 0, iv_load_policy : 3  } 
                  } 
                } }  />
              </div>
              }
            </SwiperSlide>
          ))}
          </Swiper>: null }
          </div>
          <div className="flex flex-1 justify-center items-center mt-3" ref={MetalFPagination}></div>
        <div className="flex w-full flex-col items-center mt-28">    
        {ProductLoading ?
        <div className="List flex w-full items-center flex-col">
          <p className="Middles mb-10">주요 제품</p>

          {Product.filter(product=>product.Type==="CD BAR Other").map((product, index)=>(
            <div className='w-full flex flex-col h-full justify-center items-center mb-14' key={product.index}>
              <div className='ListsHeader w-full flex h-full justify-center items-center'>
                <div className="flex flex-1 pl-5 text-center">공급규격</div>
                <div className="flex flex-1 pl-5 text-center">두께</div>
                <div className="flex flex-1 pl-5 text-center">길이</div>
              </div>
              <div className="Lists w-full flex h-full justify-center items-center">
                <div className="flex flex-1 pl-5 text-center">{product.Form}</div>
                <div className="flex flex-1 pl-5 text-center">{product.Thick}</div>
                <div className="flex flex-1 pl-5 text-center">{product.Length}</div>
              </div>
              
          </div> ))} 

          <div className='ListsHeader w-full flex h-full justify-center items-center'>
            {props_sub.productType==="CUTTING" ?
              <div className="flex flex-1 justify-start pl-5">공급규격</div> : null }
            <div className="flex flex-1 justify-start pl-5">용도</div>
            <div className="flex flex-1 justify-start pl-5">강종</div>
            {props_sub.productType==="CHQ WIRE" ?
              <div className="flex flex-1 justify-start pl-5">공정</div> 
              : null }
          </div> 
          {Product.filter(product=>product.Type===props_sub.productType).map((product, index)=>(
          <div className='Lists w-full flex h-full justify-center items-center' key={product.index}>
            {props_sub.productType==="CUTTING" ? <div className="flex flex-1 justify-start pl-5">{product.Form}</div>: null }
            <div className="flex flex-1 justify-start pl-5">{product.Usage}</div>
            <div className="flex flex-1 justify-start pl-5">{product.Hard}</div>
            {props_sub.productType==="CHQ WIRE" ?
            <div className="flex flex-1 justify-start pl-5">{product.Process}</div> : null}
          </div>
          ))} 
        </div>
        : null}
        </div>
        <div className="flex h-20"></div>
        <div className="flex h-20"></div>
      </div>
      <div className="ProductDetail_MB flex w-full flex-col">
        <div className="flex h-20"></div>
        {props_sub.productType!==null ? 
        <div className="flex flex-col mb-10">
          <p className="Middles mb-4 flex ">{props_sub.productType}</p>
          {props_sub.productType === "CHQ WIRE" ? 
            <p className="Description">냉간 압조용 선재를 말하며, 냉간 드로잉, 산세/피막, 열처리 공정으로 작업됩니다.</p>: null}
          {props_sub.productType === "CD BAR" ? 
            <p className="Description">연마, 절삭 등의 기계 가공용 소재로, 형태는 원형 및 육각, 사각, 팔각 등이 있습니다.</p>: null}
          {props_sub.productType === "CUTTING" ? 
            <p className="Description">원형 봉강 및 사각, 육각 등 이형제품까지 오차범위 0.1mm 이내 정교하며 깨끗한 마감으로 
            고객의 주문 사양에 맞추어 절단이 가능합니다.</p>: null}
        </div> : null }
        {ProductBannerLoading ?
        <Swiper
          className="mySwiper w-full"
          ref={swiperRef_MB}
          grabCursor={true}
          effect={'creative'}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ['100%', 0, 0],
            },
          }}
          modules={[EffectCreative]}
          pagination={{
            clickable: true,
            el : MetalFPagination_MB.current}}
          // autoplay
          // ={{ delay: 5000, disableOnInteraction: true }}
          loop={true}
          loopPreventsSliding={false}
          // onSlideChange={(swiper) => handleSlideChange(swiper)}
          // onSwiper={handleVideoEnded}
        >
          {ProductBanner.map(pb=>(
          <SwiperSlide className="ProductLists flex flex-col items-center bg-white" key={pb.index}>
            {pb.Video==="" ? 
            <div className="flex flex-1 h-72 items-center justify-center">No Video</div>
            :
            <div class="flex flex-1 bg-black">
              <ReactPlayer url={pb.Video} 
              width='100%' height='280px' muted={true} controls={false} playsinline={true} playing={true}   loop={true}  />
            </div>
            }
          </SwiperSlide>
        ))}
        </Swiper>: null }
        <div className="flex flex-1 justify-center items-center mt-3" ref={MetalFPagination_MB}></div>          

        {ProductLoading ?
        <div className="flex w-full flex-col items-center mt-20">    
          <p className="Middles mb-10">주요 제품</p>
          
          {Product.filter(product=>product.Type==="CD BAR Other").map((product, index)=>(
            <div className='List flex flex-col w-full items-start mb-10' key={product.index}>
              <div className='ListsHeader flex w-full h-full justify-start items-center'>
                <div className="flex flex-1 h-full pl-5 items-center">공급규격</div>
                <div className="flex flex-1 h-full pl-5 items-center">두께</div>
                <div className="flex flex-1 h-full pl-5 items-center">길이</div>
              </div>
              <div className="Lists flex w-full h-full justify-start items-center">
                <div className="flex flex-1 h-full pl-5 items-center">{product.Form}</div>
                <div className="flex flex-1 h-full pl-5 items-center">{product.Thick}</div>
                <div className="flex flex-1 h-full pl-5 items-center">{product.Length}</div>
              </div>
          </div> ))} 
        
          <div className="List flex w-full items-start flex-col">  
            <div className='ListsHeader flex w-full h-full justify-start items-center'>
              {props_sub.productType==="CUTTING" ? <div className="flex w-48 pl-5 items-center">공급규격</div> : null }
              <div className="flex flex-1 h-full pl-5 items-center">용도</div>
              <div className="flex flex-1 h-full pl-5 items-center">강종</div>
              {props_sub.productType==="CHQ WIRE" ? <div className="flex w-48 pl-5 items-center">공정</div> : null }
            </div>
            {Product.map((product, index)=>(
            <div className='Lists flex w-full h-full justify-start items-center' key={product.index}>
              {product.Type==="CUTTING" ? 
                <div className="flex flex-1 h-full pl-5 items-center">{product.Form}</div> : null}
              <div className="flex flex-1 h-full pl-5 items-center">{product.Usage}</div>
              <div className="flex flex-1 h-full pl-5 items-center">{product.Hard}</div>
              {product.Type==="CHQ WIRE" ? 
                <div className="flex flex-1 h-full pl-5 items-center">{product.Process}</div> : null}
            </div> ))} 
          </div> 
        </div>: null}


        <div className="flex h-4"></div>
        <div className="flex h-20"></div>
      </div>
    </div>
  );
}

export default ProductDetail;