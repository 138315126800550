import React, { useEffect, useState, useRef } from "react";
import { useNavigate  } from 'react-router-dom';

import './Join.css';

import { collection, addDoc } from "firebase/firestore"; // 데이터 읽어오기
import { createUserWithEmailAndPassword, signOut } from "firebase/auth";

function Join(props) {
  const UserEmail = useRef(null);	
  const UserPW = useRef(null);	
  const UserName = useRef(null);

  const [Uploading, SetUploading] = useState(false);
  const navigate = useNavigate();  // Link없이 페이지 이동
  const [time, setTime] = useState(new Date());  // 시간

  useEffect(() => {
    document.body.classList.remove("HomeMap"); // 페이지 떠나기 감지 스크롤 고정해제
    const interval = setInterval(() => {setTime(new Date()); }, 10000); // 1분마다 업데이트
    return () => clearInterval(interval);
  }, []);

  async function UserInfoUpload(e){
    e.preventDefault(); 
    try {
      sendResetPasswordEmail();
    } catch (error) { console.log(error); } 
  }

  // 파이어베이스 이메일 인증 회원가입
  async function sendResetPasswordEmail(){
    try {
      const createdUser = await createUserWithEmailAndPassword(props.auth, UserEmail.current.value, UserPW.current.value);
      await DataUpload(createdUser.user);
    } catch(err){
      console.log(err.code);
      switch (err.code) {
        case 'auth/weak-password':
          alert('비밀번호는 6자리 이상이어야 합니다');
          break;
        case 'auth/invalid-email':
          alert('잘못된 이메일 주소입니다');
          break;
        case 'auth/email-already-in-use':
          alert('이미 가입되어 있는 계정입니다');
          break;
      }
    }
  }

  // Data 업로드 
  async function DataUpload(createdUser){
    try {
      let optionsDate = { year: 'numeric', month: '2-digit', day: '2-digit' };
      let optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
      let dateFormatted = time.toLocaleString(undefined, optionsDate); // "YYYY-MM-DD"
      let timeFormatted = time.toLocaleString(undefined, optionsTime); // "HH:MM:SS"
      let CurrentTime = dateFormatted + ' ' + timeFormatted; // "YYYY-MM-DD HH:MM:SS"
      const UserListRef = collection(props.db, "UserInfo"); // 컬렉션 선택
      const userData = {
        Grade : "Common",
        email : createdUser.email,
        UserCode : createdUser.uid,
        socialLogin : "",
        username : UserName.current.value,
        signedDate : CurrentTime
      };   
      const docRef = await addDoc(UserListRef, userData);
      await signOut(props.auth);
      alert(UserName.current.value+"님 가입을 축하드립니다.");
      navigate('/Login');
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <div className='Join flex justify-center'>
        {Uploading ? 
        <div className='UploadingBlock flex flex-1 justify-center items-center'>
          <i className="fa-solid fa-circle-notch fa-spin"></i> 
        </div> : null}
        <div className="Main-Banner con flex flex-col items-center w-full pt-10 pb-40">
          <div className='Title flex w-full'>회원가입</div> 
          <div className='flex flex-col pt-3 pl-5 pr-5 w-full items-center'>
            <form onSubmit={UserInfoUpload} className='flex flex-col w-full'>
              <div className='flex flex-wrap gap-3 w-full items-center mb-5'>
                <input ref={UserEmail} className='flex flex-1' type="text" placeholder="Email*" required/>
              </div>
              <div className='flex w-full items-center mb-5'>
                <input ref={UserPW} maxLength={40} className='flex flex-1' type="password" placeholder='PassWord*' required/>	 
              </div>	
              <div className='flex w-full items-center mb-5'>
                <input ref={UserName} maxLength={40} className='flex flex-1' type="text" placeholder='UserName*' required/>	 
              </div>
              <div className='flex mt-5 w-full justify-center mb-5'>
                <button type="submit" className='Login_Submit flex flex-1 justify-center AddrSearchButton2'>JOIN</button>
              </div>
            </form>
          </div> 
        </div>
      </div>
    </div>
  );
}


export default Join;
