import { React, useEffect, useState, useRef } from "react";

import './processDetail.css';
import { Link, useNavigate  } from 'react-router-dom';
import ReactPlayer from 'react-player'
import { getFirestore, collection, getDocs, doc, query, orderBy, limit , where } from "firebase/firestore"; // 데이터 읽어오기

import $ from 'jquery';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay, EffectCreative } from "swiper";	// 추가

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-creative';

SwiperCore.use([Navigation, Pagination, Autoplay])

function ProcessDetail(props_sub) {

  const [Equip, SetEquip]=useState(null);
  const [EquipLoading, SetEquipLoading]=useState(false);

  const [ProcessBanner, SetProcessBanner]=useState(null);
  const [ProcessBannerLoading, SetProcessBannerLoading]=useState(false);

  const [ProcessIcons, SetProcessIcons]=useState(null);
  const [ProcessIconsLoading, SetProcessIconsLoading]=useState(false);

  const [Facility, SetFacility]=useState(null);
  const [FacilityLoading, SetFacilityLoading]=useState(false);

  const swiperRef = useRef(null);
  const MetalFPagination=useRef(null);
  const swiperRef_MB = useRef(null);
  const MetalFPagination_MB=useRef(null);

  useEffect(()=>{
    LoadingEquipData(props_sub.productType);
    LoadingFacilityData(props_sub.productType);
    LoadingProcessIconsData(props_sub.productType);
    LoadingProcessBannerData(props_sub.productType);
  },[])

  useEffect(()=>{
    LoadingEquipData(props_sub.productType);
    LoadingFacilityData(props_sub.productType);
    LoadingProcessBannerData(props_sub.productType);
    LoadingProcessIconsData(props_sub.productType);
  },[props_sub.productType])

  async function LoadingEquipData(productType){
    SetEquipLoading(false);
    const UserListRef = collection(props_sub.db, "Equip"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    let querySnapshot = await getDocs(query(UserListRef, orderBy('No', 'asc'), where("Type", "==", productType)));
    let EquipData=[];
    EquipData.length=0;
    await querySnapshot.forEach((doc) => {
      EquipData.push(doc.data());
    });
    SetEquip(EquipData);
    SetEquipLoading(true);
  }

  async function LoadingProcessBannerData(productType){
    SetProcessBannerLoading(false);
    const UserListRef = collection(props_sub.db, "ProcessBanner"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    let querySnapshot = await getDocs(query(UserListRef, orderBy('No', 'asc'), where("Type", "==", productType)));
    let ProcessBanner=[];
    ProcessBanner.length=0;
    await querySnapshot.forEach((doc) => {
      ProcessBanner.push(doc.data());
    });
    SetProcessBanner(ProcessBanner);
    SetProcessBannerLoading(true);
  }

  async function LoadingProcessIconsData(productType){
    SetProcessIconsLoading(false);
    const UserListRef = collection(props_sub.db, "ProcessIcons"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    let querySnapshot = await getDocs(query(UserListRef, orderBy('No', 'asc'), where("Type", "==", productType)));
    let ProcessIcons=[];
    ProcessIcons.length=0;
    await querySnapshot.forEach((doc) => {
      ProcessIcons.push(doc.data());
    });
    SetProcessIcons(ProcessIcons);
    SetProcessIconsLoading(true);
  }

  async function LoadingFacilityData(productType){
    SetFacilityLoading(false);
    const UserListRef = collection(props_sub.db, "Facility"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    let querySnapshot = await getDocs(query(UserListRef, orderBy('No', 'asc'), where("Type", "==", productType)));
    let FacilityData=[];
    FacilityData.length=0;
    await querySnapshot.forEach((doc) => {
      FacilityData.push(doc.data());
    });
    SetFacility(FacilityData);
    SetFacilityLoading(true);
  }

  return (
    <div className="ProcessDetail flex flex-1">
      <div className="ProcessDetail_PC flex w-full flex-col">
        {props_sub.productType==="CUTTING" ? null : 
        <div className="flex flex-col mb-14">
          <div className="Middles mb-4 flex items-start gap-6">
            <p>공정소개</p>
          </div>
        </div> }
        {props_sub.productType==="CUTTING" ? null :
        <div className="flex w-full justify-center gap-14 mb-14">
          {ProcessIconsLoading ?
          <div className="flex flex-1 grid grid-cols-5 gap-4 justify-center pl-4">
            {ProcessIcons.map((pb, index)=>(
            <div className="ProcessIcons flex flex-col items-center cursor-pointer" key={pb.index}>
              <div className="flex"><img src={pb.ICONS} alt={pb.Name}></img></div>
              <p className="mt-3">{index+1}. {pb.Name}</p>
            </div>
            ))}
          </div>: null } 
        </div>}
        {ProcessBannerLoading ? 
        <div className="flex flex-col w-full justify-center">
          {ProcessBanner.map(processBanner=>(
          <div className="flex w-full justify-center">
            {processBanner.Display === "PC" ? <img className="flex ProcessIMG mb-28" src={processBanner.IMG}></img> : null}
          </div>
          ))}</div>: null}
        <p className="Middles w-full flex justify-center mb-20">주요 설비</p>
        <div className="flex w-full justify-center">
          {EquipLoading ? 
          <div className="flex flex-1 justify-center">                
            {Equip.map(equip=>(
            <div className="flex conhalf flex-col items-center bg-white" key={equip.index}>
              <img src={equip.IMG} alt={equip.Name}></img>
            </div>))}
          </div>: null}
          {FacilityLoading ?
          <Swiper
            className="mySwiper"
            ref={swiperRef}
            grabCursor={true}
            effect={'creative'}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [0, 0, -400],
              },
              next: {
                translate: ['100%', 0, 0],
              },
            }}
            modules={[EffectCreative]}
            pagination={{
              clickable: true,
              el : MetalFPagination.current}}
            // autoplay
            // ={{ delay: 5000, disableOnInteraction: true }}
            loop={true}
            loopPreventsSliding={false}
            // onSlideChange={(swiper) => handleSlideChange(swiper)}
            // onSwiper={handleVideoEnded}
          >
            {Facility.map(pb=>(
            <SwiperSlide className="flex flex-col items-center" key={pb.index}>
              {pb.Video==="" ? 
              <div className="ProductLists flex flex-1 items-center h-80 justify-center">No Video</div>
              :
              <div class="ProductLists flex flex-1 bg-black">
                <ReactPlayer url={pb.Video} 
                width='100%' height='305px' muted={true} controls={false} playsinline={true} playing={true} loop={true} 
                config = { { 
                  youtube : { 
                    playerVars : {  showinfo : 0, iv_load_policy : 3  } 
                  } 
                } }  />
              </div>
              }
            </SwiperSlide>
          ))}
          </Swiper>: null }
          <div className="flex flex-1 justify-center items-center mt-3" ref={MetalFPagination}></div>
        </div>  
        <div className="flex h-20"></div>
        <div className="flex h-20"></div>
      </div>
      <div className="ProcessDetail_MB flex w-full flex-col">
        { props_sub.productType !== "CUTTING" ? 
        <p className="Middles mb-14 flex items-start">공정소개</p> : null }
        { props_sub.productType !== "CUTTING" ? 
        <div className="flex w-full flex-col items-center gap-14 mb-10">
          {ProcessIconsLoading ?
          <div className="flex flex-wrap flex-1 flex-wrap gap-x-2 gap-y-6 justify-center">
            {ProcessIcons.map((pb,index)=>(
            <div className="ProcessIcons flex flex-col w-28 items-center cursor-pointer" key={pb.index}>
              <div className="flex"><img src={pb.ICONS} alt={pb.Name}></img></div>
              <p className="mt-3">{index+1}. {pb.Name}</p>
            </div>
            ))}
          </div>: null }
        </div> : null }
        {ProcessBannerLoading ? 
        <div className="flex w-full flex-col">
          {ProcessBanner.map(processBanner=>(
          <div className="flex w-full justify-center">
            {processBanner.Display === "MOBILE" ? <img className="flex ProcessIMG mb-24" src={processBanner.IMG}></img> : null}
          </div>
        ))}</div>: null}
        <p className="Middles w-full flex justify-center mb-5">주요 설비</p>
        <div className="flex w-full flex-col items-center">
          {EquipLoading ? 
          <div className="flex mb-4">    
          {Equip.map(equip=>(
          <div className="flex flex-col items-center bg-white" key={equip.index}>
            <img src={equip.IMG} alt={equip.Name}></img>
          </div>))}
          </div> : null}
          {FacilityLoading ?
          <Swiper
            className="mySwiper w-full"
            ref={swiperRef_MB}
            grabCursor={true}
            effect={'creative'}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [0, 0, -400],
              },
              next: {
                translate: ['100%', 0, 0],
              },
            }}
            modules={[EffectCreative]}
            pagination={{
              clickable: true,
              el : MetalFPagination_MB.current}}
            // autoplay
            // ={{ delay: 5000, disableOnInteraction: true }}
            loop={true}
            loopPreventsSliding={false}
            // onSlideChange={(swiper) => handleSlideChange(swiper)}
            // onSwiper={handleVideoEnded}
          >
            {Facility.map(pb=>(
            <SwiperSlide className="ProductLists flex flex-col items-center bg-white" key={pb.index}>
              {pb.Video==="" ? 
              <div className="flex flex-1 h-72 items-center justify-center">No Video</div>
              :
              <div class="flex flex-1 bg-black">
                <ReactPlayer url={pb.Video} 
                width='100%' height='280px' muted={true} controls={false} playsinline={true} playing={true}   loop={true}  />
              </div>
              }
            </SwiperSlide>
          ))}
          </Swiper>: null }
          <div className="flex flex-1 justify-center items-center mt-3" ref={MetalFPagination_MB}></div>       
        </div>  
        <div className="flex h-20"></div>
        <div className="flex h-10"></div>
      </div>
    </div>
  );
}

export default ProcessDetail;