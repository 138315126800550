import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import $ from 'jquery';

import './Menu.css';

function Menu(props) {

  const[MBmenubar, SetMBmenubar]=useState(false);
  const MBmenus = useRef(null);

  const [IsLogined, SetIsLogined] = useState(false);
  const navigate = useNavigate();  // Link없이 페이지 이동

  useEffect(()=>{
    $(".Menu_PC-right > .Menu_PC-bottom").stop().slideUp(0);

    if(props.UserData===null || props.UserData===undefined || props.UserData.length===0){
      SetIsLogined(false);
    }
    else{
      SetIsLogined(true);
    }
  },[])

  useEffect(()=>{
    if(props.UserData===null || props.UserData===undefined || props.UserData.length===0){
      SetIsLogined(false);
    }
    else{
      SetIsLogined(true);
    }
  },[props.UserData])

  const sideOutput = () => {
    if(MBmenus.current.nextElementSibling.classList.contains('Output')){
      MBmenus.current.nextElementSibling.classList.remove('Output');
      SetMBmenubar(false);
    }
    else{
      MBmenus.current.nextElementSibling.classList.add('Output');
      SetMBmenubar(true);
    }
  }

  function linkClicked(){
    if(MBmenus.current.nextElementSibling.classList.contains('Output')){
      MBmenus.current.nextElementSibling.classList.remove('Output');
      SetMBmenubar(false);
    }

    $(window).scrollTop(0);
    console.clear();
  }

  $(window).on('mousewheel',function(e){
      var wheel = e.originalEvent.wheelDelta;
      if(wheel>0){
        $(".Menu").stop().slideDown(500);
      } 
      else {
        $(".Menu").stop().slideUp(500);
      }     
  });

  function MenuScrollUp(){
    $(".Menu_PC-right > .Menu_PC-bottom").stop().slideUp(500);
  }

  function MenuScrollDown(){
    $(".Menu_PC-right > .Menu_PC-bottom").stop().slideDown(500);
  }

   // 로그아웃, 회원정보 삭제
   async function LogoutHandle(){
    SetIsLogined(false); 
    await signOut(props.auth)
    .then(async ()=>{
      navigate('/');
      window.location.reload();
    })
    .catch((error) => {
      console.log(error);
    });
  }

  return (
    <div className="Menu">
      <div className="Menu_PC flex flex-col w-full items-center">
        <div className="Menu_PC-1line flex h-full items-center">
          <div className="Menu_PC-left flex justify-start">
            <Link to="" onClick={linkClicked}><img src="https://firebasestorage.googleapis.com/v0/b/youngwoo-37745.appspot.com/o/Logos%2F%E1%84%85%E1%85%A9%E1%84%80%E1%85%A9.png?alt=media&token=0f5a36ac-5d05-4212-85c7-3ba1866f8c62" alt="영우특수강" /></Link>
          </div>
          <div className="flex flex-1"></div>
          {IsLogined ? 
          <div className="Menu_Fixed_PC-left flex justify-center gap-x-6 mr-6">
            <Link to="/Posting" className='PinGray flex cursor-pointer'>관리자 페이지</Link>
            <div className='PinGray flex cursor-pointer' onClick={LogoutHandle}>LogOut</div>
          </div>
          : null }
          <ul className="Menu_PC-right flex h-full" onMouseEnter={MenuScrollDown} onMouseLeave={MenuScrollUp}>
            <li className="flex flex-1 justify-center items-center">
              <Link to="/AboutUs" onClick={linkClicked}>회사소개</Link>
            </li>
            <li className="flex flex-1 justify-center items-center">
              <Link to="/CHQWire" onClick={linkClicked}>생산</Link>
            </li>
            <li className="flex flex-1 justify-center items-center">
              <Link to="/News" onClick={linkClicked}>고객지원</Link>
            </li>
            <div className="Menu_PC-bottom flex justify-center">
              <div className="Menu_PC-bottom-group flex">
                <div className="flex flex-1"></div>
                <div className="Menu_PC-bottom-GMenu flex flex-col items-center h-full">
                  <div className="flex flex-1 justify-center"><Link to="/AboutUs" onClick={linkClicked}>회사소개</Link></div>
                  <div className="flex flex-1 justify-center"><Link to="/Patent" onClick={linkClicked}>특허·인증</Link></div>
                  <div className="flex flex-1 justify-center"><Link to="/Location" onClick={linkClicked}>사업장소개</Link></div>
                </div>
                <div className="Menu_PC-bottom-GMenu flex flex-col items-center h-full">
                  <div className="flex flex-1 justify-center"><Link to="/CHQWire" onClick={linkClicked}>CHQ WIRE</Link></div>
                  <div className="flex flex-1 justify-center"><Link to="/CDBar" onClick={linkClicked}>CD BAR</Link></div>
                  <div className="flex flex-1 justify-center"><Link to="/Cutting" onClick={linkClicked}>CUTTING</Link></div>
                </div>
                <div className="Menu_PC-bottom-GMenu flex flex-col items-center h-full">
                  <div className="flex flex-1 justify-center"><Link to="/News" onClick={linkClicked}>공지사항</Link></div>
                  <div className="flex flex-1 justify-center"></div>
                  <div className="flex flex-1 justify-center"></div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>

      <div className="Menu_MB flex w-full items-center">
        <div className="Menu_MB-left flex">
          <Link to="" onClick={linkClicked}><img src="https://firebasestorage.googleapis.com/v0/b/youngwoo-37745.appspot.com/o/Logos%2F%E1%84%85%E1%85%A9%E1%84%80%E1%85%A9.png?alt=media&token=0f5a36ac-5d05-4212-85c7-3ba1866f8c62" alt="영우특수강" /></Link>
        </div>
        <div className="flex flex-1"></div>
        <div className="Menu_MB-right flex justify-end items-center" onClick={sideOutput} ref={MBmenus}>
          {MBmenubar ? 
          <i className="fa-solid fa-xmark text-black"></i>
          : 
          <i className="fa-solid fa-bars"></i>
          }
        </div>
        <div className="Menu_MB-lists flex">
          <ul className="Menu_MB-lists-menu flex flex-1 flex-col">
            <i className="fa-solid fa-xmark text-black" onClick={sideOutput}></i>
            {IsLogined ? 
            <li className="flex justify-start flex-col">
              <div className='flex'>
                <i className="fa-regular fa-circle-user text-6xl"></i>
              </div>
              <div className='flex mt-2 text-sm'>{props.UserData[0].Grade}</div>
              <div className='flex text-2xl mt-1 font-bold'>{props.UserData[0].username}</div>

              <div onClick={LogoutHandle} className='flex mt-4 text-sm AddrSearchButton w-20 justify-center'>LogOut</div>
            </li>
            : null }
            <li className="flex flex-col">
              <Link to="/AboutUs" onClick={linkClicked}>회사소개</Link>
              <ul className="flex flex-col w-full">
                <div className="flex h-1"></div>
                <li className="flex"><Link to="/AboutUs" onClick={linkClicked}>회사소개</Link></li>
                <li className="flex"><Link to="/Patent" onClick={linkClicked}>특허·인증</Link></li>
                <li className="flex"><Link to="/Location" onClick={linkClicked}>사업장소개</Link></li>
              </ul>
            </li>
            <li className="flex flex-col">
              <Link to="/CHQWire" onClick={linkClicked}>생산</Link>
              <ul className="flex flex-col w-full">
                <div className="flex h-1"></div>
                <li className="flex"><Link to="/CHQWire" onClick={linkClicked}>CHQ WIRE</Link></li>
                <li className="flex"><Link to="/CDBar" onClick={linkClicked}>CD BAR</Link></li>
                <li className="flex"><Link to="/Cutting" onClick={linkClicked}>CUTTING / PRECISION</Link></li>
              </ul>
            </li>
            <li className="flex flex-col">
              <Link to="/News" onClick={linkClicked}>고객지원</Link>
              <ul className="flex flex-col w-full">
                <div className="flex h-1"></div>
                <li className="flex"><Link to="/News" onClick={linkClicked}>공지사항</Link></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Menu;
