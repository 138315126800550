import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import $ from 'jquery';

import './Menu_Fixed.css';

function MenuFixed(props) {

  const[MBMenu_Fixedbar, SetMBMenu_Fixedbar]=useState(false);
  const MBMenu_Fixed = useRef(null);

  const [IsLogined, SetIsLogined] = useState(false);
  const navigate = useNavigate();  // Link없이 페이지 이동

  useEffect(()=>{
    $(".Menu_Fixed_PC-right > .Menu_Fixed_PC-bottom").stop().slideUp(0);
    Menu_Fixed_ScollTop();
    Menu_Fixed_MBStay();

    if(props.UserData===null || props.UserData===undefined || props.UserData.length===0){
      SetIsLogined(false);
    }
    else{
      SetIsLogined(true);
    }
  },[])

  useEffect(()=>{
    if(props.UserData===null || props.UserData===undefined || props.UserData.length===0){
      SetIsLogined(false);
    }
    else{
      SetIsLogined(true);
    }
  },[props.UserData])

  const sideOutput = () => {
    if(MBMenu_Fixed.current.nextElementSibling.classList.contains('Output')){
      MBMenu_Fixed.current.nextElementSibling.classList.remove('Output');
      SetMBMenu_Fixedbar(false);
    }
    else{
      MBMenu_Fixed.current.nextElementSibling.classList.add('Output');
      SetMBMenu_Fixedbar(true);
    }
  }

  function linkClicked(){
    if(MBMenu_Fixed.current.nextElementSibling.classList.contains('Output')){
      MBMenu_Fixed.current.nextElementSibling.classList.remove('Output');
      SetMBMenu_Fixedbar(false);
    }

    $(window).scrollTop(0);
    console.clear();
  }

  $(window).on('mousewheel',function(e){
      var wheel = e.originalEvent.wheelDelta;
      if(wheel>0){
        if ($(this).scrollTop() <= 400 ) {
          $(".Menu_Fixed").stop().slideDown(500);
          $(".Menu_Fixed img").attr("src","https://firebasestorage.googleapis.com/v0/b/youngwoo-37745.appspot.com/o/Logos%2F%E1%84%85%E1%85%A9%E1%84%80%E1%85%A9white.png?alt=media&token=0ea204de-8773-4479-8573-7a4f184a70d5");
          Menu_Fixed_ScollTop();
          Menu_Fixed_MBStay();
        } else {
          $(".Menu_Fixed").stop().slideDown(500);
          $(".Menu_Fixed img").attr("src","https://firebasestorage.googleapis.com/v0/b/youngwoo-37745.appspot.com/o/Logos%2F%E1%84%85%E1%85%A9%E1%84%80%E1%85%A9.png?alt=media&token=0f5a36ac-5d05-4212-85c7-3ba1866f8c62");
          Menu_Fixed_ScollNonTop();
          Menu_Fixed_MBStay();
        }
      } 
      else {
        $(".Menu_Fixed").stop().slideUp(500);
      }     
  });

  function Menu_FixedScrollUp(e){
    $(".Menu_Fixed_PC-right > .Menu_Fixed_PC-bottom").stop().slideUp(500);
    if($(window).scrollTop() <= 400 ){
      Menu_Fixed_ScollTop();
    }else{
      Menu_Fixed_ScollNonTop();
    }
    Menu_Fixed_MBStay();
  }

  function Menu_FixedScrollDown(){
    $(".Menu_Fixed_PC-right > .Menu_Fixed_PC-bottom").stop().slideDown(500);
    Menu_Fixed_ScollNonTop();
    Menu_Fixed_MBStay();
  }

  function Menu_Fixed_ScollNonTop(){
    $(".Menu_Fixed").css('backgroundColor','rgba(255,255,255,1)');
    $(".Menu_Fixed").css('color','black');
    $(".Menu_Fixed img").attr("src","https://firebasestorage.googleapis.com/v0/b/youngwoo-37745.appspot.com/o/Logos%2F%E1%84%85%E1%85%A9%E1%84%80%E1%85%A9.png?alt=media&token=0f5a36ac-5d05-4212-85c7-3ba1866f8c62");
  }

  function Menu_Fixed_ScollTop(){
    $(".Menu_Fixed").css('backgroundColor','rgba(0,0,0,0.2)');
    $(".Menu_Fixed").css('color','white');
    $(".Menu_Fixed img").attr("src","https://firebasestorage.googleapis.com/v0/b/youngwoo-37745.appspot.com/o/Logos%2F%E1%84%85%E1%85%A9%E1%84%80%E1%85%A9white.png?alt=media&token=0ea204de-8773-4479-8573-7a4f184a70d5");
  }

  function Menu_Fixed_MBStay(){
    $(".Menu_Fixed_MB-lists").css('backgroundColor','rgba(255,255,255,1)');
    $(".Menu_Fixed_MB-lists").css('color','black');
  }

  // 로그아웃, 회원정보 삭제
  async function LogoutHandle(){
    SetIsLogined(false); 
    await signOut(props.auth)
    .then(async ()=>{
      navigate('/');
      window.location.reload();
    })
    .catch((error) => {
      console.log(error);
    });
  }

  return (
    <div className="Menu_Fixed">
      <div className="Menu_Fixed_PC flex flex-col w-full items-center">
        <div className="Menu_Fixed_PC-1line flex h-full items-center">
          <div className="Menu_Fixed_PC-left flex justify-start">
            <Link to="" onClick={linkClicked}><img src="https://firebasestorage.googleapis.com/v0/b/youngwoo-5c34b.appspot.com/o/Design%2F%E1%84%85%E1%85%A9%E1%84%80%E1%85%A9white.png?alt=media&token=9a821322-47be-4228-8657-7de050b32b75" alt="영우특수강" /></Link>
          </div>
          <div className="flex flex-1"></div>
          {IsLogined ? 
          <div className="Menu_Fixed_PC-left flex justify-center w-60 gap-x-6">
            <Link to="/Posting" className='flex cursor-pointer'>관리자 페이지</Link>
            <div className='flex cursor-pointer' onClick={LogoutHandle}>LogOut</div>
          </div>
          : null }
          <ul className="Menu_Fixed_PC-right flex h-full" onMouseEnter={Menu_FixedScrollDown} onMouseLeave={Menu_FixedScrollUp}>
            <li className="flex flex-1 justify-center items-center">
              <Link to="/AboutUs" onClick={linkClicked}>회사소개</Link>
            </li>
            <li className="flex flex-1 justify-center items-center">
              <Link to="CHQWire" onClick={linkClicked}>생산</Link>
            </li>
            <li className="flex flex-1 justify-center items-center">
              <Link to="/News" onClick={linkClicked}>고객지원</Link>
            </li>
            <div className="Menu_Fixed_PC-bottom flex justify-center">
              <div className="Menu_Fixed_PC-bottom-group flex">
                <div className="flex flex-1"></div>
                <div className="Menu_Fixed_PC-bottom-GMenu_Fixed flex flex-col items-center h-full">
                  <div className="flex flex-1 justify-center"><Link to="/AboutUs" onClick={linkClicked}>회사소개</Link></div>
                  <div className="flex flex-1 justify-center"><Link to="/Patent" onClick={linkClicked}>특허·인증</Link></div>
                  <div className="flex flex-1 justify-center"><Link to="/Location" onClick={linkClicked}>사업장소개</Link></div>
                </div>
                <div className="Menu_PC-bottom-GMenu flex flex-col items-center h-full">
                  <div className="flex flex-1 justify-center"><Link to="/CHQWire" onClick={linkClicked}>CHQ WIRE</Link></div>
                  <div className="flex flex-1 justify-center"><Link to="/CDBar" onClick={linkClicked}>CD BAR</Link></div>
                  <div className="flex flex-1 justify-center"><Link to="/Cutting" onClick={linkClicked}>CUTTING</Link></div>
                </div>
                <div className="Menu_Fixed_PC-bottom-GMenu_Fixed flex flex-col items-center h-full">
                  <div className="flex flex-1 justify-center"><Link to="/News" onClick={linkClicked}>공지사항</Link></div>
                  <div className="flex flex-1 justify-center"></div>
                  <div className="flex flex-1 justify-center"></div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>

      <div className="Menu_Fixed_MB flex w-full items-center">
        <div className="Menu_Fixed_MB-left flex">
          <Link to="" onClick={linkClicked}><img src="https://firebasestorage.googleapis.com/v0/b/youngwoo-37745.appspot.com/o/Logos%2F%E1%84%85%E1%85%A9%E1%84%80%E1%85%A9white.png?alt=media&token=0ea204de-8773-4479-8573-7a4f184a70d5" alt="영우특수강" /></Link>
        </div>
        <div className="flex flex-1"></div>
        <div className="Menu_Fixed_MB-right flex justify-end items-center" onClick={sideOutput} ref={MBMenu_Fixed}>
          {MBMenu_Fixedbar ? 
          <i className="fa-solid fa-xmark text-black"></i>
          : 
          <i className="fa-solid fa-bars"></i>
          }
        </div>
        <div className="Menu_Fixed_MB-lists flex">
          <ul className="Menu_Fixed_MB-lists-Menu_Fixed flex flex-1 flex-col">
            <i className="fa-solid fa-xmark text-black" onClick={sideOutput}></i>
            {IsLogined ? 
            <li className="flex justify-start flex-col">
              <div className='flex'>
                <i className="fa-regular fa-circle-user text-6xl"></i>
              </div>
              <div className='flex mt-2 text-sm'>{props.UserData[0].Grade}</div>
              <div className='flex text-2xl mt-1 font-bold'>{props.UserData[0].username}</div>

              <div onClick={LogoutHandle} className='flex mt-4 text-sm AddrSearchButton w-20 justify-center'>LogOut</div>
            </li>
            : null }
            <li className="flex flex-col">
              <Link to="/AboutUs" onClick={linkClicked}>회사소개</Link>
              <ul className="flex flex-col w-full">
                <div className="flex h-1"></div>
                <li className="flex"><Link to="/AboutUs" onClick={linkClicked}>회사소개</Link></li>
                <li className="flex"><Link to="/Patent" onClick={linkClicked}>특허·인증</Link></li>
                <li className="flex"><Link to="/Location" onClick={linkClicked}>사업장소개</Link></li>
              </ul>
            </li>
            <li className="flex flex-col">
              <Link to="/CHQWire" onClick={linkClicked}>생산</Link>
              <ul className="flex flex-col w-full">
                <div className="flex h-1"></div>
                <li className="flex"><Link to="/CHQWire" onClick={linkClicked}>CHQ WIRE</Link></li>
                <li className="flex"><Link to="/CDBar" onClick={linkClicked}>CD BAR</Link></li>
                <li className="flex"><Link to="/Cutting" onClick={linkClicked}>CUTTING / PRECISION</Link></li>
              </ul>
            </li>
            <li className="flex flex-col">
              <Link to="/News" onClick={linkClicked}>고객지원</Link>
              <ul className="flex flex-col w-full">
                <div className="flex h-1"></div>
                <li className="flex"><Link to="/News" onClick={linkClicked}>공지사항</Link></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MenuFixed;
