import { React, useEffect, useRef, useState } from "react";
import './AboutUs.css';
import $ from 'jquery';

import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs, doc, query, orderBy, limit , where} from "firebase/firestore"; // 데이터 읽어오기

function AboutUs(props) {

  const [BannerDb, SetBannerDb] = useState(null);
  const [BannerLoading, SetBannerLoading] = useState(false);

  const [CIDb, SetCIDb]=useState(null);
  const [CILoading, SetCILoading]=useState(false);

  const [HistoryDb, SetHistoryDb]=useState(null);
  let HistoryData=[];
  const [HistoryLoading, SetHistoryLoading]=useState(false);

  const [HistoryYearPC, SetHistoryYearPC]=useState(2023);
  const HistoryYearTopPC=useRef(null);
  const HistoryMonthTopPC=useRef(null);

  const [HistoryYearMB, SetHistoryYearMB]=useState(2023);
  const HistoryYearTopMB=useRef(null);
  const HistoryMonthTopMB=useRef(null);

  const [OrgDb, SetOrgDb]=useState(null);
  let OrgData=[];
  const [OrgLoading, SetOrgLoading]=useState(false);

  const [CustomerDb, SetCustomerDb]=useState(null);
  let CustomerData=[];
  const [CustomerLoading, SetCustomerLoading]=useState(false);

  const [scrollY, SetscrollY]=useState(null);

  useEffect(()=>{
    $(".AboutUs_PC-sub-menu").stop().slideUp(0);
    $(".AboutUs_MB-sub-menu").stop().slideUp(0);

    LoadingCIData();
    LoadingHistoryData();
    LoadingORGData();
    LoadingCustomerData();
    LoadingBannerData("AboutUs");
  },[])

  useEffect(()=>{
    if (HistoryLoading===true && HistoryMonthTopPC!==null && HistoryYearTopPC!==null) {
      ChangeYearPC();
    }

    if (HistoryLoading===true && HistoryMonthTopMB!==null && HistoryYearTopMB!==null) {
      ChangeYearMB();
    }
  },[scrollY])

  window.addEventListener('scroll', (e) => {
    SetscrollY(window.scrollY);
  })

  async function LoadingBannerData(Page){
    SetBannerLoading(false);
    const UserListRef = collection(props.db, "Banner"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, where("Page", "==", Page)));
    let Banner = [];
    await querySnapshot.forEach((doc) => {
      Banner.push(doc.data());
    });
    SetBannerDb(Banner);
    SetBannerLoading(true);
  }

  async function LoadingCIData(){
    SetCILoading(false);
    const UserListRef = collection(props.db, "CI"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("No", "asc")));
    let CI = [];
    await querySnapshot.forEach((doc) => {
      CI.push(doc.data());
    });
    SetCIDb(CI);
    SetCILoading(true);
  }

  async function LoadingHistoryData(){
    SetHistoryLoading(false);
    const UserListRef = collection(props.db, "History"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("Year","desc"), orderBy("Month","desc")));
    HistoryData.length=0;
    await querySnapshot.forEach((doc) => {
      HistoryData.push(doc.data());
    });
    SetHistoryDb(HistoryData);
    SetHistoryLoading(true);
  }

  async function LoadingORGData(){
    SetOrgLoading(false);
    const UserListRef = collection(props.db, "Organize"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef));
    OrgData.length=0;
    await querySnapshot.forEach((doc) => {
      OrgData.push(doc.data());
    });
    SetOrgDb(OrgData);
    SetOrgLoading(true);
  }

  async function LoadingCustomerData(){
    SetCustomerLoading(false);
    const UserListRef = collection(props.db, "Customer"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("No", "asc")));
    CustomerData.length=0;
    await querySnapshot.forEach((doc) => {
      CustomerData.push(doc.data());
    });
    SetCustomerDb(CustomerData);
    SetCustomerLoading(true);
  }

  function ChangeYearPC() {
    const historyYear=HistoryYearTopPC.current.offsetTop;
    const array=HistoryMonthTopPC.current.childNodes;
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element.classList.contains('Sticky')) {
        element.classList.remove('Sticky');
      }
      if(historyYear>=element.offsetTop && historyYear<=(element.offsetTop+element.offsetHeight)){
        const decadeElement=parseInt(element.id/10)*10;
        SetHistoryYearPC(decadeElement);
      }
    }
    ChangeYearColorPC();
  }

  function ChangeYearColorPC() {
    const a = document.getElementsByName(`${HistoryYearPC}`);
    for (let i = 0; i < a.length; i++) {
      const element = a[i];
      element.classList.add('Sticky'); 
    }
  }

  function ChangeYearMB() {
    const historyYear=HistoryYearTopMB.current.offsetTop;
    const array=HistoryMonthTopMB.current.childNodes;
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element.classList.contains('Sticky')) {
        element.classList.remove('Sticky');
      }
      if(historyYear>=element.offsetTop && historyYear<=(element.offsetTop+element.offsetHeight)){
        const decadeElement=parseInt(element.id/10)*10;
        SetHistoryYearMB(decadeElement);
      }
    }
    ChangeYearColorMB();
  }

  function ChangeYearColorMB() {
    const a = document.getElementsByName(`${HistoryYearMB}`);
    for (let i = 0; i < a.length; i++) {
      const element = a[i];
      element.classList.add('Sticky'); 
    }
  }

  $(window).on('mousewheel',function(e){
    var wheel = e.originalEvent.wheelDelta;
    if(wheel>0){
      if(window.scrollY > 480){
        $(".AboutUs_PC-sub-menu").stop().slideDown(500);
      }
      else{
        $(".AboutUs_PC-sub-menu").stop().slideUp(0);
      }

      if(window.scrollY > 380){
        $(".AboutUs_MB-sub-menu").stop().slideDown(500);
      }
      else{
        $(".AboutUs_MB-sub-menu").stop().slideUp(0);
      }
    } 
    else {
      $(".AboutUs_PC-sub-menu").stop().slideUp(500);
      $(".AboutUs_MB-sub-menu").stop().slideUp(500);
    }     
  });

  return (
    <div className="AboutUs">
      <div className="AboutUs_PC flex flex-col w-full items-center">
       <div className="AboutUs_PC-sub-menu flex w-full justify-center">
          <ul className="flex">
            <li className="flex h-full flex-1"></li>
            <li className="flex h-full justify-center items-center">
              <a href="#ci">CI</a>
            </li>
            <li className="flex h-full justify-center items-center">
              <a href="#history">회사연혁</a>
            </li>
            <li className="flex h-full justify-center items-center">
              <a href="#group">조직도</a>
            </li>
            <li className="flex h-full justify-center items-center">
              <a href="#customer">주요거래처</a>
            </li>
          </ul>
        </div>
        <div className="AboutUs_PC-Banner flex flex-col w-full justify-center items-center h-40">
          <div className="AboutUs_PC-Banner-Text flex flex-col flex-1 justify-center w-full">
            <div className="flex h-10"></div>
            <p className="flex Title">COMPANY</p>
            <p className="flex Title">INTRODUCTION</p>
            <p className="flex text-2xl pl-2">회사소개</p>
          </div>
          <div className="AboutUs_PC-banner-menu flex h-16 w-full items-center justify-end">
            <ul className="flex"><a name="ci"></a>
              <li className="flex flex-1 h-full justify-center">
                <a className="flex flex-1 justify-center" href="#ci">CI</a>
              </li>
              <li className="flex flex-1 h-full justify-center">
                <a className="flex flex-1 justify-center" href="#history">회사연혁</a>
              </li>
              <li className="flex flex-1 h-full justify-center">
                <a className="flex flex-1 justify-center" href="#group">조직도</a>
              </li>
              <li className="flex flex-1 h-full justify-center">
                <a className="flex flex-1 justify-center" href="#customer">주요거래처</a>
              </li>
            </ul>
          </div>
          <div className="BannerBackColor flex w-full h-full"></div>
          {BannerLoading ? 
          <div className="BannerBackIMG flex w-full h-full">
            {BannerDb.map(banner => (
              <img className="flex w-full h-full" src={banner.IMG}></img>
            ))}
          </div> : null}
        </div>

        <div className="AboutUs_PC-CI con flex flex-col w-full items-center justify-center">
          <div className="flex h-24"></div>
          <div className="Text flex w-full">
            <div className="Text-Left flex flex-1">
              Clean & Creative <br></br>
              Innovation Enterprise <br></br>
              PartnerShip <br></br>
            </div>
            <div className="Text-Right flex flex-1 justify-end">
            우리 삶에 이로운 철, 다양한 시도와 <br></br>
            도전으로 그 이상의 가치를 향해 앞장서는 <br></br>
            영우특수강이 되겠습니다. <br></br>
            </div>
          </div>
          {CILoading ? 
          <div className="IntroBox flex w-full gap-4 items-center">
            {CIDb.map(ci=>(
              <div className="IntroBoxList flex flex-col items-center" key={ci.index}>
                <img className="flex flex-1" src={ci.IMG} alt="ci"></img>
                <div className="IntroBoxListText">
                  <p>{ci.Name}</p>
                  <p>{ci.NameSub}</p>
                </div>
              </div>
            ))}
          </div> : null}
        </div>
        <div className="AboutUs_PC-History flex w-full flex-col items-center">
          <div className="flex h-36"><a name="history"></a></div>
          <div className="Title flex con w-full">회사연혁</div>
          <div className="flex h-16"></div>
          <div className="Frame flex w-full con">
            <div className="Years flex flex-col items-end" >
              <div ref={HistoryYearTopPC}></div>
            </div>
            {/* <div className="flex w-4"></div> */}
            {HistoryLoading ? 
            <div className="Month flex w-3/5 flex-col" ref={HistoryMonthTopPC}>
              <div className="flex h-5"></div>
              {HistoryDb.map(history=>(
                <div className="Months flex h-full w-full" key={history.index} id={history.Year} name={history.Decade} >
                  <div className="flex justify-center items-center">{history.Year}.{String(history.Month).padStart(2, '0')}</div>
                  <div className="flex w-5"></div>
                  <div className="flex flex-1 items-center">{history.Detail}</div>
                </div>
              ))}
            </div>
            : null}
          </div>
        </div>
        <div className="AboutUs_PC-Ogz con flex flex-col w-full">
          <div className="flex h-36"><a name="group"></a></div>
          <div className="Title flex w-full">조직도</div>
          <div className="flex h-10"></div>
          {OrgLoading ? 
          <div className="Pictures1 flex flex-col items-center">
            {OrgDb.filter(org=>org.Display==="PC").map(org => (
            <img src={org.IMG} alt="조직도"></img>
            ))}
          </div>
          : null}
        </div>
        <div className="AboutUs_PC-Customer con flex flex-col w-full">
          <div className="flex h-36"><a name="customer"></a></div>
          <div className="Title flex w-full">주요거래처</div>
          <div className="flex h-10"></div>
          <div className="flex w-full justify-center">
          {CustomerLoading ?
            <div className="grid grid-cols-4 gap-4">
            {CustomerDb.map(customer=>(
              <div className="AboutUs_PC-Customer-lists flex flex-col items-center mb-2" key={customer.index}>
                <div className="flex w-full justify-center items-center">
                  <img src={customer.IMG} alt={customer.Name}></img>
                </div>
                <div className="flex w-full justify-center mt-2 mb-1">{customer.Name}</div>
              </div>
            ))}
            </div>
          : null}
          </div>
        </div>
        <div className="flex h-10"></div>
        <div className="flex h-20"></div>

      </div>

      <div className="AboutUs_MB flex flex-col w-full items-center">
        <div className="AboutUs_MB-sub-menu flex w-full justify-center">
          <ul className="flex w-full">
            <li className="flex flex-1 h-full justify-center items-center">
              <a href="#cimb">CI</a>
            </li>
            <li className="flex flex-1 h-full justify-center items-center">
              <a href="#historymb">회사연혁</a>
            </li>
            <li className="flex flex-1 h-full justify-center items-center">
              <a href="#groupmb">조직도</a>
            </li>
            <li className="flex flex-1 h-full justify-center items-center">
              <a href="#customermb">주요거래처</a>
            </li>
          </ul>
        </div>
        <div className="AboutUs_MB-Banner flex flex-col w-full justify-center items-center h-40">
          <div className="AboutUs_MB-Banner-Text flex flex-col flex-1 justify-center w-full">
            <div className="flex h-10"></div>
            <p className="flex Title">COMPANY</p>
            <p className="flex Title">INTRODUCTION</p>
            <p className="flex text-2xl pl-2">회사소개</p>
          </div>
          <div className="AboutUs_MB-banner-menu flex h-16 w-full items-center justify-end">
            <ul className="flex w-full"><a name="cimb"></a>
              <li className="flex flex-1 h-full justify-center">
                <a className="flex flex-1 justify-center" href="#cimb">CI</a>
              </li>
              <li className="flex flex-1 h-full justify-center">
                <a className="flex flex-1 justify-center" href="#historymb">회사연혁</a>
              </li>
              <li className="flex flex-1 h-full justify-center">
                <a className="flex flex-1 justify-center" href="#groupmb">조직도</a>
              </li>
              <li className="flex flex-1 h-full justify-center">
                <a className="flex flex-1 justify-center" href="#customermb">주요거래처</a>
              </li>
            </ul>
          </div>
          <div className="BannerBackColor flex w-full h-full"></div>
          {BannerLoading ? 
          <div className="BannerBackIMG flex w-full h-full">
            {BannerDb.map(banner => (
              <img className="flex w-full h-full" src={banner.IMG}></img>
            ))}
          </div> : null}
        </div>
        <div className="AboutUs_MB-CI con flex flex-col w-full items-center justify-center">
          <div className="flex h-20"></div>
          <div className="Text flex w-full flex-col">
            <div className="Text-Left flex flex-1">
              Clean & Creative <br></br>
              Innovation Enterprise <br></br>
              PartnerShip <br></br>
            </div>
            <div className="Text-Right flex flex-1">
            우리 삶에 이로운 철, 다양한 시도와 <br></br>
            도전으로 그 이상의 가치를 향해 앞장서는 <br></br>
            영우특수강이 되겠습니다. <br></br>
            </div>
          </div>
          {CILoading ? 
          <div className="IntroBox flex w-full gap-4 flex-col items-center">
            {CIDb.map(ci=>(
              <div className="IntroBoxList flex flex-col items-center" key={ci.index}>
                <img className="flex flex-1" src={ci.IMG} alt="ci"></img>
                <div className="IntroBoxListText flex flex-1 flex-col">
                  <p>{ci.Name}</p>
                  <p className="flex">{ci.NameSub}</p>
                </div>
              </div>
            ))}
          </div> : null}
        </div>
        <div className="AboutUs_MB-History flex w-full flex-col items-center">
          <div className="flex h-32"><a name="historymb"></a></div>
          <div className="Title flex w-full">회사연혁</div>
          <div className="flex h-8"></div>
          <div className="Frame flex w-full">
            <div className="Years flex flex-col items-end" >
              <div className="flex h-4"></div>
              <div ref={HistoryYearTopMB}></div>
            </div>
            <div className="flex w-3"></div>
            {HistoryLoading ? 
            <div className="Month flex flex-1 flex-col" ref={HistoryMonthTopMB}>
              <div className="flex h-5"></div>
              {HistoryDb.map(history=>(
                <div className="Months flex flex-col h-full" key={history.index} id={history.Year} name={history.Decade} >
                  <div className="flex pl-3">{history.Year}.{String(history.Month).padStart(2, '0')}</div>
                  <div className="flex h-0.5 pl-3"></div>
                  <div className="flex flex-1 items-center pl-3">{history.Detail}</div>
                </div>
              ))}
            </div>
            : null}
          </div>
        </div>
        <div className="AboutUs_MB-Ogz flex flex-col w-full">
          <div className="flex h-36"><a name="groupmb"></a></div>
          <div className="Title flex w-full">조직도</div>
          <div className="flex h-8"></div>
          {OrgLoading ?
          <div className="Pictures1 flex flex-col w-full">
            {OrgDb.filter(org=>org.Display==="MOBILE").map(org => (
            <img src={org.IMG} alt="조직도"></img>
            ))}
          </div>
          : null }
        </div>
        <div className="AboutUs_MB-Customer con flex flex-col w-full">
          <div className="flex h-36"><a name="customermb"></a></div>
          <div className="Title flex w-full">주요거래처</div>
          <div className="flex h-10"></div>
          {CustomerLoading ?
          <div className="flex flex-col w-full justify-center gap-4">
            {CustomerDb.map(customer=>(
              <div className="AboutUs_MB-Customer-lists flex flex-col items-center" key={customer.index}>
                <div className="flex w-full justify-center items-center">
                  <img src={customer.IMG} alt={customer.Name}></img>
                </div>
                <div className="flex w-full justify-center mt-2 mb-1">{customer.Name}</div>
              </div>
            ))}
          </div>
          : null}
        </div>
        <div className="flex h-10"></div>
        <div className="flex h-20"></div>
      </div>
    </div>
  );
}

export default AboutUs;
