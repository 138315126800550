import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { collection, addDoc, updateDoc, getDocs, query, orderBy, getDoc, where, doc, deleteDoc } from "firebase/firestore"; // 데이터 읽어오기
import './Footer.css';
import $ from 'jquery';

function Footer(props) {

  const [FMSSlide, SetFMSSlide] = useState(false);

  const [Contact, SetContact]=useState(null);
  const [ContactLoading, SetContactLoading]=useState(false);

  function linkClicked(){
    $(window).scrollTop(0);        
    $(".Menu").stop().slideDown(0);
  }

  useEffect(() => {
    // $('.FMSite ul').slideUp(0);
    LoadingContactData();
  }, [])

  function FMSSlideTrigger() {
    if(FMSSlide){
      $('.FMSite ul').stop().slideUp(500); 
      SetFMSSlide(false);
    }
    else{
      $('.FMSite ul').stop().slideDown(500); 
      SetFMSSlide(true);
    }
  }

  async function LoadingContactData(){
    SetContactLoading(false);
    const UserListRef = collection(props.db, "Contact"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(UserListRef);
    let ContactData=[];
    ContactData.length=0;
    await querySnapshot.forEach((doc) => {
      ContactData.push(doc.data());
    });
    SetContact(ContactData);
    SetContactLoading(true);
  }

  return (
    <div className="Footer">
      <div className="Footer_PC flex items-center flex-col w-full">
        {ContactLoading ? 
        <div className="flex gap-12 w-full">
          <div className="FPT-Logo flex">
            <Link to="" className="flex" onClick={linkClicked}><img src="./img/로고white.png" alt="영우특수강" /></Link>
          </div>

          {Contact.map(contact=>(
          <div className="Footer_PC-Menu flex flex-col gap-2 pl-5 flex-1">
            <div className="flex gap-5">
              <div className="flex">㈜영우특수강</div>
              <div className="flex">대표자 : {contact.CEO} </div>
              <div className="flex">사업자번호 : {contact.BusinessNum}</div>
            </div>
            <div className="flex gap-5">
              <div className="flex">주소 : {contact.Location}</div>
            </div>
            {/*<div className="flex gap-5">
              <div className="flex">TEL : {contact.Phone} </div>
              <div className="flex">FAX : {contact.Fax} </div>
            </div>*/}
            <div className="flex gap-5">
              <div className="flex">E-MAIL : {contact.Email}</div>
            </div>
            <div className="FPT-CopyRight flex mt-3">COPYRIGHT(C) YOUNG-WOO CO.,LTD</div>
          </div> ))}


          <div className="flex flex-col items-end">
            <div className="FPT-Upper flex justify-center items-center" onClick={linkClicked}>Top</div>
            <div className="flex flex-1"></div>
            <div className="flex PinWhite"><Link to="/Posting">관리자 페이지</Link></div>
          </div>
        </div> : null}
      </div>
      {ContactLoading ? 
      <div className="Footer_MB flex flex-col w-full items-center">
        <div className="Footer_MB-Top flex">
          <div className="FPT-Logo flex flex-1">
            <Link to="" className="flex items-center" onClick={linkClicked}>
              <img className="flex" src="./img/로고white.png" alt="영우특수강" /></Link>
          </div>
          <div className="flex flex-1 justify-end">
            <div className="FPT-Upper flex" onClick={linkClicked}>Top</div>
          </div>
        </div>
        {Contact.map(contact=>(
        <div className="Footer_MB-Menu flex gap-y-2 flex-col">
          <div className="flex">㈜영우특수강</div>
          <div className="flex">대표자 : {contact.CEO} </div>
          <div className="flex">사업자번호 : {contact.BusinessNum}</div>
          <div className="flex">주소 : {contact.Location}</div>
          {/* <div className="flex">TEL : {contact.Phone} </div>
          <div className="flex">FAX : {contact.Fax} </div> */}
          <div className="flex">E-MAIL : {contact.Email}</div>
        </div> ))}
        <div className="FPT-CopyRight flex">
          COPYRIGHT(C) YOUNG-WOO CO.,LTD
        </div>
      </div> : null}
    </div>
  );
}

export default Footer;
