import { Routes, Route, useLocation } from "react-router-dom";
import { React, useEffect, useState } from "react";
import './App.css';

import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, sendEmailVerification } from 'firebase/auth';
import { getFirestore, collection, getDocs, query, where, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject, uploadBytes, getDownloadURL } from "firebase/storage";

import 'firebase/compat/auth';

import Menu from './Module/Menu.js';
import MenuFixed from './Module/Menu_Fixed.js';
import Footer from './Module/Footer.js';
import Home from "./pages/Home.js"
import AboutUs from "./pages/AboutUs.js"
import Patent from "./pages/Patent.js"
import Location from "./pages/Location.js"
import Product from "./pages/Product.js"
import News from "./pages/News.js"
import Login from "./pages/Login.js"
import Join from "./pages/Join.js"
import Posting from "./pages/Posting.js"

function App() {
  const [UserData, SetUserData] = useState(null); // 회원정보
  const [MenuFix, SetMenuFix] = useState(true);
  const location=useLocation();
  const [Uploading, SetUploading] = useState(false);  // 업로딩 관련

  // FireBase, TODO: Replace the following with your app's Firebase project configuration
  // See: https://support.google.com/firebase/answer/7015592
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  // 파이어베이스 세팅 끝

  // 새로고침 및 페이지 변경 시 사용자계정 관리 시작
  useEffect(()=>{
    if(location.pathname==="/"){
      SetMenuFix(true);
    }
    else{
      SetMenuFix(false);
    }

    onAuthStateChanged(auth, (user) => {
      if (user) {  LoadingLogin(user.uid); } // 사용자가 로그인한 경우
      else { SetUserData(null); } // 사용자가 로그인 안한 경우
    });
  },[])  // 웹앱 새로고침(리로드) 변경 감지

  useEffect(()=>{
    if(location.pathname==="/"){
      SetMenuFix(true);
    }
    else{
      SetMenuFix(false);
    }

    onAuthStateChanged(auth, (user) => {
      if (user) {  LoadingLogin(user.uid); } // 사용자가 로그인한 경우
      else { SetUserData(null); } // 사용자가 로그인 안한 경우
    });
  },[location]) // 웹앱 현재 페이지 변경 감지
  // 새로고침 및 페이지 변경 시 사용자계정 관리 끝

  // 사용자 계정 인증 함수, 계정이 인증되지 않을 경우 호출, 하위페이지에서 호출됨.
  async function EmailInsurance(e){
    e.preventDefault(); 
    alert("인증이 되지 않은 계정입니다. 본 기능이 제한됩니다.");   
    if(window.confirm("인증하시겠습니까?")){
      const auth = getAuth();
      await sendEmailVerification(auth.currentUser)
      .then(async () => {
        alert("인증 이메일 발송 완료, 메시지 수신 후 새로고침 할 것.");
        alert("메일함에 없다면, 스팸메일함에서 확인해주시길 바랍니다.");
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }
  
  // 사용자 추가 정보를 파이어베이스에서 가져오는 함수
  async function LoadingLogin(uid){
    try {
      const UserListRef = collection(db, "UserInfo");
      const querySnapshot = await getDocs(query(UserListRef, where("UserCode", "==", uid))); 
      let LogDt=[];
      querySnapshot.forEach((doc)=>{
        LogDt.push(doc.data());
      });
      SetUserData(LogDt);
    } catch (error) {
      console.error('사용자 정보를 가져오는 도중 오류:', error);
    }
  }

  // 공통사용 함수
  // 이미지를 포함한 DB 삭제함수
  async function DeleteDBwithIMG(e, Code, Collection, IMGurl) {
    if(window.confirm("삭제하시겠습니까?")){
      alert("삭제진행시작");
      try { await DeleteDB1st(Code, Collection, IMGurl); } 
      catch (error) { alert("문서삭제 실패"); }
      alert("삭제완료");
      window.location.reload();
    }
  }
  // 이미지를 포함한 DB 삭제함수 - DB 삭제
  async function DeleteDB1st(Code, Collection, IMGurl){
    const UserListRef = collection(db, Collection);
    const querySnapshot = await getDocs(query(UserListRef, where("Code", "==", Code)));
    // 가져온 문서를 순회하면서 삭제합니다.
    await querySnapshot.forEach(async (item) => {
      // 각 문서를 삭제합니다.
      const docRef = await doc(db, Collection, item.id);
      await deleteDoc(docRef);
    });
    // 이미지들도 삭제합니다.
    await DeleteIMG(Collection, IMGurl);
  }
  // 이미지를 포함한 DB 삭제함수 - 이미지 삭제
  async function DeleteIMG(Collection, IMGurl){

    let extractedValue = '';
    const startIndex = IMGurl.indexOf('%2F') + 3; // %2F 다음 문자의 인덱스 (3은 %2F의 길이)
    const endIndex = IMGurl.indexOf('?alt'); // ?alt 이전 문자의 인덱스

    if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
        extractedValue = IMGurl.substring(startIndex, endIndex);
        console.log('추출된 값:', extractedValue);
    } else {
        console.log('값을 추출할 수 없습니다.');
    }

    const storage = getStorage();
    const filePath = Collection + '/'+ decodeURIComponent(extractedValue); // 삭제할 이미지의 경로
    const storageRef = ref(storage, filePath);

    // 이미지 삭제
    await deleteObject(storageRef)
    .then(() => { 
      
      })
    .catch((error) => {
      console.error('이미지 삭제 중 오류 발생:', error);
    });
  };

  async function DeleteDBwithIMG3(e, Code, Collection, IMGurl1, IMGurl2, IMGurl3) {
    if(window.confirm("삭제하시겠습니까?")){
      alert("삭제진행시작");
      try { await DeleteDB1st3(Code, Collection, IMGurl1, IMGurl2, IMGurl3); } 
      catch (error) { alert("문서삭제 실패"); }
      alert("삭제완료");
      window.location.reload();
    }
  }
  // 이미지를 포함한 DB 삭제함수 - DB 삭제
  async function DeleteDB1st3(Code, Collection, IMGurl1, IMGurl2, IMGurl3){
    const UserListRef = collection(db, Collection);
    const querySnapshot = await getDocs(query(UserListRef, where("Code", "==", Code)));
    // 가져온 문서를 순회하면서 삭제합니다.
    await querySnapshot.forEach(async (item) => {
      // 각 문서를 삭제합니다.
      const docRef = await doc(db, Collection, item.id);
      await deleteDoc(docRef);
    });
    // 이미지들도 삭제합니다.
    await DeleteIMG(Collection, IMGurl1);
    await DeleteIMG(Collection, IMGurl2);
    await DeleteIMG(Collection, IMGurl3);
  }

  // 이미지 없는 DB 삭제함수
  async function DeleteDBnoneIMG(e, Code, Collection) {
    if(window.confirm("삭제하시겠습니까?")){
      alert("삭제진행시작");
      try { 
        await DeletingNode(Code, Collection);
      } 
      catch (error) { alert("문서삭제 실패"); }
    }
  }

  async function DeletingNode(Code, Collection){
    const UserListRef = await collection(db, Collection);
    const querySnapshot = await getDocs(query(UserListRef, where("Code", "==", Code)));
    // 가져온 문서를 순회하면서 삭제합니다.
    await querySnapshot.forEach(async (item) => {
      // 각 문서를 삭제합니다.
      const docRef = await doc(db, Collection, item.id);
      await deleteDoc(docRef)
      .then(() => {
        alert("삭제완료");
        window.location.reload();
      });
    });
    
  }

  // 이미지 업로드
  async function handleFileSelect(docRef, collection, file1, Calls) {
    const storage = getStorage(); // Firebase Storage 설정
    if (Calls && (file1!=="" || file1!== null || file1!==undefined)) {
      let IMGPath = collection + '/' + docRef.id;
      const storageRef = ref(storage, IMGPath); // 이미지를 저장할 경로 지정
      await uploadBytes(storageRef, file1)
      .then(async (snapshot) => {
        await getDownloadURL(storageRef)
        .then(async (url) => { await updateDoc(docRef, { IMG : url }); })
        .catch((error) => {
          console.error('URL 가져오기 오류:', error);
        });
      })
      .catch((error) => {
        console.error("이미지 업로드 오류: ", error);
      });
    }
  }

  // 순서변경함수
  async function handleChange(e, Collection){
    if(window.confirm("순서변경을 하시겠습니까?")){   
      try {
        SetUploading(true);
        await OrderByChange(e.target.options[e.target.selectedIndex].id, parseInt(e.target.value), Collection);
        alert("업로드 완료");
        window.location.reload(); 
      } catch (error) { console.log(error); } 
    }
    else{ alert("업로드 취소"); }
  };
  // 순서변경함수 - 서버 업데이트
  async function OrderByChange(documentId, orderNum, Collection){
    const docRef = doc(db, Collection, documentId); 
    const updateData = { No : orderNum }
    await updateDoc(docRef, updateData)
    .then(() => {
      console.log('Document successfully updated!');
    })
    .catch((error) => {
      console.error('Error updating document: ', error);
    });
  }

  // 우클릭 금지
  const handleContextMenu = (e) => {
    e.preventDefault(); // 우클릭 이벤트를 막음
  }

  return (
    <div className="App" onContextMenu={handleContextMenu}>
      {Uploading ? 
      <div className='UploadingBlock flex flex-1 w-full h-full justify-center items-center'>
        <i className="fa-solid fa-circle-notch fa-spin"></i> 
      </div> : null}
      {MenuFix ? 
        <div className="Top-Menu-Fix">
          <MenuFixed auth={auth} UserData={UserData}/>
        </div>
        : 
        <div className="Top-Menu-NonFix">
          <Menu auth={auth} UserData={UserData}/>
        </div>
      }
      <Routes>
          <Route exact path="" element={<Home db={db} />} />
          <Route exact path="/AboutUs" element={<AboutUs db={db} />} />
          <Route exact path="/Patent" element={<Patent db={db} auth={auth} UserData={UserData} handleFileSelect={handleFileSelect} DeleteDBwithIMG={DeleteDBwithIMG} handleChange={handleChange} />} />
          <Route exact path="/Location" element={<Location db={db} />} />
          <Route exact path="/CHQWire" element={<Product db={db} product={"CHQ WIRE"}/>} />
          <Route exact path="/CDBar" element={<Product db={db} product={"CD BAR"}/>} />
          <Route exact path="/Cutting" element={<Product db={db} product={"CUTTING"}/>} />
          <Route exact path="/News" element={<News db={db} auth={auth} UserData={UserData} DeleteDBwithIMG3={DeleteDBwithIMG3}/>} /> 
          <Route exact path="/Posting" element={<Posting UserData={UserData} db={db} auth={auth} handleFileSelect={handleFileSelect} DeleteDBnoneIMG={DeleteDBnoneIMG} DeleteDBwithIMG={DeleteDBwithIMG} handleChange={handleChange} />}/>
          <Route exact path="/Login" element={<Login UserData={UserData} SetUserData={SetUserData} db={db} auth={auth} />}/>
          <Route exact path="/Join" element={<Join db={db} auth={auth}/>}/>   
      </Routes>
      <Footer db={db}/>
    </div>
  );
}

export default App;
