import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useRef } from "react";
import $ from 'jquery';

import './Posting.css';

import { collection, addDoc, updateDoc, getDocs, query, orderBy, getDoc, where, doc, deleteDoc } from "firebase/firestore"; // 데이터 읽어오기
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { signInWithEmailAndPassword, getAuth, updatePassword, signOut, deleteUser } from "firebase/auth";

function Posting(props) {

  const Email = useRef(null);	
  const EmailSub = useRef(null);	
  const Phone = useRef(null);	
  const PhoneSub = useRef(null);	
  const FAX = useRef(null);	
  const FAXSub = useRef(null);	

  const [MyPageLoaded, SetMyPageLoaded]=useState(false);
  const [Uploading, SetUploading] = useState(false);  // 업로딩 관련

  const LocationName = useRef(null);	
  const LocationEmail = useRef(null);	
  const LocationTel = useRef(null);	
  const LocationFax = useRef(null);	
  const LocationAddr = useRef(null);		
  const LocationIMGRef = useRef(null);
  const [LocaitonIMG, SetLocationIMGupload] = useState(false);
	
  const OrgDisplay = useRef(null);
  const OrgIMGRef = useRef(null);
  const [OrgIMG, SetOrgIMGupload] = useState(false);

  const CIBannerName = useRef(null);	
  const CIBannerNameSub = useRef(null);			
  const CIBannerIMGRef = useRef(null);
  const [CIBannerIMG, SetCIBannerIMGupload] = useState(false);

  const TopBannerPage = useRef(null);	
  const TopBannerType = useRef(null);			
  const TopBannerIMGRef = useRef(null);
  const [TopBannerIMG, SetTopBannerIMGupload] = useState(false);

  const CustomerName = useRef(null);	
  const CustomerIMGRef = useRef(null);
  const [CustomerIMG, SetCustomerIMGupload] = useState(false);

  const HistoryYear = useRef(null);	
  const HistoryMonth = useRef(null);	
  const HistoryDetail = useRef(null);	

  const ProductType = useRef(null);
  const [ ProductUsage, SetProductUsage ] = useState('');	
  const handleProductUsage = (e) => { SetProductUsage(e.target.value);  };
  const [ ProductHard, SetProductHard ] = useState('');	
  const handleProductHard = (e) => { SetProductHard(e.target.value); };
  const [ ProductProcess, SetProductProcess ] = useState('');	
  const handleProductProcess = (e) => { SetProductProcess(e.target.value) };
  const [ ProductForm, SetProductForm ] = useState('');	
  const handleProductForm = (e) => { SetProductForm(e.target.value) };
  const [ ProductThickMin, SetProductThickMin ] = useState(0);
  const handleProductThickMin = (e) => { SetProductThickMin(e.target.value); }	;
  const [ ProductThickMax, SetProductThickMax ] = useState(0);	
  const handleProductThickMax = (e) => { SetProductThickMax(e.target.value); };
  const [ ProductLengthMin, SetProductLengthMin ] = useState(0);
  const handleProductLengthMin = (e) => { SetProductLengthMin(e.target.value); };
  const [ ProductLengthMax, SetProductLengthMax ] = useState(0);
  const handleProductLengthMax = (e) => { SetProductLengthMax(e.target.value); };	

  const ProductBannerName = useRef(null);	
  const ProductBannerType = useRef(null);
  const ProductBannerVideo = useRef(null);	

  const FacilityName = useRef(null);	
  const FacilityType = useRef(null);
  const FacilityVideo = useRef(null);	

  const EquipName = useRef(null);	
  const EquipType = useRef(null);
  const EquipIMGRef = useRef(null);
  const [EquipIMG, SetEquipIMGupload] = useState(false);

  const ProcessBannerType = useRef(null);
  const ProcessBannerDisplay = useRef(null);
  const ProcessBannerIMGRef = useRef(null);
  const [ProcessBannerIMG, SetProcessBannerIMGupload] = useState(false);

  const navigate = useNavigate();  // Link없이 페이지 이동
  const PassWord = useRef(null);
  const PassWordChange = useRef(null);
  const PassWordQuit = useRef(null);
  const [PWChangeChecked, SetPWChangeChecked] = useState(false);
  const [PWQuitAccChecked, SetPWQuitAccChecked] = useState(true);

  const MainVideo = useRef(null);
  const MainVideoMB = useRef(null);

  // 데이터 불러오기
  const [Contact, SetContact]=useState(null);
  const [ContactLoading, SetContactLoading]=useState(false);

  const [MainBanner, SetMainBanner]=useState(null);
  const [MainBannerLoading, SetMainBannerLoading]=useState(false);

  const [MainBannerMB, SetMainBannerMB]=useState(null);
  const [MainBannerMBLoading, SetMainBannerMBLoading]=useState(false);

  const [Manager, SetManager]=useState(null);
  const [ManagerLoading, SetManagerLoading]=useState(false);
  
  const [Loc, SetLoc]=useState(null);
  const [LocLoading, SetLocLoading]=useState(false);

  const [Org, SetOrg]=useState(null);
  const [OrgLoading, SetOrgLoading]=useState(false);

  const [History, SetHistory]=useState(null);
  const [HistoryLoading, SetHistoryLoading]=useState(false);

  const [Customer, SetCustomer]=useState(null);
  const [CustomerLoading, SetCustomerLoading]=useState(false);

  const [Product, SetProduct]=useState(null);
  const [ProductLoading, SetProductLoading]=useState(false);

  const [ProductBanner, SetProductBanner]=useState(null);
  const [ProductBannerLoading, SetProductBannerLoading]=useState(false);

  const [ProcessBanner, SetProcessBanner]=useState(null);
  const [ProcessBannerLoading, SetProcessBannerLoading]=useState(false);

  const [Facility, SetFacility]=useState(null);
  const [FacilityLoading, SetFacilityLoading]=useState(false);

  const [Equip, SetEquip]=useState(null);
  const [EquipLoading, SetEquipLoading]=useState(false);

  const [ProductInfo, SetProductInfo]=useState(null);
  const [ProductInfoLoading, SetProductInfoLoading]=useState(false);

  const [ProductInfoMB, SetProductInfoMB]=useState(null);
  const [ProductInfoMBLoading, SetProductInfoMBLoading]=useState(false);

  const [CIBanner, SetCIBanner]=useState(null);
  const [CIBannerLoading, SetCIBannerLoading]=useState(false);

  const [TopBanner, SetTopBanner]=useState(null);
  const [TopBannerLoading, SetTopBannerLoading]=useState(false);

  useEffect(() => {
    if(props.UserData===null || props.UserData===undefined || props.UserData.length===0){
      SetMyPageLoaded(false);
    }
    else{ SetMyPageLoaded(true); }
    LoadingContactData();
    LoadingManagerData();
    LoadingLocData();
    LoadingMainVideoData();
    LoadingMainVideoMBData();
    LoadingHistoryData();
    LoadingCustomerData();
    LoadingProductData();
    LoadingProcessBannerData();
    LoadingProductBannerData();
    LoadingCIBannerData();
    LoadingOrgData();
    LoadingFacilityData();
    LoadingEquipData();
    LoadingProductInfoData();
    LoadingProductInfoMBData();
    LoadingTopBannerData();
  }, []);

  useEffect(()=>{
    if(props.UserData===null || props.UserData===undefined || props.UserData.length===0){
      SetMyPageLoaded(false);
    }
    else{ SetMyPageLoaded(true); }
  },[props.UserData])

  // 계정 관련 함수
  function OpenPWChage() {
    $('.Posting .ChagePW').css('display','flex');
  }

  function ClosePWChage(){
    $('.Posting .ChagePW').css('display','none');
  }

  function OpenQuitChage() {
    $('.Posting .QuitAcc').css('display','flex');
  }

  function CloseQuitChage(){
    $('.Posting .QuitAcc').css('display','none');
  }

  async function PWCheck(e){
    e.preventDefault();
    SetPWChangeChecked(false);
    await signInWithEmailAndPassword(props.auth, props.UserData[0].email, PassWord.current.value)
    .then((userCredential) => {
      SetPWChangeChecked(true);
    })
    .catch((error) => {
      SetPWChangeChecked(false);   // 인증실패 메시지
    });
  }

  async function QuitAccCheck(e){
    e.preventDefault();
    await signInWithEmailAndPassword(props.auth, props.UserData[0].email, PassWordQuit.current.value)
    .then((userCredential) => {
      const findauth = getAuth();
      const user = findauth.currentUser;
      user.delete().then(async () => {
        SetPWQuitAccChecked(true); 
        // 데이터베이스 내역도 삭제
        const UserListRef = collection(props.db, "UserInfo");
        const querySnapshot = await getDocs(query(UserListRef, where("UserCode", "==", props.UserData[0].UserCode)));
        // 가져온 문서를 순회하면서 삭제합니다.
        querySnapshot.forEach(async (item) => {
          // 각 문서를 삭제합니다.
          const docRef = doc(props.db, "UserInfo", item.id);
          await deleteDoc(docRef);
          alert("계정삭제 완료");
          await signOut(props.auth);
          navigate('/');
          window.location.reload();
        });
      })
      .catch((error)=>{
        alert("계정삭제 실패");
      });
    })
    .catch((error) => {
      SetPWQuitAccChecked(false);   // 인증실패 메시지
    });
  }

  async function QuitAccCheckSocial(e){
    e.preventDefault();
    const findauth = getAuth();
    const user = findauth.currentUser;
    user.delete().then(async () => {
      SetPWQuitAccChecked(true); 
      // 데이터베이스 내역도 삭제
      const UserListRef = collection(props.db, "UserInfo");
      const querySnapshot = await getDocs(query(UserListRef, where("UserCode", "==", props.UserData[0].UserCode)));
      // 가져온 문서를 순회하면서 삭제합니다.
      querySnapshot.forEach(async (item) => {
        // 각 문서를 삭제합니다.
        const docRef = doc(props.db, "UserInfo", item.id);
        await deleteDoc(docRef);
        alert("계정삭제 완료");
        await signOut(props.auth);
        navigate('/');
        window.location.reload();
      });
    })
    .catch((error)=>{
      alert("계정삭제 실패");
    });
  }

  async function PWChange(e){
    e.preventDefault();
    if(PassWordChange.current.value!==PassWord.current.value){
      const findauth = getAuth();
      const user = findauth.currentUser;
      updatePassword(user, PassWordChange.current.value)
      .then(()=>{
        alert("비밀번호 변경 완료.");
      })
    }
    else{
      alert("비밀번호가 이전과 같습니다.");
    }
  }

  // 매니저 승격
  async function GradeCtrl(e, UserCode, Grade){
    const UserListRef = collection(props.db, "UserInfo");  // 데이터베이스 내역도 삭제
    const querySnapshot = await getDocs(query(UserListRef, where("UserCode", "==", UserCode)));
    querySnapshot.forEach(async (item) => {
      const docRef = doc(props.db, "UserInfo", item.id);
      const docSnap = await getDoc(docRef);  // 현재 문서의 데이터 가져오기
      if (docSnap.exists()) {
        const userData = docSnap.data();
        userData.Grade = Grade;
        await updateDoc(docRef, userData);
        alert("매니저 등급 조정 완료");
        window.location.reload();
      }
    });
  }

  // 펼치기 접기 
  function OpenPostDetail(e){
    if($(e.target).find('i').hasClass('fa-rotate-270')){
      $(e.target).next().css('display','none'); 
      $(e.target).find('i').removeClass('fa-rotate-270');  
    }
    else{
      ClosePostDetail();
      $(e.target).next().css('display','flex');  
      $(e.target).find('i').addClass('fa-rotate-270');  
    }
  }

  function ClosePostDetail(){
    $(".News .Sections .NewsListContext").css('display','none');  // 클릭된 거 형제 노드들 안보이게 함.
    $(".News .Sections .NewsListBar i").removeClass('fa-rotate-270');  
  }

  // 데이터 불러오기
  async function LoadingManagerData(){
    SetManagerLoading(false);
    const UserListRef = collection(props.db, "UserInfo"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef),orderBy("Grade","desc"));
    let ManagerData=[];
    ManagerData.length=0;
    await querySnapshot.forEach((doc) => {
      ManagerData.push(doc.data());
    });
    SetManager(ManagerData);
    SetManagerLoading(true);
  }

  async function LoadingContactData(){
    SetContactLoading(false);
    const UserListRef = collection(props.db, "Contact"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(UserListRef);
    let ContactData=[];
    ContactData.length=0;
    await querySnapshot.forEach((doc) => {
      ContactData.push(doc.data());
    });
    SetContact(ContactData);
    SetContactLoading(true);
  }

  async function LoadingMainVideoData(){
    SetMainBannerLoading(false);
    const UserListRef = collection(props.db, "MainBanner"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef),orderBy("No","asc"));
    let MainBanner=[];
    MainBanner.length=0;
    await querySnapshot.forEach((doc) => {
      MainBanner.push(doc.data());
    });
    SetMainBanner(MainBanner);
    SetMainBannerLoading(true);
  }

  async function LoadingMainVideoMBData(){
    SetMainBannerLoading(false);
    const UserListRef = collection(props.db, "MainBannerMB"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef),orderBy("No","asc"));
    let MainBannerMB=[];
    MainBannerMB.length=0;
    await querySnapshot.forEach((doc) => {
      MainBannerMB.push(doc.data());
    });
    SetMainBannerMB(MainBannerMB);
    SetMainBannerMBLoading(true);
  }

  async function LoadingLocData(){
    SetLocLoading(false);
    const UserListRef = collection(props.db, "Location"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("No","asc")));
    let LocData=[];
    LocData.length=0;
    await querySnapshot.forEach((doc) => {
      LocData.push(doc.data());
    });
    SetLoc(LocData);
    SetLocLoading(true);
  }

  async function LoadingOrgData(){
    SetOrgLoading(false);
    const UserListRef = collection(props.db, "Organize"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("No","asc")));
    let LocData=[];
    LocData.length=0;
    await querySnapshot.forEach((doc) => {
      LocData.push(doc.data());
    });
    SetOrg(LocData);
    SetOrgLoading(true);
  }

  async function LoadingCIBannerData(){
    SetLocLoading(false);
    const UserListRef = collection(props.db, "CI"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("No","asc")));
    let CIBannerData=[];
    CIBannerData.length=0;
    await querySnapshot.forEach((doc) => {
      CIBannerData.push(doc.data());
    });
    SetCIBanner(CIBannerData);
    SetCIBannerLoading(true);
  }

  async function LoadingTopBannerData(){
    SetLocLoading(false);
    const UserListRef = collection(props.db, "Banner"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("Page","asc")));
    let TopBannerData=[];
    TopBannerData.length=0;
    await querySnapshot.forEach((doc) => {
      TopBannerData.push(doc.data());
    });
    SetTopBanner(TopBannerData);
    SetTopBannerLoading(true);
  }

  async function LoadingHistoryData(){
    SetHistoryLoading(false);
    const UserListRef = collection(props.db, "History"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("Year","desc"), orderBy("Month","desc")));
    let HistoryData=[];
    HistoryData.length=0;
    await querySnapshot.forEach((doc) => {
      HistoryData.push(doc.data());
    });
    SetHistory(HistoryData);
    SetHistoryLoading(true);
  }

  async function LoadingCustomerData(){
    SetCustomerLoading(false);
    const UserListRef = collection(props.db, "Customer"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("No","asc")));
    let CustomerData=[];
    CustomerData.length=0;
    await querySnapshot.forEach((doc) => {
      CustomerData.push(doc.data());
    });
    SetCustomer(CustomerData);
    SetCustomerLoading(true);
  }

  async function LoadingProductData(){
    SetProductLoading(false);
    const UserListRef = collection(props.db, "Product"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("Type","asc"), orderBy("No","asc")));
    let ProductData=[];
    ProductData.length=0;
    await querySnapshot.forEach((doc) => {
      ProductData.push(doc.data());
    });
    SetProduct(ProductData);
    SetProductLoading(true);
  }

  async function LoadingProcessBannerData(){
    SetProcessBannerLoading(false);
    const UserListRef = collection(props.db, "ProcessBanner"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("Type","asc"), orderBy("No","asc")));
    let ProcessBanner=[];
    ProcessBanner.length=0;
    await querySnapshot.forEach((doc) => {
      ProcessBanner.push(doc.data());
    });
    SetProcessBanner(ProcessBanner);
    SetProcessBannerLoading(true);
  }

  async function LoadingProductBannerData(){
    SetProductBannerLoading(false);
    const UserListRef = collection(props.db, "ProductBanner"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("Type","asc"), orderBy("No","asc")));
    let ProductBannerData=[];
    ProductBannerData.length=0;
    await querySnapshot.forEach((doc) => {
      ProductBannerData.push(doc.data());
    });
    SetProductBanner(ProductBannerData);
    SetProductBannerLoading(true);
  }

  async function LoadingFacilityData(){
    SetFacilityLoading(false);
    const UserListRef = collection(props.db, "Facility"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("Type","asc"), orderBy("No","asc")));
    let FacilityData=[];
    FacilityData.length=0;
    await querySnapshot.forEach((doc) => {
      FacilityData.push(doc.data());
    });
    SetFacility(FacilityData);
    SetFacilityLoading(true);
  }

  async function LoadingEquipData(){
    SetEquipLoading(false);
    const UserListRef = collection(props.db, "Equip"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("Type","asc"), orderBy("No","asc")));
    let EquipData=[];
    EquipData.length=0;
    await querySnapshot.forEach((doc) => {
      EquipData.push(doc.data());
    });
    SetEquip(EquipData);
    SetEquipLoading(true);
  }

  async function LoadingProductInfoData(){
    SetProductInfoLoading(false);
    const UserListRef = collection(props.db, "ProductInfo"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("Type","asc"), orderBy("No","asc")));
    let productInfoData=[];
    productInfoData.length=0;
    await querySnapshot.forEach((doc) => {
      productInfoData.push(doc.data());
    });
    SetProductInfo(productInfoData);
    SetProductInfoLoading(true);
  }

  async function LoadingProductInfoMBData(){
    SetProductInfoMBLoading(false);
    const UserListRef = collection(props.db, "ProductInfoMB"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("Type","asc"), orderBy("No","asc")));
    let productInfoMBData=[];
    productInfoMBData.length=0;
    await querySnapshot.forEach((doc) => {
      productInfoMBData.push(doc.data());
    });
    SetProductInfoMB(productInfoMBData);
    SetProductInfoMBLoading(true);
  }
   
  // 포스팅 함수
  // 연락처 데이터 수정
  async function ContactUpload(e){
    e.preventDefault();
    const userData = {
      Phone : Phone.current.value,
      PhoneSub : PhoneSub.current.value,
      Email : Email.current.value,
      EmailSub : EmailSub.current.value,
      Fax : FAX.current.value,
      FaxSub : FAXSub.current.value
    }; 

    if(window.confirm("수정하시겠습니까?")){   
      try {
        SetUploading(true);
        await ContactDataUpload(userData);        
      } catch (error) { console.log(error); } 
    }
    else{ alert("수정 취소"); }
  }
  async function ContactDataUpload(userData){
    const UserListRef = await collection(props.db, "Contact"); // 컬렉션 선택
    const querySnapshot = await getDocs(UserListRef);
    await querySnapshot.forEach(async (doc) => {
      const docRef = doc.ref;
      await updateDoc(docRef, userData)
      .then(() => {
        alert("수정 완료");
        SetUploading(false);
      });       
    });
  }

  // 연혁 Data 업로드 
  async function MainBannerUpload(e){
    e.preventDefault();
    if(window.confirm("업로드 하시겠습니까?")){   
      try {
        SetUploading(true);
        await MainBannerDataUpload();
        alert("업로드 완료");
        window.location.reload(); 
      } catch (error) { console.log(error); } 
    }    
  }
  async function MainBannerDataUpload(){
    const MainBannerData = {
      Video : MainVideo.current.value,
      No : 999
    }; 
    try {
      const UserListRef = collection(props.db, "MainBanner"); // 컬렉션 선택 
      const docRef = await addDoc(UserListRef, MainBannerData);
      await updateDoc(docRef, { Code : docRef.id });
    } catch (error) {
      console.log(error);
    }
  }

  async function MainBannerMBUpload(e){
    e.preventDefault();
    if(window.confirm("업로드 하시겠습니까?")){   
      try {
        SetUploading(true);
        await MainBannerMBDataUpload();
        alert("업로드 완료");
        window.location.reload(); 
      } catch (error) { console.log(error); } 
    }    
  }
  async function MainBannerMBDataUpload(){
    const MainBannerMBData = {
      Video : MainVideoMB.current.value,
      No : 999
    }; 
    try {
      const UserListRef = collection(props.db, "MainBannerMB"); // 컬렉션 선택 
      const docRef = await addDoc(UserListRef, MainBannerMBData);
      await updateDoc(docRef, { Code : docRef.id });
    } catch (error) {
      console.log(error);
    }
  }

  // 연혁 Data 업로드 
  async function HistoryUpload(e){
    e.preventDefault();
    if(window.confirm("업로드 하시겠습니까?")){   
      try {
        SetUploading(true);
        await HistoryDataUpload();
        alert("업로드 완료");
        window.location.reload(); 
      } catch (error) { console.log(error); } 
    }    
  }
  async function HistoryDataUpload(){
    const number = parseInt(HistoryYear.current.value, 10); // 문자열을 숫자로 변환
    const Decade = Math.floor(number / 10) * 10 ;
    const HistoryData = {
      Year : HistoryYear.current.value,
      Month : HistoryMonth.current.value,
      Decade : Decade.toString(),
      Detail : HistoryDetail.current.value,
    }; 

    try {
      const UserListRef = collection(props.db, "History"); // 컬렉션 선택 
      const docRef = await addDoc(UserListRef, HistoryData);
      await updateDoc(docRef, { Code : docRef.id });
    } catch (error) {
      console.log(error);
    }
  }

  // Product Data 업로드 
  async function ProductUpload(e){
    e.preventDefault();
    if(window.confirm("업로드 하시겠습니까?")){   
      try {
        SetUploading(true);
        await ProductDataUpload();
        alert("업로드 완료");
        window.location.reload(); 
      } catch (error) { console.log(error); } 
    }    
  }
  async function ProductDataUpload(){
    let ProductData;
    if(ProductThickMin===0 || ProductLengthMin===0){
      ProductData = {
        Type : ProductType.current.value,
        Usage : ProductUsage,
        Hard : ProductHard,
        Process : ProductProcess,
        Form : ProductForm,
        No : 999,
      };
    }
    else{
      ProductData = {
        Type : ProductType.current.value,
        Usage : ProductUsage,
        Hard : ProductHard,
        Process : ProductProcess,
        Thick : ProductThickMin + " ~ " + ProductThickMax + "mm",
        Length : ProductLengthMin + " ~ " + ProductLengthMax + "mm",
        Form : ProductForm,
        No : 999,
      };
    }
    
    try {
      const UserListRef = collection(props.db, "Product"); // 컬렉션 선택 
      const docRef = await addDoc(UserListRef, ProductData);
      await updateDoc(docRef, { Code : docRef.id });
    } catch (error) {
      console.log(error);
    }
  }

  // ProductBanner Data 업로드 
  async function ProductBannerUpload(e){
    e.preventDefault();
    if(window.confirm("업로드 하시겠습니까?")){   
      try {
        SetUploading(true);
        await ProductBannerDataUpload();
        alert("업로드 완료");
        window.location.reload(); 
      } catch (error) { console.log(error); } 
    }    
  }
  async function ProductBannerDataUpload(){
    const ProductBannerData = {
      Type : ProductBannerType.current.value,
      Name : ProductBannerName.current.value,
      Video : ProductBannerVideo.current.value,
      No : 999,
    }; 

    try {
      const UserListRef = collection(props.db, "ProductBanner"); // 컬렉션 선택 
      const docRef = await addDoc(UserListRef, ProductBannerData);
      await updateDoc(docRef, { Code : docRef.id });
    } catch (error) {
      console.log(error);
    }
  }

  // Facility Data 업로드 
  async function FacilityUpload(e){
    e.preventDefault();
    if(window.confirm("업로드 하시겠습니까?")){   
      try {
        SetUploading(true);
        await FacilityDataUpload();
        alert("업로드 완료");
        window.location.reload(); 
      } catch (error) { console.log(error); } 
    }    
  }
  async function FacilityDataUpload(){
    const facilityData = {
      Type : FacilityType.current.value,
      Name : FacilityName.current.value,
      Video : FacilityVideo.current.value,
      No : 999,
    }; 

    try {
      const UserListRef = collection(props.db, "Facility"); // 컬렉션 선택 
      const docRef = await addDoc(UserListRef, facilityData);
      await updateDoc(docRef, { Code : docRef.id });
    } catch (error) {
      console.log(error);
    }
  }

  // 지점 Data 업로드
  async function LocationUpload(e){
    e.preventDefault();
    if(window.confirm("업로드 하시겠습니까?")){   
      try {
        SetUploading(true);
        await LocDataUpload();
        alert("업로드 완료");
        window.location.reload(); 
      } catch (error) { console.log(error); } 
    }
  }
  async function LocDataUpload(){
    try {
      const UserListRef = collection(props.db, "Location"); // 컬렉션 선택
      const userData = {
        Name : LocationName.current.value,
        Email : LocationEmail.current.value,
        Tel : LocationTel.current.value,
        Fax : LocationFax.current.value,
        Location : LocationAddr.current.value,
        IMG : "",
        No : 999,
      };   
      const docRef = await addDoc(UserListRef, userData);
      await updateDoc(docRef, { Code : docRef.id });
      await props.handleFileSelect(docRef, "Location", LocationIMGRef.current.files[0], LocaitonIMG);
    } catch (error) {
      console.log(error);
    }
  }

  // 조직도 업로드
  async function OrgUpload(e){
    e.preventDefault();
    if(window.confirm("업로드 하시겠습니까?")){   
      try {
        SetUploading(true);
        await OrgDataUpload();
        alert("업로드 완료");
        window.location.reload(); 
      } catch (error) { console.log(error); } 
    }
  }
  async function OrgDataUpload(){
    try {
      const UserListRef = collection(props.db, "Organize"); // 컬렉션 선택
      const userData = {
        Display : OrgDisplay.current.value,
        IMG : "",
        No : 999,
      };   
      const docRef = await addDoc(UserListRef, userData);
      await updateDoc(docRef, { Code : docRef.id });
      await props.handleFileSelect(docRef, "Organize", OrgIMGRef.current.files[0], OrgIMG);
    } catch (error) {
      console.log(error);
    }
  }

  // ci 배너이미지 업로드
  async function CIBannerUpload(e){
    e.preventDefault();
    if(window.confirm("업로드 하시겠습니까?")){   
      try {
        SetUploading(true);
        await CIBannerDataUpload();
        alert("업로드 완료");
        window.location.reload(); 
      } catch (error) { console.log(error); } 
    }
  }
  async function CIBannerDataUpload(){
    try {
      const UserListRef = collection(props.db, "CI"); // 컬렉션 선택
      const userData = {
        Name : CIBannerName.current.value,
        NameSub : CIBannerNameSub.current.value,
        IMG : "",
        No : 999,
      };   
      const docRef = await addDoc(UserListRef, userData);
      await updateDoc(docRef, { Code : docRef.id });
      await props.handleFileSelect(docRef, "CI", CIBannerIMGRef.current.files[0], CIBannerIMG);
    } catch (error) {
      console.log(error);
    }
  }

  // 상단 배너이미지 업로드
  async function TopBannerUpload(e){
    e.preventDefault();
    if(window.confirm("업로드 하시겠습니까?")){   
      try {
        SetUploading(true);
        await TopBannerDataUpload();
        alert("업로드 완료");
        window.location.reload(); 
      } catch (error) { console.log(error); } 
    }
  }
  async function TopBannerDataUpload(){
    try {
      const UserListRef = collection(props.db, "Banner"); // 컬렉션 선택
      const userData = {
        Page : TopBannerPage.current.value,
        Type : TopBannerType.current.value,
        IMG : "",
      };   
      const docRef = await addDoc(UserListRef, userData);
      await updateDoc(docRef, { Code : docRef.id });
      await props.handleFileSelect(docRef, "Banner", TopBannerIMGRef.current.files[0], TopBannerIMG);
    } catch (error) {
      console.log(error);
    }
  }

  // 주요거래처 Data 업로드 
  async function CustomerUpload(e){
    e.preventDefault();
    if(window.confirm("업로드 하시겠습니까?")){   
      try {
        SetUploading(true);
        await CustomerDataUpload();
        alert("업로드 완료");
        window.location.reload(); 
      } catch (error) { console.log(error); } 
    }
  }
  async function CustomerDataUpload(){
    try {
      const UserListRef = collection(props.db, "Customer"); // 컬렉션 선택
      const customerData = {
        Name : CustomerName.current.value,
        IMG : "",
        No : 999,
      };   
      const docRef = await addDoc(UserListRef, customerData);
      await updateDoc(docRef, { Code : docRef.id });
      await props.handleFileSelect(docRef, "Customer", CustomerIMGRef.current.files[0], CustomerIMG);
    } catch (error) {
      console.log(error);
    }
  }

  // 공정과정 Data 업로드 
  async function ProcessBannerUpload(e){
    e.preventDefault();
    if(window.confirm("업로드 하시겠습니까?")){   
      try {
        SetUploading(true);
        await ProcessBannerDataUpload();
        alert("업로드 완료");
        window.location.reload(); 
      } catch (error) { console.log(error); } 
    }
  }
  async function ProcessBannerDataUpload(){
    try {
      const UserListRef = collection(props.db, "ProcessBanner"); // 컬렉션 선택
      const ProcessBannerData = {
        Type : ProcessBannerType.current.value,
        Display : ProcessBannerDisplay.current.value,
        IMG : "",
        No : 999,
      };   
      const docRef = await addDoc(UserListRef, ProcessBannerData);
      await updateDoc(docRef, { Code : docRef.id });
      await props.handleFileSelect(docRef, "ProcessBanner", ProcessBannerIMGRef.current.files[0], ProcessBannerIMG);
    } catch (error) {
      console.log(error);
    }
  }

  // 공정장비 Data 업로드 
  async function EquipUpload(e){
    e.preventDefault();
    if(window.confirm("업로드 하시겠습니까?")){   
      try {
        SetUploading(true);
        await EquipDataUpload();
        alert("업로드 완료");
        window.location.reload(); 
      } catch (error) { console.log(error); } 
    }
  }
  async function EquipDataUpload(){
    try {
      const UserListRef = collection(props.db, "Equip"); // 컬렉션 선택
      const EquipData = {
        Name : EquipName.current.value,
        Type : EquipType.current.value,
        IMG : "",
        No : 999,
      };   
      const docRef = await addDoc(UserListRef, EquipData);
      await updateDoc(docRef, { Code : docRef.id });
      await props.handleFileSelect(docRef, "Equip", EquipIMGRef.current.files[0], EquipIMG);
    } catch (error) {
      console.log(error);
    }
  }

  // 이미지 업로드 설정
  async function CheckMLoc(e) {
    const file = e.target.files[0];   // 업로드 할 파일
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (file && file.size > maxSizeInBytes && file.size===0) {
      alert('파일 크기가 5MB를 초과합니다. 다른 파일을 선택해주세요.');
      e.target.value = ''; // 파일 선택 취소
      SetLocationIMGupload(false);
    }
    else{
      SetLocationIMGupload(true);
    }
  }

  async function CheckMOrg(e) {
    const file = e.target.files[0];   // 업로드 할 파일
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (file && file.size > maxSizeInBytes && file.size===0) {
      alert('파일 크기가 5MB를 초과합니다. 다른 파일을 선택해주세요.');
      e.target.value = ''; // 파일 선택 취소
      SetOrgIMGupload(false);
    }
    else{
      SetOrgIMGupload(true);
    }
  }

  async function CheckMCIBanner(e) {
    const file = e.target.files[0];   // 업로드 할 파일
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (file && file.size > maxSizeInBytes && file.size===0) {
      alert('파일 크기가 5MB를 초과합니다. 다른 파일을 선택해주세요.');
      e.target.value = ''; // 파일 선택 취소
      SetCIBannerIMGupload(false);
    }
    else{
      SetCIBannerIMGupload(true);
    }
  }

  async function CheckMTopBanner(e) {
    const file = e.target.files[0];   // 업로드 할 파일
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (file && file.size > maxSizeInBytes && file.size===0) {
      alert('파일 크기가 5MB를 초과합니다. 다른 파일을 선택해주세요.');
      e.target.value = ''; // 파일 선택 취소
      SetTopBannerIMGupload(false);
    }
    else{
      SetTopBannerIMGupload(true);
    }
  }

  async function CheckMCustomer(e) {
    const file = e.target.files[0];   // 업로드 할 파일
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (file && file.size > maxSizeInBytes && file.size===0) {
      alert('파일 크기가 5MB를 초과합니다. 다른 파일을 선택해주세요.');
      e.target.value = ''; // 파일 선택 취소
      SetCustomerIMGupload(false);
    }
    else{
      SetCustomerIMGupload(true);
    }
  }

  async function CheckMEquip(e) {
    const file = e.target.files[0];   // 업로드 할 파일
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (file && file.size > maxSizeInBytes && file.size===0) {
      alert('파일 크기가 5MB를 초과합니다. 다른 파일을 선택해주세요.');
      e.target.value = ''; // 파일 선택 취소
      SetEquipIMGupload(false);
    }
    else{
      SetEquipIMGupload(true);
    }
  }

  async function CheckMProcessBanner(e) {
    const file = e.target.files[0];   // 업로드 할 파일
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (file && file.size > maxSizeInBytes && file.size===0) {
      alert('파일 크기가 5MB를 초과합니다. 다른 파일을 선택해주세요.');
      e.target.value = ''; // 파일 선택 취소
      SetProcessBannerIMGupload(false);
    }
    else{
      SetProcessBannerIMGupload(true);
    }
  }

  const [ProductTypeChange, SetProductTypeChange] = useState("CHQ WIRE");
  const ProductSelect = (e) => {
    SetProductTypeChange(e.target.value);
  }

  return (
    <div>
      {Uploading ? 
      <div className='UploadingBlock flex flex-1 w-full h-full justify-center items-center'>
        <i className="fa-solid fa-circle-notch fa-spin"></i> 
      </div> : null}
      <div className='Posting flex flex-col items-center'>
        <div className="Section con flex flex-col w-full pt-10 pb-40">
          {MyPageLoaded && ( props.UserData[0].Grade==="Manager" || props.UserData[0].Grade==="Master") && props.auth.currentUser.emailVerified ? 
          <div>
            <div className='ProfileSection flex flex-col w-full'>
              <div className='Title flex w-full'>관리자 프로필</div>
              <div className='Contexts flex flex-col flex-1 justify-center'>
                <div className='flex mb-3'>닉네임 : {props.UserData[0].username}</div>
                <div className='flex mb-3'>등급 : {props.UserData[0].Grade}</div>
                <div className='flex mb-3'>이메일 : {props.UserData[0].email}</div>
                <div className='flex mt-1 flex-1'>
                  {props.UserData[0].socialLogin === "" && props.auth.currentUser.emailVerified ?
                    <button className='flex AddrSearchButton2 justify-center items-center mr-3' onClick={OpenPWChage}>비밀번호 변경</button> : null}
                    <button className='flex AddrSearchButton2 justify-center items-center' onClick={OpenQuitChage}>회원탈퇴</button>
                </div>
              </div>
              {ManagerLoading ?
              <div className='ManageGrid flex w-full flex-col'>
                <div className='ManageNode flex w-full items-center' onClick={OpenPostDetail}>
                  <p className='SubTitle flex flex-1'>관리계정 관리하기</p>
                  <i className="fa-solid fa-chevron-left ml-3"></i>
                </div>
                <div className='ManageContents flex w-full flex-col'>
                  <div className="ManageContentsNode flex items-center gap-y-4 flex-col">
                    {Manager.map((manager, index)=>(
                    <div className='flex w-full gap-4 items-center'>
                      <p>{index+1}</p>
                      <p>{manager.username}</p>
                      <p>{manager.email}</p>
                      <p>{manager.Grade}</p>
                      <p>{manager.signedDate}</p>
                      {props.UserData[0].Grade==="Master" && manager.Grade==="Common" ?
                        <button className='AddrSearchButton2' onClick={(e) => GradeCtrl(e, manager.UserCode, "Manager")}>매니저 승격</button> : null}
                      {props.UserData[0].Grade==="Master" && manager.Grade==="Manager" ?                       
                        <button className='AddrSearchButton2' onClick={(e) => GradeCtrl(e, manager.UserCode, "Common")}>매니저 강등</button>: null}
                    </div>
                     ))} 
                  </div>
                </div>
              </div>
               : null}
            </div>
            <div className='ManageSection flex flex-col w-full items-center'>
              <div className='Title flex w-full'>홈페이지 관리</div>
              <div className='ManageGrid flex w-full flex-col'>
                <div className='ManageNode flex w-full items-center' onClick={OpenPostDetail}>
                  <p className='SubTitle flex flex-1'>회사연락처 & 지점 관리하기</p>
                  <i className="fa-solid fa-chevron-left ml-3"></i>
                </div>
                <div className='ManageContents flex w-full flex-col'>
                <p className="Sub2ndTitle">연락처 관리하기</p>
                  {ContactLoading ?
                  <div className="ManageContentsNode flex flex-col gap-y-4 items-center">
                    {Contact.map((contact, index)=>(
                      <form class="flex w-full gap-4 flex-col" onSubmit={ContactUpload}>
                        <div class="flex w-full gap-2 items-center ml-4">
                          <p class="w-16">전화번호</p>
                          <input class="flex w-60 h-12 AddrSearchButton6" ref={Phone} type="text" placeholder="전화번호*" defaultValue={contact.Phone} required/>
                          <p class="ml-4">전화번호 Sub</p>
                          <input class="flex w-60 h-12 AddrSearchButton6" ref={PhoneSub} type="text" placeholder="전화번호*" defaultValue={contact.PhoneSub}/>
                        </div>
                        <div class="flex w-full gap-2 items-center ml-4">
                          <p class="w-16">이메일</p>
                          <input class="flex w-80 h-12 AddrSearchButton6" ref={Email} type="text" placeholder="이메일*" defaultValue={contact.Email} required/>
                          <p class="ml-4">이메일 Sub</p>
                          <input class="flex w-80 h-12 AddrSearchButton6" ref={EmailSub} type="text" placeholder="이메일 Sub" defaultValue={contact.EmailSub}/>
                        </div>
                          <div class="flex w-full gap-2 items-center ml-4">
                            <p class="w-16">FAX</p>
                            <input class="flex w-60 h-12 AddrSearchButton6" ref={FAX} type="text" placeholder="FAX 1" defaultValue={contact.Fax}/>
                            <p class="ml-4">FAX Sub</p>
                            <input class="flex w-60 h-12 AddrSearchButton6" ref={FAXSub} type="text" placeholder="FAX 2" defaultValue={contact.FaxSub}/>
                          </div>
                          <button type="submit" class="Login_Submit flex ml-4 mt-2 w-60 h-12 justify-center AddrSearchButton2">수정하기</button>
                      </form>
                    ))} 
                  </div>
                  : null}
                  <p className="Sub2ndTitle">회사지점 관리하기</p>
                  {LocLoading ?
                  <div className="ManageContentsNode flex flex-col gap-y-4 items-center">
                    {Loc.map((loc, index)=>(
                    <div className='flex w-full gap-4 items-center'>
                      <p>{index+1}</p>
                      <p>{loc.Name}</p>
                      <p>{loc.Email}</p>
                      <p>{loc.Tel}</p>
                      <p>{loc.Fax}</p>
                      <p>{loc.Location}</p>
                      <i className="fa-solid fa-image AddrSearchButton2">
                        <div className='ManageContentsNodeIMG items-center justify-center'>
                          {loc.IMG==="" ? 
                            <p className='text-black'>No Image</p>
                          : <img src={loc.IMG} alt={loc.Name}></img>}
                        </div>
                      </i>
                      <select value={String(loc.No)} onChange={(e) => props.handleChange(e, "Location")} id={loc.id}> 
                        <option value="1" id={loc.Code}>1</option>
                        <option value="2" id={loc.Code}>2</option>
                        <option value="3" id={loc.Code}>3</option>
                        <option value="4" id={loc.Code}>4</option>
                        <option value="5" id={loc.Code}>5</option>
                        <option value="6" id={loc.Code}>6</option>
                        <option value="7" id={loc.Code}>7</option>
                        <option value="8" id={loc.Code}>8</option>
                        <option value="9" id={loc.Code}>9</option>
                        <option value="10" id={loc.Code}>10</option>
                        <option value="999" id={loc.Code}>999</option>
                      </select>
                      <button className='AddrSearchButton2' onClick={(e) => props.DeleteDBwithIMG(e, loc.Code, "Location", loc.IMG)}>삭제하기</button> 
                    </div> 
                    ))} 
                  </div>
                  : null}
                  <div className='flex h-5'></div>
                  <div className='flex w-full AddrSearchButton6'>
                    <form onSubmit={LocationUpload} className='flex flex-col w-full mt-8 mb-8 ml-3 mr-3'>
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" ref={LocationName} type="text" placeholder='지점명*' required/>	
                      </div>
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" ref={LocationEmail} type="text" placeholder='지점 이메일'/>	
                      </div>
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" ref={LocationTel} type="text" placeholder='지점 전화번호'/>	
                      </div>
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" ref={LocationFax} type="text" placeholder='지점 FAX'/>	
                      </div>
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" ref={LocationAddr} type="text" placeholder='지점 주소*' required/>	
                      </div>
                      <div className='flex mb-3 ml-5 mt-2'>
                        <input type="file" ref={LocationIMGRef} onChange={CheckMLoc} accept="image/*"/>
                      </div>
                      <div className='flex mt-5 w-full justify-center'>
                        <button type="submit" className='Login_Submit flex flex-1 justify-center AddrSearchButton2'>회사지점 등록하기</button>
                      </div>
                    </form> 
                  </div>
                </div>
              </div>
              <div className='ManageGrid flex w-full flex-col'>
                <div className='ManageNode flex w-full items-center' onClick={OpenPostDetail}>
                  <p className='SubTitle flex flex-1'>회사연혁 관리하기</p>
                  <i className="fa-solid fa-chevron-left ml-3"></i>
                </div>
                <div className='ManageContents flex w-full flex-col'>
                  {HistoryLoading ?
                  <div className="ManageContentsNode flex flex-col gap-y-4 items-center">
                    {History.map((history, index)=>(
                    <div className='flex w-full gap-4 items-center'>
                      <p>{index+1}</p>
                      <p>{history.Year}</p>
                      <p>{history.Month}</p>
                      <p>{history.Detail}</p>
                      <button className='AddrSearchButton2' onClick={(e) => props.DeleteDBnoneIMG(e,history.Code, "History")}>삭제하기</button> 
                    </div> 
                    ))} 
                  </div>
                  : null}
                  <div className='flex AddrSearchButton6'>
                    <form onSubmit={HistoryUpload} className='flex flex-col w-full mt-8 mb-8 ml-3 mr-3'>
                      <div className='flex w-full mb-5 items-center ml-6'>
                        년도 <input className="flex AddrSearchButton4 h-12" ref={HistoryYear} type="number" min={1} required/>	
                      </div>
                      <div className='flex w-full mb-5 items-center ml-6'>
                        월 <input className="flex AddrSearchButton4 h-12" ref={HistoryMonth} type="number" min={1} max={12} required/>	
                      </div>
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" ref={HistoryDetail} type="text" placeholder='상세*' required/>	
                      </div>
                      <div className='flex mt-5 w-full justify-center'>
                        <button type="submit" className='Login_Submit flex flex-1 justify-center AddrSearchButton2'>회사연혁 등록하기</button>
                      </div>
                    </form> 
                  </div>
                </div>
              </div>
              <div className='ManageGrid flex w-full flex-col'>
                <div className='ManageNode flex w-full items-center' onClick={OpenPostDetail}>
                  <p className='SubTitle flex flex-1'>조직도 관리하기</p>
                  <i className="fa-solid fa-chevron-left ml-3"></i>
                </div>
                <div className='ManageContents flex w-full flex-col'>
                  {OrgLoading ?
                  <div className="ManageContentsNode flex flex-col gap-y-4 items-center">
                    {Org.map((org, index)=>(
                    <div className='flex w-full gap-4 items-center'>
                      <p>{index+1}</p>
                      <i className="fa-solid fa-image AddrSearchButton2">
                        <div className='ManageContentsNodeIMG items-center justify-center'>
                          {org.IMG==="" ? 
                            <p className='text-black'>No Image</p>
                          : <img src={org.IMG} alt={org.Name}></img>}
                        </div>
                      </i>
                      <p>{org.Display}</p>
                      <select value={String(org.No)} onChange={(e) => props.handleChange(e, "Organize")} id={org.id}> 
                        <option value="1" id={org.Code}>1</option>
                        <option value="2" id={org.Code}>2</option>
                        <option value="3" id={org.Code}>3</option>
                        <option value="4" id={org.Code}>4</option>
                        <option value="5" id={org.Code}>5</option>
                        <option value="6" id={org.Code}>6</option>
                        <option value="7" id={org.Code}>7</option>
                        <option value="8" id={org.Code}>8</option>
                        <option value="9" id={org.Code}>9</option>
                        <option value="10" id={org.Code}>10</option>
                        <option value="999" id={org.Code}>999</option>
                      </select>
                      <button className='AddrSearchButton2' onClick={(e) => props.DeleteDBwithIMG(e, org.Code, "Organize", org.IMG)}>삭제하기</button> 
                    </div> 
                    ))} 
                  </div>
                  : null}
                  <div className='flex h-5'></div>
                  <div className='flex w-full AddrSearchButton6'>
                    <form onSubmit={OrgUpload} className='flex flex-col w-full mt-8 mb-8 ml-3 mr-3'>
                      <div className='flex mb-3 ml-5 mt-2'>
                        <input type="file" ref={OrgIMGRef} onChange={CheckMOrg} accept="image/*"/>
                      </div>
                      <div className='flex w-full mb-5'>
                        <select ref={OrgDisplay} className='flex AddrSearchButton4 h-12 w-full'>
                          <option selected={true} value="PC">PC</option>
                          <option value="MOBILE">MOBILE</option>
                        </select>
                      </div>
                      <div className='flex mt-5 w-full justify-center'>
                        <button type="submit" className='Login_Submit flex flex-1 justify-center AddrSearchButton2'>조직도 등록하기</button>
                      </div>
                    </form> 
                  </div>
                </div>
              </div>
              <div className='ManageGrid flex w-full flex-col'>
                <div className='ManageNode flex w-full items-center' onClick={OpenPostDetail}>
                  <p className='SubTitle flex flex-1'>주요거래처 관리하기</p>
                  <i className="fa-solid fa-chevron-left ml-3"></i>
                </div>
                <div className='ManageContents flex w-full flex-col'>
                  {CustomerLoading ?
                  <div className="ManageContentsNode flex flex-col gap-y-4 items-center">
                    {Customer.map((customer, index)=>(
                    <div className='flex w-full gap-4 items-center'>
                      <p>{index+1}</p>
                      <p>{customer.Name}</p>
                      <i className="fa-solid fa-image AddrSearchButton2">
                        <div className='ManageContentsNodeIMG items-center justify-center'>
                          {customer.IMG==="" ? 
                            <p className='text-black'>No Image</p>
                            : <img src={customer.IMG} alt={customer.Name}></img>}
                        </div>
                      </i>
                      <select value={String(customer.No)} onChange={(e) => props.handleChange(e, "Customer")} id={customer.id}> 
                        <option value="1" id={customer.Code}>1</option>
                        <option value="2" id={customer.Code}>2</option>
                        <option value="3" id={customer.Code}>3</option>
                        <option value="4" id={customer.Code}>4</option>
                        <option value="5" id={customer.Code}>5</option>
                        <option value="6" id={customer.Code}>6</option>
                        <option value="7" id={customer.Code}>7</option>
                        <option value="8" id={customer.Code}>8</option>
                        <option value="9" id={customer.Code}>9</option>
                        <option value="10" id={customer.Code}>10</option>
                        <option value="11" id={customer.Code}>11</option>
                        <option value="12" id={customer.Code}>12</option>
                        <option value="13" id={customer.Code}>13</option>
                        <option value="14" id={customer.Code}>14</option>
                        <option value="15" id={customer.Code}>15</option>
                        <option value="16" id={customer.Code}>16</option>
                        <option value="17" id={customer.Code}>17</option>
                        <option value="18" id={customer.Code}>18</option>
                        <option value="19" id={customer.Code}>19</option>
                        <option value="20" id={customer.Code}>20</option>
                        <option value="999" id={customer.Code}>999</option>
                      </select>
                      <button className='AddrSearchButton2' onClick={(e) => props.DeleteDBwithIMG(e, customer.Code, "Customer", customer.IMG)}>삭제하기</button> 
                    </div> 
                    ))} 
                  </div>
                  : null}
                  <div className='flex h-5'></div>
                    <div className='flex w-full AddrSearchButton6'>
                      <form onSubmit={CustomerUpload} className='flex flex-col w-full mt-8 mb-8 ml-3 mr-3'>
                        <div className='flex w-full mb-5'>
                          <input className="flex w-full AddrSearchButton4 h-12" ref={CustomerName} type="text" placeholder='거래처명*' required/>	
                        </div>
                        <div className='flex mb-3 ml-5 mt-2'>
                          <input type="file" ref={CustomerIMGRef} onChange={CheckMCustomer} accept="image/*"/>
                        </div>
                        <div className='flex mt-5 w-full justify-center'>
                          <button type="submit" className='Login_Submit flex flex-1 justify-center AddrSearchButton2'>주요거래처 등록하기</button>
                        </div>
                      </form> 
                    </div>
                </div>
              </div>
              <div className='ManageGrid flex w-full flex-col'>
                <div className='ManageNode flex w-full items-center' onClick={OpenPostDetail}>
                  <p className='SubTitle flex flex-1'>게시물 관리하기 ( 공지사항, 특허·인증 ) </p>
                  <i className="fa-solid fa-chevron-left ml-3"></i>
                </div>
                <div className='ManageContents flex w-full flex-col'>
                  <div className="ManageContentsNode flex items-center flex-wrap gap-4">
                    <Link className="flex justify-center h-10 PinBasic" to="/News" target="_blank">
                      공지사항 관리하기 <i className="fa-solid fa-up-right-from-square ml-1"></i>
                    </Link>
                    <Link className="flex justify-center h-10 PinBasic" to="/Patent" target="_blank">
                      특허·인증 관리하기 <i className="fa-solid fa-up-right-from-square ml-1"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className='ManageGrid flex w-full flex-col'>
                <div className='ManageNode flex w-full items-center' onClick={OpenPostDetail}>
                  <p className='SubTitle flex flex-1'>생산 관리하기 ( 제품목록 제품소개 동영상, 공정 장비, 공정(시설)소개 동영상 ) </p>
                  <i className="fa-solid fa-chevron-left ml-3"></i>
                </div>
                <div className='ManageContents flex w-full flex-col'>
                  <p className="Sub2ndTitle">제품 목록 관리하기</p>
                  {ProductLoading ?
                  <div className="ManageContentsNode flex flex-col gap-y-4 items-center">
                    {Product.map((product, index)=>(
                    <div className='flex w-full gap-4 items-center'>
                      <p>{index+1}</p>
                      {product.Type ==="" ? null : <p>{product.Type}</p> }
                      {product.Usage ===""  ? null : <p>{product.Usage}</p> }
                      {product.Hard ==="" ? null : <p>{product.Hard}</p> }
                      {product.Process ===""  ? null : <p>{product.Process}</p> }
                      {product.Form ===""  ? null : <p>{product.Form}</p> }
                      {product.Thick ===""  ? null : <p>{product.Thick}</p> }
                      {product.Length ==="" ? null : <p>{product.Length}</p> }
                      <select value={String(product.No)} onChange={(e) => props.handleChange(e, "Product")} id={product.id}> 
                        <option value="1" id={product.Code}>1</option>
                        <option value="2" id={product.Code}>2</option>
                        <option value="3" id={product.Code}>3</option>
                        <option value="4" id={product.Code}>4</option>
                        <option value="5" id={product.Code}>5</option>
                        <option value="6" id={product.Code}>6</option>
                        <option value="7" id={product.Code}>7</option>
                        <option value="8" id={product.Code}>8</option>
                        <option value="9" id={product.Code}>9</option>
                        <option value="10" id={product.Code}>10</option>
                        <option value="999" id={product.Code}>999</option>
                      </select>
                      <button className='AddrSearchButton2' onClick={(e) => props.DeleteDBnoneIMG(e, product.Code, "Product")}>삭제하기</button> 
                    </div> 
                    ))} 
                  </div>
                  : null}
                  <div className='flex h-5'></div>
                  <div className='flex AddrSearchButton6'>
                    <form onSubmit={ProductUpload} className='flex flex-col w-full mt-8 mb-8 ml-3 mr-3'>
                      <div className='flex w-full mb-5'>
                        <select ref={ProductType} onChange={ProductSelect} className='flex AddrSearchButton4 h-12 w-full'>
                          <option selected={true} value="CHQ WIRE">CHQ WIRE</option>
                          <option value="CD BAR">CD BAR</option>
                          <option value="CD BAR Other">CD BAR Other</option>
                          <option value="CUTTING">CUTTING</option>
                        </select>
                      </div>
                      {ProductTypeChange==="CHQ WIRE" || ProductTypeChange==="CD BAR" || ProductTypeChange==="CUTTING" ? 
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" value={ProductUsage} onChange={handleProductUsage} type="text" placeholder='용도*' required/>	
                      </div> : null}
                      {ProductTypeChange==="CHQ WIRE" || ProductTypeChange==="CD BAR" || ProductTypeChange==="CUTTING" ? 
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" value={ProductHard} onChange={handleProductHard} type="text" placeholder='강종*' required/>	
                      </div> : null}

                      {ProductTypeChange==="CHQ WIRE" ? 
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" value={ProductProcess} onChange={handleProductProcess} type="text" placeholder='공정*' required/>	
                      </div> : null}

                      {ProductTypeChange==="CD BAR Other" || ProductTypeChange==="CUTTING" ? 
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" value={ProductForm} onChange={handleProductForm} type="text" placeholder='형태*' required/>	
                      </div> : null}
                      {ProductTypeChange==="CD BAR Other" ? 
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 w-20 h-12" value={ProductThickMin} onChange={handleProductThickMin} defaultValue={0} min={0} type="number" required/> ~
                        <input className="flex w-full AddrSearchButton4 w-20 h-12" value={ProductThickMax} onChange={handleProductThickMax} defaultValue={0} min={0} type="number" required/>	mm
                      </div> : null}
                      {ProductTypeChange==="CD BAR Other" ? 
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 w-20 h-12" value={ProductLengthMin} onChange={handleProductLengthMin} defaultValue={0} type="number" required/> ~
                        <input className="flex w-full AddrSearchButton4 w-20 h-12" value={ProductLengthMax} onChange={handleProductLengthMax} defaultValue={0} type="number" required/>	mm
                      </div> : null}
                      <div className='flex mt-5 w-full justify-center'>
                        <button type="submit" className='Login_Submit flex flex-1 justify-center AddrSearchButton2'>제품 추가하기</button>
                      </div>
                    </form> 
                  </div>
                  <div className='flex h-20'></div>
                  <p className="Sub2ndTitle">제품소개 동영상</p>
                  {ProductBannerLoading ?
                  <div className="ManageContentsNode flex flex-col gap-y-4 items-center">
                    {ProductBanner.map((productbanner, index)=>(
                    <div className='flex w-full gap-4 items-center'>
                      <p>{index+1}</p>
                      <p>{productbanner.Type}</p>
                      <p>{productbanner.Name}</p>
                      <p>{productbanner.Video}</p>
                      <select value={String(productbanner.No)} onChange={(e) => props.handleChange(e, "ProductBanner")} id={productbanner.id}> 
                        <option value="1" id={productbanner.Code}>1</option>
                        <option value="2" id={productbanner.Code}>2</option>
                        <option value="3" id={productbanner.Code}>3</option>
                        <option value="4" id={productbanner.Code}>4</option>
                        <option value="5" id={productbanner.Code}>5</option>
                        <option value="6" id={productbanner.Code}>6</option>
                        <option value="7" id={productbanner.Code}>7</option>
                        <option value="8" id={productbanner.Code}>8</option>
                        <option value="9" id={productbanner.Code}>9</option>
                        <option value="10" id={productbanner.Code}>10</option>
                        <option value="999" id={productbanner.Code}>999</option>
                      </select>
                      <button className='AddrSearchButton2' onClick={(e) => props.DeleteDBnoneIMG(e, productbanner.Code, "ProductBanner")}>삭제하기</button> 
                    </div> 
                    ))} 
                  </div>
                  : null}
                  <div className='flex h-5'></div>
                  <div className='flex AddrSearchButton6'>
                    <form onSubmit={ProductBannerUpload} className='flex flex-col w-full mt-8 mb-8 ml-3 mr-3'>
                      <div className='flex w-full mb-5'>
                        <select ref={ProductBannerType} className='flex AddrSearchButton4 h-12 w-full'>
                          <option selected={true} value="CHQ WIRE">CHQ WIRE</option>
                          <option value="CD BAR">CD BAR</option>
                          <option value="CUTTING">CUTTING</option>
                        </select>
                      </div>
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" ref={ProductBannerName} type="text" placeholder='이름*' required/>	
                      </div>
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" ref={ProductBannerVideo} type="text" placeholder='유튜브 동영상 링크*' required/>	
                      </div>
                      <div className='flex mt-5 w-full justify-center'>
                        <button type="submit" className='Login_Submit flex flex-1 justify-center AddrSearchButton2'>제품소개 동영상 추가하기</button>
                      </div>
                    </form> 
                  </div>
                  <div className='flex h-20'></div>
                  <p className="Sub2ndTitle">공정 과정 ( 공정 과정정보를 가진 이미지 업로드 ) </p>
                  {ProcessBannerLoading ?
                  <div className="ManageContentsNode flex flex-col gap-y-4 items-center">
                    {ProcessBanner.map((processbanner, index)=>(
                    <div className='flex w-full gap-4 items-center'>
                      <p>{index+1}</p>
                      <p>{processbanner.Type}</p>
                      <p>{processbanner.Display}</p>
                      <i className="fa-solid fa-image AddrSearchButton2">
                        <div className='ManageContentsNodeIMG items-center justify-center'>
                          {processbanner.IMG==="" ? 
                            <p className='text-black'>No Image</p>
                            : <img src={processbanner.IMG} alt={processbanner.Name}></img>}
                        </div>
                      </i>
                      <select value={String(processbanner.No)} onChange={(e) => props.handleChange(e, "ProcessBanner")} id={processbanner.id}> 
                        <option value="1" id={processbanner.Code}>1</option>
                        <option value="2" id={processbanner.Code}>2</option>
                        <option value="3" id={processbanner.Code}>3</option>
                        <option value="4" id={processbanner.Code}>4</option>
                        <option value="5" id={processbanner.Code}>5</option>
                        <option value="6" id={processbanner.Code}>6</option>
                        <option value="7" id={processbanner.Code}>7</option>
                        <option value="8" id={processbanner.Code}>8</option>
                        <option value="9" id={processbanner.Code}>9</option>
                        <option value="10" id={processbanner.Code}>10</option>
                        <option value="999" id={processbanner.Code}>999</option>
                      </select>
                      <button className='AddrSearchButton2' onClick={(e) => props.DeleteDBwithIMG(e, processbanner.Code, "ProcessBanner", processbanner.IMG)}>삭제하기</button> 
                    </div> 
                    ))} 
                  </div>
                  : null}
                  <div className='flex h-5'></div>
                  <div className='flex AddrSearchButton6'>
                    <form onSubmit={ProcessBannerUpload} className='flex flex-col w-full mt-8 mb-8 ml-3 mr-3'>
                      <div className='flex w-full mb-5'>
                        <select ref={ProcessBannerType} className='flex AddrSearchButton4 h-12 w-full'>
                          <option selected={true} value="CHQ WIRE">CHQ WIRE</option>
                          <option value="CD BAR">CD BAR</option>
                          <option value="CUTTING">CUTTING</option>
                        </select>
                      </div>
                      <div className='flex w-full mb-5'>
                        <select ref={ProcessBannerDisplay} className='flex AddrSearchButton4 h-12 w-full'>
                          <option selected={true} value="PC">PC</option>
                          <option value="MOBILE">MOBILE</option>
                        </select>
                      </div>
                      <div className='flex w-full mb-5'>
                        <input type="file" ref={ProcessBannerIMGRef} onChange={CheckMProcessBanner} accept="image/*"/>
                      </div>
                      <div className='flex mt-5 w-full justify-center'>
                        <button type="submit" className='Login_Submit flex flex-1 justify-center AddrSearchButton2'>공정 과정 추가하기</button>
                      </div>
                    </form> 
                  </div>
                  <div className='flex h-20'></div>
                  <p className="Sub2ndTitle">공정 장비 ( 공정 장비정보를 가진 이미지 업로드 ) </p>
                  {EquipLoading ?
                  <div className="ManageContentsNode flex flex-col gap-y-4 items-center">
                    {Equip.map((equip, index)=>(
                    <div className='flex w-full gap-4 items-center'>
                      <p>{index+1}</p>
                      <p>{equip.Type}</p>
                      <p>{equip.Name}</p>
                      <i className="fa-solid fa-image AddrSearchButton2">
                        <div className='ManageContentsNodeIMG items-center justify-center'>
                          {equip.IMG==="" ? 
                            <p className='text-black'>No Image</p>
                            : <img src={equip.IMG} alt={equip.Name}></img>}
                        </div>
                      </i>
                      <select value={String(equip.No)} onChange={(e) => props.handleChange(e, "Equip")} id={equip.id}> 
                        <option value="1" id={equip.Code}>1</option>
                        <option value="2" id={equip.Code}>2</option>
                        <option value="3" id={equip.Code}>3</option>
                        <option value="4" id={equip.Code}>4</option>
                        <option value="5" id={equip.Code}>5</option>
                        <option value="6" id={equip.Code}>6</option>
                        <option value="7" id={equip.Code}>7</option>
                        <option value="8" id={equip.Code}>8</option>
                        <option value="9" id={equip.Code}>9</option>
                        <option value="10" id={equip.Code}>10</option>
                        <option value="999" id={equip.Code}>999</option>
                      </select>
                      <button className='AddrSearchButton2' onClick={(e) => props.DeleteDBwithIMG(e, equip.Code, "Equip", equip.IMG)}>삭제하기</button> 
                    </div> 
                    ))} 
                  </div>
                  : null}
                  <div className='flex h-5'></div>
                  <div className='flex AddrSearchButton6'>
                    <form onSubmit={EquipUpload} className='flex flex-col w-full mt-8 mb-8 ml-3 mr-3'>
                      <div className='flex w-full mb-5'>
                        <select ref={EquipType} className='flex AddrSearchButton4 h-12 w-full'>
                          <option selected={true} value="CHQ WIRE">CHQ WIRE</option>
                          <option value="CD BAR">CD BAR</option>
                          <option value="CUTTING">CUTTING</option>
                        </select>
                      </div>
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" ref={EquipName} type="text" placeholder='이름*' required/>	
                      </div>
                      <div className='flex w-full mb-5'>
                        <input type="file" ref={EquipIMGRef} onChange={CheckMEquip} accept="image/*"/>
                      </div>
                      <div className='flex mt-5 w-full justify-center'>
                        <button type="submit" className='Login_Submit flex flex-1 justify-center AddrSearchButton2'>공정 장비 추가하기</button>
                      </div>
                    </form> 
                  </div>
                  <div className='flex h-20'></div>
                  <p className="Sub2ndTitle">공정(시설)소개 동영상</p>
                  {FacilityLoading ?
                  <div className="ManageContentsNode flex flex-col gap-y-4 items-center">
                    {Facility.map((facility, index)=>(
                    <div className='flex w-full gap-4 items-center'>
                      <p>{index+1}</p>
                      <p>{facility.Type}</p>
                      <p>{facility.Name}</p>
                      <p>{facility.Video}</p>
                      <select value={String(facility.No)} onChange={(e) => props.handleChange(e, "Facility")} id={facility.id}> 
                        <option value="1" id={facility.Code}>1</option>
                        <option value="2" id={facility.Code}>2</option>
                        <option value="3" id={facility.Code}>3</option>
                        <option value="4" id={facility.Code}>4</option>
                        <option value="5" id={facility.Code}>5</option>
                        <option value="6" id={facility.Code}>6</option>
                        <option value="7" id={facility.Code}>7</option>
                        <option value="8" id={facility.Code}>8</option>
                        <option value="9" id={facility.Code}>9</option>
                        <option value="10" id={facility.Code}>10</option>
                        <option value="999" id={facility.Code}>999</option>
                      </select>
                      <button className='AddrSearchButton2' onClick={(e) => props.DeleteDBnoneIMG(e, facility.Code, "Facility")}>삭제하기</button> 
                    </div> 
                    ))} 
                  </div>
                  : null}
                  <div className='flex h-5'></div>
                  <div className='flex AddrSearchButton6'>
                    <form onSubmit={FacilityUpload} className='flex flex-col w-full mt-8 mb-8 ml-3 mr-3'>
                      <div className='flex w-full mb-5'>
                        <select ref={FacilityType} className='flex AddrSearchButton4 h-12 w-full'>
                          <option selected={true} value="CHQ WIRE">CHQ WIRE</option>
                          <option value="CD BAR">CD BAR</option>
                          <option value="CUTTING">CUTTING</option>
                        </select>
                      </div>
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" ref={FacilityName} type="text" placeholder='이름*' required/>	
                      </div>
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" ref={FacilityVideo} type="text" placeholder='유튜브 동영상 링크*' required/>	
                      </div>
                      <div className='flex mt-5 w-full justify-center'>
                        <button type="submit" className='Login_Submit flex flex-1 justify-center AddrSearchButton2'>공정(시설)소개 동영상 추가하기</button>
                      </div>
                    </form> 
                  </div>

                  
                </div>
              </div>
              <div className='ManageGrid flex w-full flex-col'>
                <div className='ManageNode flex w-full items-center' onClick={OpenPostDetail}>
                  <p className='SubTitle flex flex-1'>배너 관리하기 ( 메인동영상, CI배너 )</p>
                  <i className="fa-solid fa-chevron-left ml-3"></i>
                </div>
                <div className='ManageContents flex w-full flex-col'>
                  
                  <p className="Sub2ndTitle">메인동영상_PC 관리하기</p>
                  {MainBannerLoading ?
                  <div className="ManageContentsNode flex flex-col gap-y-4 items-center">
                    {MainBanner.map((mainbanner, index)=>(
                    <div className='flex w-full gap-4 items-center'>
                      <p>{index+1}</p>
                      <p>{mainbanner.Video}</p>
                      <select value={String(mainbanner.No)} onChange={(e) => props.handleChange(e, "MainBanner")} id={mainbanner.id}> 
                        <option value="1" id={mainbanner.Code}>1</option>
                        <option value="2" id={mainbanner.Code}>2</option>
                        <option value="3" id={mainbanner.Code}>3</option>
                        <option value="4" id={mainbanner.Code}>4</option>
                        <option value="5" id={mainbanner.Code}>5</option>
                        <option value="6" id={mainbanner.Code}>6</option>
                        <option value="7" id={mainbanner.Code}>7</option>
                        <option value="8" id={mainbanner.Code}>8</option>
                        <option value="9" id={mainbanner.Code}>9</option>
                        <option value="10" id={mainbanner.Code}>10</option>
                        <option value="999" id={mainbanner.Code}>999</option>
                      </select>
                      <button className='AddrSearchButton2' onClick={(e) => props.DeleteDBnoneIMG(e, mainbanner.Code, "MainBanner")}>삭제하기</button> 
                    </div> 
                    ))} 
                  </div>
                  : null}
                  <div className='flex h-5'></div>
                  <div className='flex AddrSearchButton6'>
                    <form onSubmit={MainBannerUpload} className='flex flex-col w-full mt-8 mb-8 ml-3 mr-3'>
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" ref={MainVideo} type="text" placeholder='동영상 링크*' required/>	
                      </div>
                      <div className='flex mt-5 w-full justify-center'>
                        <button type="submit" className='Login_Submit flex flex-1 justify-center AddrSearchButton2'>메인동영상_PC 추가하기</button>
                      </div>
                    </form> 
                  </div>
                  <div className='flex h-14'></div>
                  <p className="Sub2ndTitle">메인동영상_Mobile 관리하기</p>
                  {MainBannerMBLoading ?
                  <div className="ManageContentsNode flex flex-col gap-y-4 items-center">
                    {MainBannerMB.map((mainbannermb, index)=>(
                    <div className='flex w-full gap-4 items-center'>
                      <p>{index+1}</p>
                      <p>{mainbannermb.Video}</p>
                      <select value={String(mainbannermb.No)} onChange={(e) => props.handleChange(e, "MainBannerMB")} id={mainbannermb.id}> 
                        <option value="1" id={mainbannermb.Code}>1</option>
                        <option value="2" id={mainbannermb.Code}>2</option>
                        <option value="3" id={mainbannermb.Code}>3</option>
                        <option value="4" id={mainbannermb.Code}>4</option>
                        <option value="5" id={mainbannermb.Code}>5</option>
                        <option value="6" id={mainbannermb.Code}>6</option>
                        <option value="7" id={mainbannermb.Code}>7</option>
                        <option value="8" id={mainbannermb.Code}>8</option>
                        <option value="9" id={mainbannermb.Code}>9</option>
                        <option value="10" id={mainbannermb.Code}>10</option>
                        <option value="999" id={mainbannermb.Code}>999</option>
                      </select>
                      <button className='AddrSearchButton2' onClick={(e) => props.DeleteDBnoneIMG(e, mainbannermb.Code, "MainBannerMB")}>삭제하기</button> 
                    </div> 
                    ))} 
                  </div>
                  : null}
                  <div className='flex h-5'></div>
                  <div className='flex AddrSearchButton6'>
                    <form onSubmit={MainBannerMBUpload} className='flex flex-col w-full mt-8 mb-8 ml-3 mr-3'>
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" ref={MainVideoMB} type="text" placeholder='동영상 링크*' required/>	
                      </div>
                      <div className='flex mt-5 w-full justify-center'>
                        <button type="submit" className='Login_Submit flex flex-1 justify-center AddrSearchButton2'>메인동영상_모바일 추가하기</button>
                      </div>
                    </form> 
                  </div>
                  <div className='flex h-14'></div>
                  <p className="Sub2ndTitle">상단 배너 관리하기</p><p className='mb-7'>※ 추가 업로드 시 이미지(높이 400px 이상) 반드시 삽입할 것.</p>
                  {TopBannerLoading ?
                  <div className="ManageContentsNode flex flex-col gap-y-4 items-center">
                    {TopBanner.map((topBanner, index)=>(
                    <div className='flex w-full gap-4 items-center'>
                      <p>{index+1}</p>
                      <p>{topBanner.Page}</p>
                      <p>{topBanner.Type}</p>
                      <i className="fa-solid fa-image AddrSearchButton2">
                        <div className='ManageContentsNodeIMG items-center justify-center'>
                          {topBanner.IMG==="" ? 
                            <p className='text-black'>No Image</p>
                          : <img src={topBanner.IMG} alt={topBanner.Page}></img>}
                        </div>
                      </i>
                      {topBanner.Code}
                      <button className='AddrSearchButton2' onClick={(e) => props.DeleteDBwithIMG(e, topBanner.Code, "Banner", topBanner.IMG)}>삭제하기</button> 
                    </div> 
                    ))} 
                  </div>
                  : null}
                  <div className='flex h-5'></div>
                  <div className='flex w-full AddrSearchButton6'>
                    <form onSubmit={TopBannerUpload} className='flex flex-col w-full mt-8 mb-8 ml-3 mr-3'>
                      <div className='flex w-full mb-5'>
                        <select ref={TopBannerPage} required className='AddrSearchButton4 flex flex-1 h-10'>
                          <option value="AboutUs">회사소개</option>
                          <option value="Location">사업장소개</option>
                          <option value="Product">생산</option>
                        </select>
                      </div>
                      <div className='flex w-full mb-5'>
                        <select ref={TopBannerType} required className='AddrSearchButton4 flex flex-1 h-10'>
                          <option value="">--</option>
                          <option value="CHQ WIRE">CHQ WIRE(생산타입)</option>
                          <option value="CD BAR">CD BAR(생산타입)</option>
                          <option value="CUTTING">CUTTING(생산타입)</option>
                        </select>
                      </div>
                      <div className='flex mb-3 ml-5 mt-2'>
                        <input type="file" ref={TopBannerIMGRef} onChange={CheckMTopBanner} accept="image/*"/>
                      </div>
                      <div className='flex mt-5 w-full justify-center'>
                        <button type="submit" className='Login_Submit flex flex-1 justify-center AddrSearchButton2'>상단 배너 등록하기</button>
                      </div>
                    </form> 
                  </div>
                  <div className='flex h-14'></div>
                  <p className="Sub2ndTitle">CI배너 관리하기</p><p className='mb-7'>※ 업로드 이미지는 가로세로 비율은 1:1이여야 합니다.</p>
                  {CIBannerLoading ?
                  <div className="ManageContentsNode flex flex-col gap-y-4 items-center">
                    {CIBanner.map((cibanner, index)=>(
                    <div className='flex w-full gap-4 items-center'>
                      <p>{index+1}</p>
                      <p>{cibanner.Name}</p>
                      <p>{cibanner.NameSub}</p>
                      <i className="fa-solid fa-image AddrSearchButton2">
                        <div className='ManageContentsNodeIMG items-center justify-center'>
                          {cibanner.IMG==="" ? 
                            <p className='text-black'>No Image</p>
                          : <img src={cibanner.IMG} alt={cibanner.Name}></img>}
                        </div>
                      </i>
                      <select value={String(cibanner.No)} onChange={(e) => props.handleChange(e, "CI")} id={cibanner.id}> 
                        <option value="1" id={cibanner.Code}>1</option>
                        <option value="2" id={cibanner.Code}>2</option>
                        <option value="3" id={cibanner.Code}>3</option>
                        <option value="4" id={cibanner.Code}>4</option>
                        <option value="5" id={cibanner.Code}>5</option>
                        <option value="6" id={cibanner.Code}>6</option>
                        <option value="7" id={cibanner.Code}>7</option>
                        <option value="8" id={cibanner.Code}>8</option>
                        <option value="9" id={cibanner.Code}>9</option>
                        <option value="10" id={cibanner.Code}>10</option>
                        <option value="999" id={cibanner.Code}>999</option>
                      </select>
                      <button className='AddrSearchButton2' onClick={(e) => props.DeleteDBwithIMG(e, cibanner.Code, "CI", cibanner.IMG)}>삭제하기</button> 
                    </div> 
                    ))} 
                  </div>
                  : null}
                  <div className='flex h-5'></div>
                  <div className='flex w-full AddrSearchButton6'>
                    <form onSubmit={CIBannerUpload} className='flex flex-col w-full mt-8 mb-8 ml-3 mr-3'>
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" ref={CIBannerName} type="text" placeholder='이미지명*' required/>	
                      </div>
                      <div className='flex w-full mb-5'>
                        <input className="flex w-full AddrSearchButton4 h-12" ref={CIBannerNameSub} type="text" placeholder='이미지명 Sub*' required/>	
                      </div>
                      <div className='flex mb-3 ml-5 mt-2'>
                        <input type="file" ref={CIBannerIMGRef} onChange={CheckMCIBanner} accept="image/*"/>
                      </div>
                      <div className='flex mt-5 w-full justify-center'>
                        <button type="submit" className='Login_Submit flex flex-1 justify-center AddrSearchButton2'>CI배너 등록하기</button>
                      </div>
                    </form> 
                  </div>
                </div>
              </div>
            </div>
            <div className='ChagePW flex w-full h-full justify-center items-center'>
              <div className='ChagePWContext flex flex-col items-center'>
                <div className="flex w-full h-10 justify-end mb-14">
                  <i className="fa-solid fa-xmark text-2xl" onClick={ClosePWChage}></i>
                </div>
                <form className='flex w-full justify-center items-center'> 
                  <input ref={PassWord} type="password" className='AddrSearchButton6 w-60' placeholder='비밀번호를 입력해주세요*' required/>	
                  <button type="submit" className='AddrSearchButton2 w-32 ml-5' onClick={PWCheck}>인증하기</button>
                </form>
                {PWChangeChecked ? <p className='flex pl-20 mt-10 mb-14 mr-28 w-full justify-center'>인증완료!!</p> : null}
                {PWChangeChecked ? 
                <form className='flex w-full justify-center items-center'>
                  <input ref={PassWordChange} type="password" className='AddrSearchButton6 w-60' placeholder='변경할 비밀번호를 입력해주세요*' required/>	
                  <button type="submit" className='AddrSearchButton2 w-32 ml-5' onClick={PWChange}>비밀번호 변경</button>
                </form>
                : null}
              </div>
            </div>
            <div className='QuitAcc flex w-full h-full justify-center items-center'>
              <div className='ChagePWContext flex flex-col items-center'>
                <div className="flex w-full h-10 justify-end mb-14">
                  <i className="fa-solid fa-xmark text-2xl" onClick={CloseQuitChage}></i>
                </div>
                <div className='flex font-bold text-2xl'>회원탈퇴 안내</div>
                <div className='flex text-xl mt-5 mb-6'>탈퇴 시 업로드 된 제보들은 삭제되지 않습니다.</div>
                {props.UserData[0].socialLogin==="" ? 
                <form className='flex w-full justify-center items-center'>
                  <input ref={PassWordQuit} type="password" className='AddrSearchButton6 w-60' placeholder='비밀번호를 입력해주세요*' required/>	
                  <button type="submit" className='AddrSearchButton2 w-48 ml-5' onClick={QuitAccCheck}>인증하고 탈퇴하기</button>
                  {PWQuitAccChecked ? null : <p className='mt-3 mb-3'>인증 실패!!</p>}
                </form>
                : <button type="submit" className='AddrSearchButton2 w-32 ml-5' onClick={QuitAccCheckSocial}>탈퇴하기</button> }
              </div>
            </div> 
          </div>
          :
          <div className='flex flex-col items-center text-xl mt-14'>
            <div className='flex mb-5 font-bold'> 로그인 되지 않거나 이메일 인증받지 못했습니다. </div>
            <Link to="/Login" className="flex w-40 justify-center items-center AddrSearchButton">
              Login </Link>
          </div>}
        </div>
      </div>

      <div className='Posting_MB flex w-full h-full items-center justify-center'>
        <div className='text-2xl'>PC버전에서만 사용 가능합니다.</div>
      </div>
    </div>
  );
}


export default Posting;
