import { React, useEffect, useRef, useState } from "react";
import './Location.css';
import { Link } from "react-router-dom";
import { getFirestore, collection, getDocs, doc, query, orderBy, limit , where} from "firebase/firestore"; // 데이터 읽어오기
import $ from 'jquery';

function Location(props) {
  const [BannerDb, SetBannerDb] = useState(null);
  const [BannerLoading, SetBannerLoading] = useState(false);

  const [LocDb, SetLocDb]=useState(null);
  const [LocLoading, SetLocLoading]=useState(false);

  useEffect(()=>{
    LoadingLocData();
    LoadingBannerData("Location");
  },[])

  async function LoadingLocData(){
    SetLocLoading(false);
    const UserListRef = collection(props.db, "Location"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("No", "asc")));
    let LocData = [];
    LocData.length=0;
    await querySnapshot.forEach((doc) => {
      LocData.push(doc.data());
    });
    SetLocDb(LocData);
    SetLocLoading(true);
  }

  async function LoadingBannerData(Page){
    SetBannerLoading(false);
    const UserListRef = collection(props.db, "Banner"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, where("Page", "==", Page)));
    let Banner = [];
    await querySnapshot.forEach((doc) => {
      Banner.push(doc.data());
    });
    SetBannerDb(Banner);
    SetBannerLoading(true);
  }

  return (
    <div className="Location">
      <div className="Location_PC flex flex-col w-full items-center">
        <div className="Location_PC-Banner flex flex-col w-full justify-center items-center">
          <div className="Location_PC-Banner-Text flex flex-col flex-1 w-full">
            <p className="flex Title">BUSINESS PLACE</p>
            <p className="flex text-2xl pl-2">사업장 소개</p>
          </div>
          <div className="BannerBackColor flex w-full h-full"></div>
          {BannerLoading ? 
          <div className="BannerBackIMG flex w-full h-full">
            {BannerDb.map(banner => (
              <img className="flex w-full h-full" src={banner.IMG}></img>
            ))}
          </div> : null}
        </div>




        {LocLoading ?
        <div className="Location_PC-CI con flex w-full flex-col">
        {LocDb.map(loc=>(
          <div className="Location_PC-CI-lists flex flex-col w-full items-center" key={loc.index}>
            <div className="flex h-20"></div>
            <div className="flex w-full mb-5">{loc.Name}</div>
            {loc.IMG==="" ? null : 
            <div className="flex w-full">
              <img className="flex w-full" src={loc.IMG} alt={loc.Name}></img>
            </div> }
            <div className="flex w-full mt-1 mb-1 text-2xl">주소</div>
            <p className="flex w-full">{loc.Location}</p>
            <div className="flex h-10"></div>
            <div className="flex w-full items-end">
              <span className="flex">Tel &#41;</span>
              <p className="flex ml-3">{loc.Tel}</p>
              <div className="flex flex-1"></div>
              <span className="flex">Fax &#41;</span>
              <p className="flex ml-3">{loc.Fax}</p>
              <div className="flex flex-1"></div>
            </div>
          </div>
        ))}
        </div>
        : null}
        <div className="flex h-20"></div>
        <div className="flex h-10"></div>
      </div>

      <div className="Location_MB flex flex-col w-full items-center">
        
        
        
        
        <div className="Location_MB-Banner flex flex-col w-full justify-center items-center">
          <div className="Location_MB-Banner-Text flex flex-col flex-1 justify-center w-full">
            <p className="flex Title">BUSINESS</p>
            <p className="flex Title">PLACE</p>
            <p className="flex text-2xl pl-2">사업장 소개</p>
          </div>
          <div className="BannerBackColor flex w-full h-full"></div>
          {BannerLoading ? 
          <div className="BannerBackIMG flex w-full h-full">
            {BannerDb.map(banner => (
              <img className="flex w-full h-full" src={banner.IMG}></img>
            ))}
          </div> : null}
        </div>






        {LocLoading ?
        <div className="Location_MB-CI flex w-full flex-col">
        {LocDb.map(loc=>(
          <div className="Location_MB-CI-lists flex flex-col w-full items-center" key={loc.index}>
            <div className="flex h-16"></div>
            <div className="flex w-full mb-5">{loc.Name}</div>
            {loc.IMG==="" ? null : 
            <div className="flex w-full">
              <img className="flex w-full" src={loc.IMG} alt={loc.Name}></img>
            </div> }
            <div className="flex w-full mt-1 mb-1 text-xl">주소</div>
            <p className="flex w-full">{loc.Location}</p>
            <div className="flex h-4"></div>
            <div className="flex flex-col w-full">
              <div className="flex mb-1">
                <span className="flex">Tel &#41;</span>
                <p className="flex ml-2">{loc.Tel}</p>
              </div>
              <div className="flex">
                <span className="flex">Fax &#41;</span>
                <p className="flex ml-2">{loc.Fax}</p>
              </div>
            </div>
          </div>
        ))}
        </div>
        : null}
        <div className="flex h-20"></div>
        <div className="flex h-10"></div>
      </div>

      
    </div>
  );
}

export default Location;
