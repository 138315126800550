import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate  } from 'react-router-dom';
import { signInWithEmailAndPassword, sendEmailVerification, getAuth, signOut } from "firebase/auth";
import './Login.css';

function Login(props) {
  const LoginID = useRef(null);  // ID 위치
  const LoginPW = useRef(null);  // PW 위치
  const navigate = useNavigate();  // Link없이 페이지 이동

  const [time, setTime] = useState(new Date());  // 시간

  useEffect(()=>{
    document.body.classList.remove("HomeMap"); // 페이지 떠나기 감지 스크롤 고정해제
    const interval = setInterval(() => {setTime(new Date()); }, 10000); // 1분마다 업데이트
    return () => clearInterval(interval);
  },[])

  async function LoginStart(e){
    e.preventDefault(); 
    const LogID = LoginID.current.value;
    const LogPW = LoginPW.current.value;

    if(LogID!=="" && LogPW!==""){
      await FBEmailLogin(LogID, LogPW);
    }
  }

  async function FBEmailLogin(email, password){
    await signInWithEmailAndPassword(props.auth, email, password)
    .then(async (userCredential) => {
      const user = userCredential.user;
      if(user.emailVerified){
        navigate('/Posting');
      }
      else{
        if(window.confirm("인증이 되지 않은 계정입니다. 로그인을 위해 인증을 하시겠습니까?")){
          const auth = getAuth();
          await sendEmailVerification(auth.currentUser)
          .then(async () => {
            await signOut(props.auth).then( async () => {
              alert("인증 이메일 발송 완료, 메시지 수신 후 새로고침 할 것.");
              alert("메일함에 없다면, 스팸메일함에서 확인해주시길 바랍니다.");
              alert("인증 후 재로그인 해주시길 바랍니다.");
              window.location.reload();
            });
          })
          .catch((error) => {
            console.log(error);
          });
        }
        else{ 
          alert("로그인이 취소되었습니다.");
          await signOut(props.auth);

       }
      }
    })
    .catch((error) => {
      // 로그인 실패 시, 에러 처리
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error('로그인 실패:', errorCode, errorMessage);
      alert("아이디나 비번이 일치하지 않거나, 없는 계정입니다.");
    });
  }

  return (
    <div>
      <div className="Login flex justify-center">
        <div className='LoginBox con flex flex-col w-full items-center pt-10 pb-40'>
          <div className='Title flex w-full'>로그인</div>
          {/* 자체 로그인 */}
          <form onSubmit={LoginStart} className="w-full pl-5 pr-5">
            <div className='flex w-full items-center mb-5'>
              <input ref={LoginID} type="text" placeholder="ID *" autoComplete="username"
                id="username" name="username" className="flex flex-1" required/>
            </div>
            <div className='flex w-full items-center mb-10'>
              <input ref={LoginPW} type="password" placeholder="PW *" autoComplete="current-password"
                id="password" name="password" className="flex flex-1" required/>
            </div>
            <div className='flex w-full items-center mb-5'>
              <button type="submit" className='flex flex-1 AddrSearchButton2 justify-center'>Login</button>
            </div>
            <Link to="/Join" className="flex flex-1 justify-center AddrSearchButton2 mb-5">JOIN</Link>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
